import { useState, useEffect, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { ReactComponent as DownArrow } from '../../../svg/Down-arrow.svg';
import ButtonCustom from '../../button';
import { SessionContext } from 'src/context/SessionContext';

interface Props {
	company: string;
	goback: () => void;
}

const StripePayment = ({ company, goback }: Props) => {
	const [message, setMessage] = useState('');
	const { session, isBgSkip } = useContext(SessionContext);
	const { vendorId } = session.details;
	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search);

		if (query.get('success')) {
			setMessage('Order placed! You will receive an email confirmation.');
		}

		if (query.get('canceled')) {
			setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
		}
	}, []);
	return (
		<div className="customCard">
			<Typography variant="body2" className="steps-header">
				{isBgSkip ? '6 of 6' : '7 of 7'}
			</Typography>

			<div className="afterArrowImg">
				<Typography variant="h2"> Payment </Typography>
				<DownArrow className="d-arrow" />
				<Typography variant="body1">This is the annual compliance fee needed to work for ResProp Management.</Typography>
			</div>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'stretch',
				}}
			>
				{!message && (
					<Box pt={4} pb={4}>
						<form action="/api/payments/create-checkout-session" method="POST">
							<ButtonCustom
								type="submit"
								id="submit"
								className="bigButton"
								round={'full'}
								variant="contained"
								color="primary"
								size="large"
							>
								PAY {'$99'} FEE
							</ButtonCustom>
							<input type="hidden" id="vendorId" name="vendorId" value={vendorId} />
						</form>
					</Box>
				)}
				{message && (
					<section>
						<p>{message}</p>
					</section>
				)}
			</Box>
		</div>
	);
};

export default StripePayment;
