import { useState, useEffect, useContext } from 'react';
import {
	Typography,
	TextField,
	Grid,
	Autocomplete,
	FormGroup,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
	Button,
	Box,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import ButtonCustom from '../../button';
import apiClient from '../../../api/index';
import { ReactComponent as DownArrow } from '../../../svg/Down-arrow.svg';
import { SessionContext } from '../../../context/SessionContext';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { states } from '../../../data/states';
import SocialSecurityNumber from 'src/components/forms/SocialSecurityNumber';
import ZipCode from 'src/components/forms/ZipCode';
import { ReactComponent as PDFIcon } from '../../../svg/pdf-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';

export const BackgroundCheckForm = () => {
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const [bgCheckId, setBgCheckId] = useState(null);
	const [dob, setDob] = useState<Date | null>(null);
	const [showSsn, setShowSsn] = useState<boolean>(false);
	const [acceptDisclosure, setAcceptDisclosure] = useState<string | null>(null);
	const { session, isNoRisk } = useContext(SessionContext);
	const { userId } = session;

	const validationSchema = Yup.object().shape({
		subjectInfo: Yup.object().shape({
			firstName: Yup.string()
				.required('Please enter the required field')
				.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
				.min(2),
			middleName: Yup.string(),
			lastName: Yup.string()
				.required('Please enter the required field')
				.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
				.min(2),
			dob: Yup.date()
				.typeError('please enter a valid date')
				.required()
				.min('1922-01-01', 'Date is too early')
				.max(
					DateTime.now().plus({ years: -18 }).toFormat('yyyy-MM-dd'),
					'You must be 18 or older to be the subject of a background check'
				),
			ssn: Yup.string().required().min(11, 'Must be exactly 9 digits').max(11, 'Must be exactly 9 digits'),
			streetAddress: Yup.string()
				.required('Please enter the required field')
				.matches(/^[a-zA-Z0-9.#\s]+$/, 'Only alphabets are allowed for this field ')
				.min(2),
			city: Yup.string().required().min(1),
			state: Yup.string().required(),
			zipCode: Yup.string().required().min(5),
		}),
		disclosureAccepted: Yup.date().typeError('please enter a valid date').required(),
	});

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			subjectInfo: {
				firstName: '',
				middleName: '',
				lastName: '',
				dob: '',
				ssn: '',
				streetAddress: '',
				city: '',
				state: null || '',
				zipCode: '',
			},
			disclosureAccepted: '',
		},
	});

	const disableMouseDownSsn = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const handleClickShowSsn = () => {
		setShowSsn(prev => !prev);
	};

	const goNext = (data: any) => {
		let { disclosureAccepted, subjectInfo } = data;
		let payload;
		if (bgCheckId) {
			payload = { userId, disclosureAccepted, subjectInfo, id: bgCheckId };
		} else {
			payload = { userId, disclosureAccepted, subjectInfo };
		}
		apiClient
			.submitBackgroundCheckInfo(payload)
			.then(res => {
				if (state?.next) {
					navigate(state.next);
				} else {
					if (isNoRisk) {
						navigate('/vendor/enrollment/msa');
					} else {
						navigate('/vendor/enrollment/coi');
					}
				}
			})
			.catch(err => {
				console.error('Something went terribly wrong');
			});

		sessionStorage.setItem('background-check-info', JSON.stringify(payload));
	};

	useEffect(() => {
		apiClient
			.getBackgroundCheckInfo(userId)
			.then(res => {
				if (!res) {
					reset({});
					return;
				}

				if (res?.subjectInfo?.dob && res.disclosureAccepted) {
					reset({
						...res,
						subjectInfo: {
							...res.subjectInfo,
							dob: DateTime.fromISO(res.subjectInfo.dob).toLocaleString(DateTime.DATE_SHORT),
						},
						disclosureAccepted: DateTime.fromISO(res.disclosureAccepted).toLocaleString(DateTime.DATE_SHORT),
					});

					setAcceptDisclosure(res.disclosureAccepted);
					setDob(res.subjectInfo.dob);
				}

				setBgCheckId(res.id);
			})
			.catch(err => {
				console.error(err);
				reset({});
			});
	}, []);

	return (
		<Box sx={{mt: 4}}>
			
			<Typography variant="h2">Background Check Information</Typography>
			<div className="afterArrowImg">
				<Typography component="div" mb={3} variant="body2">
					We require a background screening of a person identified as a principal of your company, who owns at least 30%
					ownership or management of the business.{' '}
					<b>
						This is <u>NOT</u> a credit check.
					</b>
				</Typography>
				<DownArrow className="d-arrow" />
			</div>
			<div>
				{/* add "invalidState" class with "inputwrap" to show error state */}
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.firstName ? 'errorText' : []).join(' ')}>
						First Name <span className="textDanger">*</span>
					</label>
					<TextField
						{...register('subjectInfo.firstName')}
						hiddenLabel
						variant="outlined"
						error={!!errors.subjectInfo?.firstName}
					/>
				</div>
				<div className="inputWrap mb12">
					<label className="inputLabel">Middle Name</label>
					<TextField
						{...register('subjectInfo.middleName')}
						error={!!errors.subjectInfo?.middleName}
						hiddenLabel
						variant="outlined"
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.lastName ? 'errorText' : []).join(' ')}>
						Last Name <span className="textDanger">*</span>
					</label>
					<TextField
						{...register('subjectInfo.lastName')}
						error={!!errors.subjectInfo?.lastName}
						hiddenLabel
						variant="outlined"
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.dob ? 'errorText' : []).join(' ')}>
						Date Of Birth <span className="textDanger">*</span>
					</label>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<Controller
							control={control}
							name="subjectInfo.dob"
							render={({ field: { onChange, value } }) => (
								<DatePicker
									openTo="year"
									views={['year', 'month', 'day']}
									value={dob}
									onChange={(newValue: DateTime | null) => {
										if (newValue) {
											const d = newValue.toLocaleString(DateTime.DATE_SHORT);
											const jsd = newValue.toJSDate();
											setDob(jsd);
											onChange(d);
										}
									}}
									renderInput={params => (
										<TextField
											placeholder="MM/DD/YYYY"
											{...params}
											{...register('subjectInfo.dob')}
											error={!!errors.subjectInfo?.dob}
										/>
									)}
									minDate={DateTime.fromFormat('1922-01-01', 'yyyy-MM-dd').toLocal()}
									maxDate={DateTime.now().plus({ years: -18 }).toLocal()}
								/>
							)}
						/>
					</LocalizationProvider>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.ssn ? 'errorText' : []).join(' ')}>
						Social Security Number <span className="textDanger">*</span>
					</label>
					<Controller
						control={control}
						name="subjectInfo.ssn"
						render={({ field: { onChange, value } }) => (
							<SocialSecurityNumber
								name="subjectInfo.ssn"
								onChange={onChange}
								value={value}
								error={!!errors.subjectInfo?.ssn}
								type={showSsn ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowSsn}
												onMouseDown={disableMouseDownSsn}
												edge="end"
											>
												{showSsn ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.streetAddress ? 'errorText' : []).join(' ')}>
						Address <span className="textDanger">*</span>
					</label>
					<TextField
						{...register('subjectInfo.streetAddress')}
						hiddenLabel
						variant="outlined"
						error={!!errors.subjectInfo?.streetAddress}
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.subjectInfo?.city ? 'errorText' : []).join(' ')}>
						City <span className="textDanger">*</span>
					</label>
					<TextField {...register('subjectInfo.city')} error={!!errors.subjectInfo?.city} hiddenLabel variant="outlined" />
				</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<div className="inputWrap mb12">
							<label className={['inputLabel'].concat(errors.subjectInfo?.state ? 'errorText' : []).join(' ')}>
								State <span className="textDanger">*</span>
							</label>
							<Controller
								control={control}
								name="subjectInfo.state"
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										onChange={(e, v) => {
											onChange(v);
										}}
										fullWidth
										options={states}
										getOptionLabel={(option: any) => option}
										renderInput={params => (
											<TextField
												{...params}
												hiddenLabel
												placeholder="State"
												error={!!errors.subjectInfo?.state}
												variant="outlined"
											/>
										)}
										value={value}
									/>
								)}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="inputWrap mb12">
							<label className={['inputLabel'].concat(errors.subjectInfo?.zipCode ? 'errorText' : []).join(' ')}>
								ZIP code <span className="textDanger">*</span>
							</label>
							<Controller
								control={control}
								name="subjectInfo.zipCode"
								render={({ field: { onChange, value } }) => (
									<ZipCode
										name="subjectInfo.zipCode"
										onChange={onChange}
										value={value}
										error={!!errors.subjectInfo?.zipCode}
									/>
								)}
							/>
						</div>
					</Grid>
					<Grid item xs={8}>
						<a
							href="https://s3.us-east-2.amazonaws.com/prod.resprop-coi/EvidentBackgroundCheckDisclosure.pdf"
							target="_blank"
							className="hasIconLeft"
							rel="noreferrer"
						>
							{/* <PdfIcon className="leftIcon" /> */}
							<Typography mb={4} className="imBlueDaBaDee" variant="subtitle2">
								<PDFIcon />
								&nbsp;&nbsp;
								<u>Review Background Check Disclosure</u>{' '}
							</Typography>
						</a>
					</Grid>
					{/* <Grid item xs={4}>
						<Button
							onClick={() => {
								window.location.href = 'https://web.dev.insurepro.com/?name=Handyman&email=&place_id=ChIJrbJq05tOO4gRdOW5BGD7bW8&coverage-type=General-Liability&General-Liability=on&_gl=1*1kvv850*_ga*MTc3OTk1OTc5OS4xNjc0MTUzNzA3*_ga_WLE5W0V06B*MTY3NDE1NTcwNy4yLjEuMTY3NDE1NzY4OS42MC4wLjA.&_ga=2.268795383.743927558.1674153708-1779959799.1674153707';
							}}
						>
							Upload COI
						</Button>
					</Grid> */}
				</Grid>
				<div>
					<div className="inputWrap checkboxLabelMuted">
						<FormGroup aria-label="position" row>
							<Controller
								control={control}
								name="disclosureAccepted"
								render={({ field: { onChange, value } }) => (
									<FormControlLabel
										value={getValues()?.disclosureAccepted}
										control={
											<Checkbox
												checked={!!acceptDisclosure}
												onChange={(e, v) => {
													const d = v ? DateTime.now().setLocale('en-us').toLocaleString() : null;
													setAcceptDisclosure(v ? d : null);
													onChange(v ? d : '');
												}}
												sx={{
													color: errors.disclosureAccepted ? 'red' : '#000',
												}}
											/>
										}
										label="I have read and accepted the above disclosure and I certify the information provided is for a principal of the company enrolling."
										labelPlacement="end"
									/>
								)}
							/>
						</FormGroup>
					</div>
				</div>
				<ButtonCustom
					className="bigButton submit-cta"
					round={'full'}
					variant="contained"
					color="primary"
					size="large"
					onClick={handleSubmit(goNext)}
				>
					<span>Save and Continue</span>
				</ButtonCustom>
			</div>
		</Box>
	);
};
