import { Navigate, useLocation, Outlet } from 'react-router-dom';
interface ISession {
	userId: string;
	role: string;
	details: any;
}
type RouteProps = {
	children?: JSX.Element;
	session: ISession;
	waiting: boolean;
};

const VendorPrivateRoute = ({ session, children, waiting }: RouteProps) => {
	const location = useLocation();
	if (waiting) return null;

	if (!!session.userId && session.role === 'VENDOR') {
		return children ? children : <Outlet />;
	}

	return <Navigate to="/login" state={{ from: location }} />;
};

export default VendorPrivateRoute;
