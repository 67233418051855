import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import VendorDashboardPage from './pages/vendor-dashboard';
import Home from './pages/home';
import Login from './pages/login';
import LoginVerification from './pages/login-verification';
import LoginReset from './pages/login-reset';
import { SessionContext } from './context/SessionContext';
import VendorPrivateRoute from './middleware/vendor-private-route';
import LoadingScreen from './pages/loading';
import EnrollmentLayout from './components/layouts/EnrollmentLayout';
import { BizInfoForm } from './components/enrollment/business-information';
import { W9Landing } from './components/enrollment/form-w-nine/w9-landing';
import { BackgroundCheckForm } from './components/enrollment/background-check-information';
import COILanding from './components/enrollment/coi';
import { MSA } from './components/enrollment/master-service-agreement';
import Summary from './components/enrollment/summary';
import { Payment } from './components/enrollment/payment';
import RentalVerification from './pages/rental-verification';

const App = () => {
	const { session, waiting, sessionIsEmpty } = useContext(SessionContext);

	if (waiting) return <LoadingScreen />;
	return (
		<Routes>
			<Route path="*" element={<Home sessionIsEmpty={sessionIsEmpty} session={session} />} />
			<Route path="/login" element={<Login />} />
			<Route path="/login-verification" element={<LoginVerification />} />
			<Route path="/login-reset" element={<LoginReset />} />
			<Route path="/rental-verification" element={<RentalVerification />} />
			<Route element={<VendorPrivateRoute waiting={waiting} session={session} />}>
				<Route path="vendor">
					<Route path="dashboard" element={<VendorDashboardPage />} />
					<Route path="enrollment" element={<EnrollmentLayout />}>
						<Route path="business-info" element={<BizInfoForm />} />
						<Route path="w9" element={<W9Landing />} />
						<Route path="background-check" element={<BackgroundCheckForm />} />
						<Route path="coi" element={<COILanding />} />
						<Route path="msa" element={<MSA />} />
						<Route path="summary" element={<Summary />} />
						<Route path="payment" element={<Payment />} />
					</Route>
				</Route>
			</Route>
		</Routes>
	);
};

export default App;
