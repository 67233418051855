import { Box, Dialog, Typography } from "@mui/material"
import { ReactComponent as LogoMark } from 'src/svg/logo-mark.svg';
import { ReactComponent as DownArrow } from 'src/svg/Down-arrow.svg';
import { makeStyles } from "@mui/styles";

const styles = {
	align: {
	},
};

const useStyles = makeStyles({
	dialog: {
		alignItem: 'baseline',
	},
});
export const ReviewDocument = () => {
    const classes = useStyles();
    
    return (
        <Dialog className={classes.dialog} open={true} fullWidth>
            <Box p={4}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h2" mb={2} sx={styles.align}>
                    Document Submitted
                    </Typography>
                    <Typography mb={4} variant="body2" sx={styles.align}>
                        <LogoMark />
                    </Typography>
                    <Typography mb={4}>
                        <DownArrow />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h2" mb={2} sx={styles.align}>
                    Reviewing your insurance documents...
                    </Typography>
                    <Typography variant="body2" mb={4} sx={styles.align}>
                    Please wait, do not close this screen
                    </Typography>
                </Box>
            </Box>
        </Dialog>
    )
}