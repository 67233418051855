import ButtonCustom from '../button';
const SaveAndContinue = ({ buttonTitle, ...props }: any) => {
	return (
		<ButtonCustom
			className="bigButton submit-cta"
			round={'non-full'}
			variant="contained"
			color="primary"
			size="large"
			{...props}
		>
			{buttonTitle}
		</ButtonCustom>
	);
};
export default SaveAndContinue;
