import { STATUS_CONSTANTS, COLOR_CONSTANTS } from './constant';
import api from '../api/index';

export type Colors =
	| 'inherit'
	| 'primary'
	| 'purple'
	| 'lime'
	| 'redlight'
	| 'yellowDark'
	| 'secondary'
	| 'success'
	| 'error'
	| 'info'
	| 'warning';

export const getColor = (val: string) => {
	if (val) {
		const refinedval = val.toLocaleLowerCase();
		switch (refinedval) {
			case STATUS_CONSTANTS.PENDING:
				return COLOR_CONSTANTS.YELLOWDARK as Colors;
			case STATUS_CONSTANTS.NON_COMPLIANT:
				return COLOR_CONSTANTS.REDLIGHT as Colors;
			case STATUS_CONSTANTS.COMPLIANT:
				return COLOR_CONSTANTS.LIME as Colors;
			case STATUS_CONSTANTS.INCOMPLETE:
				return COLOR_CONSTANTS.PURPLE as Colors;
			default:
				return COLOR_CONSTANTS.PRIMARY as Colors;
		}
	} else {
		return COLOR_CONSTANTS.PRIMARY as Colors;
	}
};

// TODO remvoe this function in favor of something that reconciles the Tax Id with a name in the DB
export const convertTaxClassificationIdToStr = (taxClassificationId: string): string => {
	switch (taxClassificationId) {
		case '6297a802709496dba7ae522a':
			return 'Sole Proprietorship';
		case '6297a802709496dba7ae522b':
			return 'Partnership';
		case '6297a802709496dba7ae522c':
			return 'S Corporation';
		case '6297a802709496dba7ae522d':
			return 'C Corporation';
		case '6297a802709496dba7ae522e':
			return 'Trust/Estate';
		case '6297a802709496dba7ae522f':
			return 'LLC - S Corp';
		case '6297a802709496dba7ae5230':
			return 'LLC - C Corp';
		case '6297a802709496dba7ae5231':
			return 'LLC - Partnership';
		case '6297a802709496dba7ae523':
			return 'Other';
		default:
			return '';
	}
};

export const openInNewTab = (url: string) => {
	window.open(url, '_blank', 'noopener,noreferrer');
};

export function until(conditionFunction: any) {
	const poll = (r: any) => {
		if (conditionFunction()) r();
		else setTimeout(_ => poll(r), 1000);
	};

	return new Promise(poll);
}

export const stringdDateToIso = (dateString: string) => {
	return new Date(dateString).toISOString();
};

export async function saveInsuranceInfo(insuranceInfo: any, vendorId: string) {
	const general_policyNo = insuranceInfo?.cgl_policyNumber;
	const auto_policyNo = insuranceInfo?.ca_policyNumber;
	const excess_policyNo = insuranceInfo?.um_policyNumber;
	const wc_policyNo = insuranceInfo?.wc_policyNumber;

	let apiCalls: Promise<void>[] = [];
	const payload: any = {
		brokerName: insuranceInfo?.producer,
		brokerContact: insuranceInfo?.contact_name ?? insuranceInfo?.producer,
		brokerEmail: insuranceInfo?.contact_email,
		brokerPhone: insuranceInfo?.contact_phone,
		expiration: '',
		policyNo: '',
		certificateHolder: insuranceInfo?.certificate_holder || insuranceInfo?.certificateHolder,
		description: insuranceInfo?.description,
	};
	if (general_policyNo && general_policyNo.trim().length !== 0) {
		var policyId = await getGlPolicyIdInfo(vendorId);
		if (policyId) payload.policyId = policyId;
		payload.policyNo = general_policyNo;
		payload.expiration = stringdDateToIso(insuranceInfo?.cgl_endDate);

		payload.aggregate = insuranceInfo.cgl_genAggregate;
		payload.eachOccurence = insuranceInfo.cgl_eachOccurence;
		payload.personalAndAdvInjury = insuranceInfo['cgl_personal&advInjury'];
		payload.productsOrCompOrOpAgg = insuranceInfo['cgl_products/comp/opAgg'];
		payload.startDate = insuranceInfo.cgl_startDate;
		payload.medicalExpense = insuranceInfo.cgl_medicalExpense;
		payload.damageToPremises = insuranceInfo.cgl_damageToPremises;
		payload.additionalInsured = insuranceInfo.cgl_AI;
		payload.waiverOfSubrogation = insuranceInfo.cgl_wos;

		apiCalls.push(api.submitGeneralLiability(vendorId, payload).then(() => console.info('General liability saved')));
	}
	if (auto_policyNo && auto_policyNo.trim().length !== 0) {
		var policyId = await getAlPolicyIdInfo(vendorId);
		if (policyId) payload.policyId = policyId;
		payload.policyNo = auto_policyNo;
		payload.expiration = stringdDateToIso(insuranceInfo?.ca_endDate);

		payload.combinedSingleLimit = insuranceInfo.ca_combinedSingleLimit;
		payload.bodilyInjuryPerAccident = insuranceInfo.ca_BIperAccident;
		payload.bodilyInjuryperPerson = insuranceInfo.ca_BIperPerson;
		payload.proprtyDamagePerAccident = insuranceInfo.ca_PD;
		payload.startDate = insuranceInfo.ca_startDate;
		payload.additionalInsured = insuranceInfo.ca_AI;
		payload.waiverOfSubrogation = insuranceInfo.ca_wos;

		apiCalls.push(api.submitAutoLiability(vendorId, payload).then(() => console.info('Auto liability saved')));
	}
	if (excess_policyNo && excess_policyNo.trim().length !== 0) {
		var policyId = await getExPolicyIdInfo(vendorId);
		if (policyId) payload.policyId = policyId;
		payload.expiration = stringdDateToIso(insuranceInfo?.um_endDate);
		payload.policyNo = excess_policyNo;

		payload.eachOccurence = insuranceInfo.um_eachOccurence;
		payload.aggregate = insuranceInfo.um_aggregate;
		payload.startDate = insuranceInfo.um_startDate;
		payload.excessLiab = insuranceInfo.um_excessLiab;
		payload.additionalInsured = insuranceInfo.um_AI;
		payload.waiverOfSubrogation = insuranceInfo.um_wos;

		apiCalls.push(api.submitExcessLiability(vendorId, payload).then(() => console.info('Excess liability saved')));
	}
	if (wc_policyNo && wc_policyNo.trim().length !== 0) {
		var policyId = await getWcPolicyIdInfo(vendorId);
		if (policyId) payload.policyId = policyId;
		payload.expiration = stringdDateToIso(insuranceInfo?.wc_endDate);
		payload.policyNo = wc_policyNo;

		payload.eachAccident = insuranceInfo.wc_eachAccident;
		payload.diseaseEachEmployee = insuranceInfo.wc_diseaseEachEmployee;
		payload.diseasePolicyLimit = insuranceInfo.wc_diseasePolicyLimit;
		payload.startDate = insuranceInfo.wc_startDate;
		payload.additionalInsured = insuranceInfo.wc_AI;
		payload.waiverOfSubrogation = insuranceInfo.wc_wos;

		apiCalls.push(api.submitWorkersComp(vendorId, payload).then(() => console.info('WC saved')));
	}

	return Promise.all(apiCalls);
}

async function getGlPolicyIdInfo(vendorId: string) {
	try {
		var res = await api.getGeneralLiability(vendorId);
		if (res.data.policyId) {
			return res.data.policyId;
		}
		return null;
	} catch (error) {
		return null;
	}
}

async function getAlPolicyIdInfo(vendorId: string) {
	try {
		var res = await api.getAutoLiability(vendorId);
		if (res.data.policyId) {
			return res.data.policyId;
		}
		return null;
	} catch (error) {
		return null;
	}
}

async function getExPolicyIdInfo(vendorId: string) {
	try {
		var res = await api.getExcessLiability(vendorId);
		if (res.data.policyId) {
			return res.data.policyId;
		}
		return null;
	} catch (error) {
		return null;
	}
}

async function getWcPolicyIdInfo(vendorId: string) {
	try {
		var res = await api.getWorkersComp(vendorId);
		if (res.data.policyId) {
			return res.data.policyId;
		}
		return null;
	} catch (error) {
		return null;
	}
}


export const formatBytes = (a: any,b=2) => {if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}
