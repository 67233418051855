import { Context, createContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { getSession, getVendor, getVendorServices } from '../api/index';

// Defines the properties of a session
interface ISession {
	userId: string;
	role: string;
	details: any;
}

// Defines the interface for storing/accessing a session
interface ISessionStorage {
	session: ISession;
	setSession: Dispatch<SetStateAction<ISession>>;
	refreshSession: () => Promise<Boolean>;
	sessionIsEmpty: () => Boolean;
	waiting: Boolean;
	enrollmentStatus: string;
	setEnrollmentStatus: Dispatch<SetStateAction<string>>;
	refreshEnrollmentStatus: () => Promise<void>;
	riskLevel: string;
	setRiskLevel?: Dispatch<SetStateAction<string>>;
	serviceProvided: string;
	setServiceProvided?: Dispatch<SetStateAction<string>>;
	isNoRisk: boolean;
	setIsNoRisk: Dispatch<SetStateAction<boolean>>;
	isBgSkip: boolean;
	setIsBgSkip: Dispatch<SetStateAction<boolean>>;
	logout: any;
}

// Until a user has authenticatd there is no session so an empty object is suitable.
const initialSession: ISession = {
	userId: '',
	role: '',
	details: {},
};

const initialSessionStorage: ISessionStorage = {
	session: initialSession,
	setSession: () => {},
	refreshSession: () => Promise.resolve(false),
	sessionIsEmpty: () => true,
	waiting: true,
	enrollmentStatus: '',
	setEnrollmentStatus: () => {},
	refreshEnrollmentStatus: Promise.resolve,
	riskLevel: 'NONE',
	serviceProvided: '',
	isNoRisk: true,
	setIsNoRisk: () => {},
	isBgSkip: false,
	setIsBgSkip: () => {},
	logout: () => {},
};

// Define the session state and all fn's able to access the session storage
const useSession = (): ISessionStorage => {
	const [session, setSession] = useState(initialSession);
	const [waiting, setWaiting] = useState<boolean>(true);
	const [enrollmentStatus, setEnrollmentStatus] = useState<string>('');
	const [riskLevel, setRiskLevel] = useState('NONE');
	const [isNoRisk, setIsNoRisk] = useState(true);
	const [isBgSkip, setIsBgSkip] = useState(false);
	const [serviceProvided, setServiceProvided] = useState('');
	const sessionIsEmpty = (): Boolean => {
		return Object.values(session).filter(val => val.length > 0).length === 0;
	};

	const refreshSession = async (): Promise<Boolean> => {
		try {
			setWaiting(true);
			const res = await getSession();
			if (res) {
				setSession(res);
				return true;
			} else {
				if (!sessionIsEmpty()) {
					setSession(initialSession);
				}
				return false;
			}
		} catch (err) {
			if (!sessionIsEmpty()) {
				setSession(initialSession);
			}
			return false;
		} finally {
			setWaiting(false);
		}
	};

	useEffect(() => {
		refreshSession();
	}, []);

	useEffect(() => {
		setIsNoRisk(riskLevel === 'NONE');
	}, [riskLevel]);

	const refreshEnrollmentStatus = async (): Promise<void> => {
		const resp = await getVendor(session.details?.vendorId);
		const overallComplianceStatus = resp.data.overallComplianceStatus;
		if (resp.data.payment?.status === 'succeeded') {
			if (overallComplianceStatus && overallComplianceStatus !== 'PENDING') {
				setEnrollmentStatus(overallComplianceStatus);
			} else {
				setEnrollmentStatus('PENDING');
			}
		}
		const risks = await getVendorServices({ ids: resp.data.bizInfo.riskLevels });
		const levels = risks.data.vendorServices.map((s: any) => s.riskLevel);
		setRiskLevel(levels.indexOf('HIGH') >= 0 ? 'HIGH' : levels.indexOf('LOW') >= 0 ? 'LOW' : 'NONE');
	};

	const get_cookie = (name: any) => {
		return document.cookie.split(';').some(c => {
			return c.trim().startsWith(name + '=');
		});
	}

	const delete_cookie = ( name: any, path: any, domain: any ) => {
		if( get_cookie( name ) ) {
		  document.cookie = name + "=" +
			((path) ? ";path="+path:"")+
			((domain)?";domain="+domain:"") +
			";expires=Thu, 01 Jan 1970 00:00:01 GMT";
		}
	  }

	const logout = async () => {
		delete_cookie('accessToken', '/', window.location.hostname);
		setSession(initialSession);
	}

	return {
		session,
		setSession,
		refreshSession,
		sessionIsEmpty,
		waiting,
		enrollmentStatus,
		setEnrollmentStatus,
		refreshEnrollmentStatus,
		riskLevel,
		setRiskLevel,
		serviceProvided,
		setServiceProvided,
		isNoRisk,
		setIsNoRisk,
		isBgSkip,
		setIsBgSkip,
		logout,
	};
};

// Creates a React Context object for storing Session information
export const SessionContext: Context<ISessionStorage> = createContext(initialSessionStorage);

// Creates the SessionContextProvider which exposes the session storage to the rest of the react app
export const SessionContextProvider = ({ children }: any) => {
	const session = useSession();
	return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};
