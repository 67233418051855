import { Box, Dialog, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import ArrowLeft from '@mui/icons-material/ArrowBackIosRounded';
import { useContext, useEffect, useState } from "react";
import apiClient from '../../api/index';
import SendOutlinedIcon from '@mui/icons-material/SendRounded';
import { SessionContext } from "src/context/SessionContext";
import { DateTime } from "luxon";

export const VendorMessages = ({showMessages, setShowMessages, vendorDetails}: any) => {

    const [notes, setNotes] = useState<any[]>();
    const { session } = useContext(SessionContext);
    const [note, setNote] = useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    };
    const onAdd = () => {
        if (note.length > 0) {
            const newNote = {
                note: note,
                type: 'external',
                vendorId: vendorDetails?._id,
                userId: session.userId,
                displayName:
                session.role === 'IDCORE_ADMIN'
                    ? 'Id Core Support'
                    : session.role === 'PROPERTY_MANAGER'
                    ? 'ResProp Property Manager'
                    : vendorDetails?.w9?.businessName,
            };
            apiClient.createNote(newNote).then(res => {
                if (res.status === 201) {
                const copy = notes?.filter(() => true);
                copy?.unshift(
                    ...[
                    {
                        _id: res.data._id,
                        note: res.data.note,
                        displayName: res.data.displayName,
                        dateAdded: res.data.dateAdded,
                        vendorId: res.data.vendorId,
                        type: 'external',
                    },
                    ]
                );
                setNotesOrder(copy);
                setNote('');
                }
            });
        }
    };

    useEffect(() => {
        if(vendorDetails) {
            apiClient.getNotes(vendorDetails?._id).then(value => {
                var data = value.data.filter((x: any) => x.type === 'external');
                setNotesOrder(data);
            });
        }
    }, [vendorDetails]);

    function setNotesOrder(notes: any) {
        notes.sort((a: any, b: any) => {
          return new Date(a.dateAdded).valueOf() - new Date(b.dateAdded).valueOf();
        });
        setNotes(notes);
    }

    function getDateFormatted(dateTime: string) {
        const dateNow = DateTime.now().toLocal();
        const chatDate = DateTime.fromISO(dateTime).toLocal();
        const duration = dateNow.diff(chatDate, ['years', 'months', 'days', 'hours', 'minutes']);
        if (dateNow.year == chatDate.year && dateNow.month == chatDate.month && duration.days > 0 && duration.days <= 7) {
          return duration.days + ' days ago';
        }
        if (
          dateNow.year == chatDate.year &&
          dateNow.month == chatDate.month &&
          duration.days == 0 &&
          duration.hours > 0 &&
          duration.hours <= 23
        ) {
          return duration.hours + ' hours ago';
        }
        if (dateNow.year == chatDate.year && dateNow.month == chatDate.month && duration.days == 0 && duration.minutes <= 59) {
          return duration.minutes.toFixed(0) + ' minutes ago';
        }
        return dateNow.year == chatDate.year ? chatDate.toFormat('LLL dd') : chatDate.toFormat('LLL dd YYYY');
    }

    return <Dialog open={showMessages} fullWidth>
        <Box p={4}>
            <Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={() => setShowMessages(false)}>
                <ArrowLeft sx={{width: '20px', height: '20px'}}/>
                <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#102A43', fontWeight: 600}}>Back to Dashboard</Typography>
            </Box>
            <Box sx={{paddingY: '20px', borderBottom: '1px solid #BCCCDC'}}>
                <Typography sx={{fontSize: '24px', lineHeight: '32px', fontWeight: 500, color: '#272937'}}>Messages with Support</Typography>
            </Box>
        </Box>
        <Box sx={{marginX: '10%', display: 'flex', flexDirection: 'column', gap: '40px'}}>
            {notes?.map(note => <>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', justifyItems: 'center'}}>
                        <Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>{note.displayName}</Typography>
                        <Box sx={{width: '4px', height: '4px', border: '4px solid', borderRadius: '8px',  color: 'rgba(39, 41, 55, 0.75)'}}></Box>
                        <Typography sx={{color: 'rgba(39, 41, 55, 0.75)', fontSize: '14px', lineHeight: '20px'}}>{getDateFormatted(note.dateAdded)}</Typography>
                    </Box>
                    <Box sx={{padding: '16px', borderRadius: '8px', background: '#fff', boxShadow: '0px 4px 8px 1px rgba(0, 0, 0, 0.11)'}}>
                        <Typography sx={{color: '#2C2C2C', fontSize: '16px', lineHeight: '24px'}}>{note.note}</Typography>
                    </Box>
                </Box>
            </>)}
        </Box>
        <Box sx={{margin: '10%'}}>
            <TextField
            fullWidth
            value={note}
            placeholder="Add Your Comment"
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => onAdd()}>
                        <SendOutlinedIcon sx={{ color: '#035388' }}></SendOutlinedIcon>
                    </IconButton>
                </InputAdornment>
                ),
            }}
            onChange={onChange}
            />
        </Box>
    </Dialog>
}