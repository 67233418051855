import { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import theme from './theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';
import { SessionContextProvider } from './context/SessionContext';
import { ConfigContextProvider } from './context/ConfigContext';
import { IntercomProvider } from 'react-use-intercom';
import apiClient from '../src/api/index';
import * as FullStory from '@fullstory/browser';

const container = document.getElementById('root');
const root = createRoot(container!);

FullStory.init(
	{
	  orgId: 'o-1CZ4ZR-na1',
	  recordCrossDomainIFrames: true,
	  devMode: window.location.hostname !== 'app.idcore.com',
	},
	({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
  );

const ThemeRoot = () => {
	return (
		<ThemeProvider theme={theme}>
			<ConfigContextProvider>
				<SessionContextProvider>
					<Router>
						<UserContextProvider>
							<App />
						</UserContextProvider>
					</Router>
				</SessionContextProvider>
			</ConfigContextProvider>
		</ThemeProvider>
	);
};

const Root = () => {
	const [intercomId, setIntercomId] = useState('ggekus3b');

	// if (intercomId === '') {
	// 	apiClient.getConfiguration().then((res: any) => {
	// 		setIntercomId(res.intercomId);
	// 	});
	// }

	return (
		<>
			{intercomId ? (
				<IntercomProvider appId={intercomId} autoBoot={true} autoBootProps={{hideDefaultLauncher: true}}>
					<ThemeRoot />
				</IntercomProvider>
			) : (
				<ThemeRoot />
			)}
		</>
	);
};

root.render(<Root />);
