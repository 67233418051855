import { Typography, Box, Dialog } from '@mui/material';
import ButtonCustom from '../../button';
import { ReactComponent as LogoMark } from 'src/svg/logo-mark.svg';
import { ReactComponent as DownArrow } from 'src/svg/Down-arrow.svg';
import { makeStyles } from '@mui/styles';

const styles = {
	align: {
	},
};
const useStyles = makeStyles({
	dialog: {
		alignItem: 'baseline',
	},
});

interface Props {
	company: string;
	go_back: () => void;
	paymentStatus: string;
}

const PaymentStatusComponent = ({ company, go_back, paymentStatus}: Props) => {
	const classes = useStyles();
	const returnDashboard = () => {
		go_back();
	};

	const ErrorElement = () => (
		<div>
			<Box
				p={4}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Typography variant="h2" mb={1} sx={styles.align}>
					Payment Failed. Please try again.
				</Typography>
				<Typography mb={1} variant="body2" sx={styles.align}>
					<LogoMark />
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<ButtonCustom
					onClick={returnDashboard}
					disabled={false}
					className="bigButton"
					round={'full'}
					variant="contained"
					color="primary"
					size="large"
				>
					Try Again.
				</ButtonCustom>
			</Box>
		</div>
	);

	return (
		<div>
			<Dialog className={classes.dialog} open={true} fullWidth>
				{paymentStatus === 'false' && (
					<Box p={4}>
						<ErrorElement />
					</Box>
				)}
				{paymentStatus === 'true' && (
					<Box p={4}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Typography variant="h2" mb={2} sx={styles.align}>
								Payment Confirmed
							</Typography>
							<Typography mb={4} variant="body2" sx={styles.align}>
								You will receive an email with confirmation details.
							</Typography>
							<Typography mb={4} variant="body2" sx={styles.align}>
								<LogoMark />
							</Typography>
							<Typography mb={4}>
								<DownArrow />
							</Typography>
						</Box>
						{/* <Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Typography variant="h2" mb={2} sx={styles.align}>
							Reviewing your insurance documents...
							</Typography>
							<Typography variant="body2" mb={4} sx={styles.align}>
							Please wait, do not close this screen
							</Typography>
						</Box> */}
						<ButtonCustom
							onClick={returnDashboard}
							disabled={false}
							className="bigButton"
							round={'full'}
							variant="contained"
							color="primary"
							size="large"
						>
							Continue
						</ButtonCustom>
					</Box>
				)}
			</Dialog>
		</div>
	);
};

const PaymentStatus = ({ company, go_back , paymentStatus}: Props) => {
	return (
			<PaymentStatusComponent company={company} go_back={go_back} paymentStatus={paymentStatus} />
	);
};

export default PaymentStatus;
