export const respropNeeds = {
    cgl_occur_selected : true,
    cgl_applies : 'any',
    cgl_eachOccurence: 1000000,
    cgl_personalAdvInjury: 1000000,
    cgl_genAggregate: 1000000,
    cgl_products: 1000000,

    ca_applies: 'any',
    ca_combinedSingleLimit: 1000000,
    ca_BIperPerson: 500000,
    ca_BIperAccident: 500000,
    ca_PDperAccident: 500000,

    ca_combinedSingleLimit_low: 500000,
    ca_BIperPerson_low: 100000,
    ca_BIperAccident_low: 300000,
    ca_PDperAccident_low: 100000,

    wc_wos: 'Y',
    wc_eachAccident: 100000,
    wc_diseaseEachEmployee: 100000,
    wc_diseasePolicyLimit: 500000,

    um_aggregate: 2000000,
    um_eachOccurence: 2000000,
    um_umbrellaLiab: 'Y',
    um_excessLiab: 'Y',

    certificate_holder: 'ResProp Management',
    certificate_holder2: 'IDcore',
    description_low: 'The Certificate Holder, ResProp Management Company LLC and its respective owners, officers, agents, and employees, and any individual or entity which has an ownership interest in any real property managed or owned by ResProp Management Company LLC, and all other parties as required by executed contract are included as additional insured on a primary, non-contributory basis under General Liability (including completed operations coverage), and under Auto Liability. Waiver of subrogation applies in their favor under workers compensation coverage for work performed by or on behalf of the named insured on the captioned project. Thirty (30) days notice of cancellation except for non-payment of premium applies to Certificate Holder according to the contractual obligations.',
    description_high: 'The Certificate Holder, ResProp Management Company LLC and its respective owners, officers, agents, and employees, and any individual or entity which has an ownership interest in any real property managed or owned by ResProp Management Company LLC, and all other parties as required by executed contract are included as additional insured on a primary, non-contributory basis under General Liability (including completed operations coverage), and under Auto Liability. Waiver of subrogation applies in their favor under workers compensation coverage for work performed by or on behalf of the named insured on the captioned project. Thirty (30) days notice of cancellation except for non-payment of premium applies to Certificate Holder according to the contractual obligations. Umbrella coverage is following form without exception to the underlying General Liability, Auto, and Workers\' Compensation coverage\'s.'
}

const caLimitsHigh = {
    ca_combinedSingleLimit: 1000000,
    ca_BIperPerson: 500000,
    ca_BIperAccident: 500000,
    ca_PDperAccident: 500000,
};
const caLimitsLow = {
    ca_combinedSingleLimit: 500000,
    ca_BIperPerson: 100000,
    ca_BIperAccident: 300000,
    ca_PDperAccident: 100000,
};

const isRequirementComplete = (coiCheck: any, riskLevel: string) => {
    const missingReq = [];
    if(coiCheck['Acord 25 Detected']) {
        if(coiCheck['Extracted']) {
            if(!coiCheck['cgl_policyNumber']) {
                missingReq.push('Missing Coverage General Liability');
            }
            if(!coiCheck['ca_policyNumber']) {
                missingReq.push('Missing Coverage Auto Liability');
            }
            if(!coiCheck['wc_policyNumber']) {
                missingReq.push('Missing Coverage Workers Comp');
            }
            if(riskLevel === 'HIGH' && (!coiCheck['um_policyNumber'] || (!coiCheck['um_umbrellaLiab'] && !coiCheck['um_excessLiab']))) {
                missingReq.push('Missing Coverage Excess Liability');
            }
            if(parseInt(coiCheck['cgl_eachOccurence']) < respropNeeds.cgl_eachOccurence
                || parseInt(coiCheck['cgl_personal&advInjury']) < respropNeeds.cgl_personalAdvInjury
                || parseInt(coiCheck['cgl_genAggregate']) < respropNeeds.cgl_genAggregate
                || parseInt(coiCheck['cgl_products/comp/opAgg']) < respropNeeds.cgl_products) {
                    missingReq.push('Insufficient Coverage General Liability');
            }
            if(riskLevel === 'HIGH' && (parseInt(coiCheck['um_aggregate']) < respropNeeds.um_aggregate || 
                parseInt(coiCheck['um_eachOccurence']) < respropNeeds.um_eachOccurence)) {
                    missingReq.push('Insufficient Coverage Excess Liability');
            }
            const caLimits = riskLevel === 'HIGH' ? caLimitsHigh : caLimitsLow;
            if(!((parseInt(coiCheck['ca_combinedSingleLimit']) >= caLimits.ca_combinedSingleLimit) || (
                parseInt(coiCheck['ca_BIperPerson']) >= caLimits.ca_BIperPerson
                && parseInt(coiCheck['ca_BIperAccident']) >= caLimits.ca_BIperAccident
                && parseInt(coiCheck['ca_PD']) >= caLimits.ca_PDperAccident))
            ) {
                missingReq.push('Insufficient Coverage Auto Liability');
            }
            if(parseInt(coiCheck['wc_eachAccident']) < respropNeeds.wc_eachAccident
                || parseInt(coiCheck['wc_diseaseEachEmployee']) < respropNeeds.wc_diseaseEachEmployee
                || parseInt(coiCheck['wc_diseasePolicyLimit']) < respropNeeds.wc_diseasePolicyLimit
            ) {
                missingReq.push('Insufficient Coverage Workers Comp');
            }
            if(!coiCheck['cgl_AI'] || !coiCheck['ca_AI']) {
                missingReq.push('Additional Endorsement Needed "Additional Insured"');
            }
            if(!coiCheck['wc_wos']) {
                missingReq.push('Additional Endorsement Needed "Waiver of Subrogation"');
            }
            if(!coiCheck['certificate_holder'] || coiCheck['certificate_holder'].indexOf(respropNeeds.certificate_holder) < 0 ||
                coiCheck['certificate_holder'].indexOf(respropNeeds.certificate_holder2) < 0
            ) {
                missingReq.push('Resprop Management should be certificate holder');
            }
            const descriptionToMatch = riskLevel === 'HIGH' ? respropNeeds.description_high : respropNeeds.description_low;
            if(!coiCheck['description'] || coiCheck['description'].indexOf(descriptionToMatch) < 0){
                missingReq.push('Description of operations should match sample COI');
            }
        } else {
            missingReq.push('Wrong Format');
        }
    } else {
        missingReq.push('Wrong Doc');
    }
    return missingReq;
}

export const getComplianceIssues = (coiCheck: any, riskLevel: string) => {
    const complianceIssues = isRequirementComplete(coiCheck, riskLevel);
    return complianceIssues;
}
    // switch(isCompliantMessage) {
    //     case 'Missing Coverage':
    //         return '<b>Missing coverage</b><br/><br/>Hi! It looks like you do not have the following coverage type that is needed in order to work for ResProp Management: Commercial Auto Insurance.<br/><br/>We are connecting you with one of our experts who can quickly help you. Please wait…'
    //     case 'Insufficient Coverage':
    //         return '<b>Insufficient Coverage</b><br/><br/>Hi! It looks like some of your coverage limits do not match what is needed in order to work with ResProp Management.<br/><br/>We are connecting you with one of our experts who can quickly help you. Please wait…';
    //     case 'Additional Endorsement Needed':
    //         return '<b>Additional Endorsements Needed</b><br/><br/>Hi! You might need to list ResProp as an additional insured and have a waiver of subrogation.<br/><br/>We are connecting you with one of our experts who can assist you in getting these added. Please wait…';
    //     case 'Wrong Format':
    //         return "Hi! It looks like the format of your documents doesn't match. We are going to connect you with an expert who can help get the necessary information from you. Please wait…";
    //     case 'Wrong Doc':
    //         return '';
    //     default:
    //         return '';