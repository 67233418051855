import { Context, createContext, useState } from 'react';
import { getConfiguration } from '../api';

interface IConfig {
	evidentBGCheckBaseUrl?: string;
	intercomId?: string;
}

interface IConfigStorage {
	config: IConfig;
}

const initialConfig: IConfig = {};

const initialConfigStorage: IConfigStorage = {
	config: initialConfig,
};

const useConfig = (): IConfigStorage => {
	const [config, setConfig] = useState(initialConfig);

	if (Object.keys(config).length === 0) {
		getConfiguration()
			.then(res => {
				setConfig(res);
			})
			.catch(err => {
				setConfig(initialConfig);
			});
	}

	return { config };
};

export const ConfigContext: Context<IConfigStorage> = createContext(initialConfigStorage);

export const ConfigContextProvider = ({ children }: any) => {
	const config = useConfig();
	return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
