import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { W9FileUpload} from './upload-file';
import { W9Form } from '.';
import { SessionContext } from "../../../context/SessionContext";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

export const W9Landing = () => {
	const [tabIndex, setTabIndex] = useState<number>(1);
	const [data, setData] = useState<any>(null);

	const selectTabIndex = (index: number) => {
		setTabIndex(index);
	};

	const updateW9Info = (w9ExtractedData: any) => {
		setData({
			businessName: w9ExtractedData.name ? w9ExtractedData.name : w9ExtractedData.dba,
			dba: w9ExtractedData.dba ? w9ExtractedData.dba : w9ExtractedData.name,
			federalTaxClassification: w9ExtractedData.entity_type,
			address: w9ExtractedData.address,
			city: w9ExtractedData.city,
			state: w9ExtractedData.state,
			zipcode: w9ExtractedData.zip,
			tinType: w9ExtractedData.tin_type.toUpperCase(),
			tin: w9ExtractedData.tin,
			repeatTin: w9ExtractedData.tin,
			certification: false,
			eSignature: '',
			checkedSignature: false,
		});
	}

	return (
		<div>
			<TabPanel value={tabIndex} index={1}>
				<W9FileUpload tabChanger={selectTabIndex} updateW9Info={updateW9Info} />
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				<W9Form tabChanger={selectTabIndex} data={data} />
			</TabPanel>
		</div>
	);
};
