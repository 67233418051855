import { createTheme } from '@mui/material/styles';
import { typography } from './options';

export const themeColors = {
	primary: '#243B53',
	primaryHover: '#5A6490',
	secondary: '#2ECE9E',
	error: '#FF3B30',
	lime: '#59c802',
	purple: '#9348B7',
	yellowDark: '#E69E33',
	redlight: '#FF5858',
	white: '#fff',
	textColor: '#0D0D0D',
	bodyBg: '#E5E5E5',
	linkBlue: '#1976D2',
	grey: '#C4C4C4',
	gray1: '#666666',
};
export const borderRadius = {
	full: '500px',
};
const theme = createTheme({
	typography: typography,
	components: {
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: true, // No more ripple, on the whole application 💣!
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0,
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					paddingTop: 0,
					paddingBottom: 0,
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					lineHeight: '24px',
					letterSpacing: '.15px',
					fontWeight: 500,
					textTransform: 'initial',
					padding: '6px 10px',
					width: '100%',
					height: '48px',
					background: themeColors.primary,
					color: themeColors.white,
					borderRadius: '8px',
					'&:hover': {
						background: themeColors.primaryHover,
						boxShadow: `0 0 0 0.25rem rgba(13, 23, 67, .2)`,
					},
					'&:disabled': {
						color: themeColors.white,
						backgroundColor: themeColors.grey,
					},
				},
			},
			variants: [
				{
					props: { variant: 'primary' },
					style: {
						background: themeColors.primary,
						color: themeColors.white,
					},
				},
				{
					props: { variant: 'link' },
					style: {
						color: themeColors.linkBlue,
						textDecoration: 'underline',
						background: 'transparent',
						width: 'auto',
						height: 'auto',
						'&:hover': {
							background: 'transparent',
							boxShadow: 'none',
							textDecoration: 'underline',
						},
					},
				},
				{
					props: { variant: 'iconButton' },
					style: {
						height: '32px',
						'&:hover': {
							background: themeColors.primaryHover,
						},
					},
				},
			],
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: '#1976D2',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: 'auto',
					background: themeColors.white,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontWeight: 600,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					alignItems: 'start',
					padding: '9px 0',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 16,
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(0, 0, 0, 0.8)',
				},
			},
		},
	},
	palette: {
		primary: {
			main: themeColors.primary,
		},
		lime: {
			main: themeColors.lime,
			contrastText: themeColors.white,
		},
		purple: {
			main: themeColors.purple,
			contrastText: themeColors.white,
		},
		yellowDark: {
			main: themeColors.yellowDark,
			contrastText: themeColors.white,
		},
		redlight: {
			main: themeColors.redlight,
			contrastText: themeColors.white,
		},
		background: {
			default: '#E5E5E5',
		},
		text: {
			primary: themeColors.textColor,
			secondary: '',
		},
		error: {
			main: themeColors.error,
		},
	},
});

declare module '@mui/material/styles' {
	interface Palette {
		lime: Palette['primary'];
		purple: Palette['primary'];
		yellowDark: Palette['primary'];
		redlight: Palette['primary'];
	}

	// allow configuration using `createTheme`
	interface PaletteOptions {
		lime?: PaletteOptions['primary'];
		purple?: PaletteOptions['primary'];
		yellowDark?: PaletteOptions['primary'];
		redlight?: PaletteOptions['primary'];
	}

	interface TypographyVariants {
		poster: React.CSSProperties;
		body2Spaced: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		poster?: React.CSSProperties;
		body2Spaced: React.CSSProperties;
	}
}
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		primary: true;
		iconButton: true;
		badge: true;
		primaryRoundFull: true;
		link: true;
	}
	interface ButtonPropsColorOverrides {
		lime: true;
		purple: true;
		yellowDark: true;
		redlight: true;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		poster: true;
		body2Spaced: true;
	}
}
export default theme;
