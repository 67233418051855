import { useEffect, useState, useContext } from 'react';
import {
	Typography,
	FormGroup,
	Checkbox,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Box,
	Alert,
	Stack, Link
} from '@mui/material';
import * as Yup from 'yup';
import apiClient from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import { ReactComponent as DownArrow } from '../../../svg/Down-arrow.svg';
import { Spinner } from '../../loaders';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveAndContinue from '../../save-and-continue';
import { useLocation, useNavigate } from 'react-router-dom';

interface IInsurance {
	[key: string]: string;
}

interface IDataInsurance {
	generalLiability?: IInsurance | null;
	autoLiability?: IInsurance | null;
	excessLiability?: IInsurance | null;
	workerPolicy?: IInsurance | null;
}

interface Props {
	tabChanger: (index:number) => void;
}
const InsuranceForm = ({tabChanger}: Props) => {
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const [error, setError] = useState<string | null>(null);
	const [isQA, setIsQA] = useState(false);
	const [qaHasError, setQaHasError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [dataInsurance, setDataInsurance] = useState<IDataInsurance>({
		generalLiability: null,
		autoLiability: null,
		excessLiability: null,
		workerPolicy: null,
	});
	const [nextTab, setNextTab] = useState<number>(0);
	const brokerSchema = Yup.object().shape({
		brokerContact: Yup.string().required(),
		brokerEmail: Yup.string().required('Agency Email is Required').email('Agency Email is Required'),
		brokerName: Yup.string().required(),
		brokerPhone: Yup.string().required(),
		policyNo: Yup.string().required(),
	});
	const commonBrokerSchema = Yup.object().shape({
		policyNo: Yup.string().required(),
	});

	const [QA, setQA] = useState<any>({
		generalLiability: false,
		autoLiability: false,
		workerPolicy: false,
		excessLiability: false,
		isSameAgency: true,
	});
	const getValidationSchema = (): any => {
		let validationSchema = {};
		if (!QA.isSameAgency) {
			Object.keys(QA).forEach((q: string) => {
				if (q !== 'isSameAgency' && QA[q]) {
					validationSchema = {
						...validationSchema,
						[q]: brokerSchema,
					};
				}
			});
		} else {
			const policies = Object.keys(QA).filter(q => q !== 'isSameAgency' && QA[q]);
			policies.forEach((q: string) => {
				validationSchema = {
					...validationSchema,
					[q]: commonBrokerSchema,
				};
			});
			if (policies.length) {
				validationSchema = {
					...validationSchema,
					generalLiability: brokerSchema,
				};
			}
		}
		return validationSchema;
	};
	const {
		register,
		unregister,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(Yup.object().shape(getValidationSchema())),
	});

	const { session } = useContext(SessionContext);
	const {
		details: { vendorId },
	} = session;
	const checkSameAgent = (insurances: any) => {
		const policies = Object.keys(insurances);
		let isSame: boolean = false;
		for (let index = 0; index < policies.length; index++) {
			if (
				insurances[policies[index]].brokerName !== insurances[policies[0]].brokerName ||
				insurances[policies[index]].brokerPhone !== insurances[policies[0]].brokerPhone ||
				insurances[policies[index]].brokerContact !== insurances[policies[0]].brokerContact ||
				insurances[policies[index]].brokerEmail !== insurances[policies[0]].brokerEmail
			) {
				isSame = false;
				break;
			}
		}
		return isSame;
	};
	const reValidation = () => {
		const schema = getValidationSchema();
		Object.keys(schema).forEach(name => {
			unregister(name);
			register(name);
		});
	};
	useEffect(() => {
		Promise.all([
			apiClient.getGeneralLiability(vendorId),
			apiClient.getAutoLiability(vendorId),
			apiClient.getExcessLiability(vendorId),
			apiClient.getWorkersComp(vendorId),
		]).then(([r1, r2, r3, r4]: any) => {
			const insurances: any = {};
			if (Object.keys(r1.data).length > 0) {
				insurances.generalLiability = r1.data;
				if (typeof r1.data === 'object' && Object.keys(r1.data).length) {
					setIsQA(true);
				}
			}
			if (Object.keys(r2.data).length > 0) {
				insurances.autoLiability = r2.data;
				if (typeof r2.data === 'object' && Object.keys(r2.data).length) {
					setIsQA(true);
				}
			}
			if (Object.keys(r3.data).length > 0) {
				insurances.excessLiability = r3.data;
				if (typeof r3.data === 'object' && Object.keys(r3.data).length) {
					setIsQA(true);
				}
			}
			if (Object.keys(r4.data).length > 0) {
				insurances.workerPolicy = r4.data;
				if (typeof r4.data === 'object' && Object.keys(r4.data).length) {
					setIsQA(true);
				}
			}
			setDataInsurance({ ...insurances });
			const isSameAgency = checkSameAgent(insurances);
			setQA((values: any) => ({
				generalLiability: Object.keys(r1.data).length > 0,
				autoLiability: Object.keys(r2.data).length > 0,
				excessLiability: Object.keys(r3.data).length > 0,
				workerPolicy: Object.keys(r4.data).length > 0,
				isSameAgency: isSameAgency,
			}));
		});
		reValidation();
	}, []);

	useEffect(() => {
		// Check QA has policies and show error if not
		const policies = Object.keys(QA).filter(q => q !== 'isSameAgency' && QA[q]);
		if (policies.length <= 0) {
			setIsQA(false);
		} else {
			setIsQA(true);
			setQaHasError(false);
		}
	}, [QA]);

	useEffect(() => {
		reValidation();
	}, [dataInsurance]);

	useEffect(() => {
		if(nextTab > 0) {
			handleSubmit(goNext)();
		}
	}, [nextTab]);

	const handleChangeQA = (event: any, value: any) => {
		event.persist();
		setQA((values: any) => ({
			...values,
			[event.target.name]: value,
		}));
	};
	const handleChange = (event: any, MUIvalue?: any, attributeName?: string) => {
		if (event.persist) event.persist();
		const name = attributeName || event.target.name || '';
		const names = name.split('.');
		if (names.length > 1) {
			const insCopy: any = { ...dataInsurance };
			setDataInsurance({
				...insCopy,
				[names[0]]: {
					...insCopy[names[0]],
					[names[1]]: MUIvalue || event.target.value,
				},
			});
		} else if (names.length === 1) {
			setDataInsurance({
				...dataInsurance,
				[names[0]]: MUIvalue || event.target.value,
			});
		}
	};
	const autoFillAutolUseGeneralContact = () => {
		setDataInsurance({
			...dataInsurance,
			autoLiability: dataInsurance.generalLiability,
		});
	};
	const autoFillWorkerUseAutoContact = () => {
		setDataInsurance({
			...dataInsurance,
			workerPolicy: dataInsurance.autoLiability,
		});
	};
	const autoFillWorkerUseGeneralContact = () => {
		setDataInsurance({
			...dataInsurance,
			workerPolicy: dataInsurance.generalLiability,
		});
	};
	const autoFillExesssUseGeneralContact = () => {
		setDataInsurance({
			...dataInsurance,
			excessLiability: dataInsurance.generalLiability,
		});
	};

	const autoFillExesssUseWorkerContact = () => {
		setDataInsurance({
			...dataInsurance,
			excessLiability: dataInsurance.workerPolicy,
		});
	};
	const autoFillExesssUseAutoContact = () => {
		setDataInsurance({
			...dataInsurance,
			excessLiability: dataInsurance.autoLiability,
		});
	};

	const submitQuestionaire = () => {
		const policies = Object.keys(QA).filter(q => q !== 'isSameAgency' && QA[q]);

		if (policies.length === 0) {
			setQaHasError(true);
			/* goNext();
			if (state?.next) {
				navigate(state.next);
			} else {
				navigate('/vendor/enrollment/msa');
			} */
		} else {
			setIsQA(true);
			setQaHasError(false);
		}
	};
	const goNext = () => {
		const requestsCall = Object.keys(QA).filter(q => q !== 'isSameAgency');

		requestsCall.map((q: string) => {
			let insuData: any = { ...dataInsurance };
			let data: any = {};
			if (QA[q]) {
				const { policyId, policyNo } = insuData[q];
				if (QA.isSameAgency)
					data = {
						...dataInsurance.generalLiability,
						policyId,
						policyNo,
					};
				else data = { ...insuData[q] };
			} else {
				const { policyId } = insuData[q] || {};
				data = { policyId };
			}
			switch (q) {
				case 'generalLiability':
					if (QA[q]) return apiClient.submitGeneralLiability(vendorId, data);
					else if (data.policyId) {
						return apiClient.deleteGeneralLiability(vendorId, data.policyId);
					}
					break;
				case 'autoLiability':
					if (QA[q]) return apiClient.submitAutoLiability(vendorId, data);
					else if (data.policyId) {
						return apiClient.deleteAutoLiability(vendorId, data.policyId);
					}
					break;

				case 'workerPolicy':
					if (QA[q]) return apiClient.submitWorkersComp(vendorId, data);
					else if (data.policyId) {
						return apiClient.deleteWorkersComp(vendorId, data.policyId);
					}
					break;

				case 'excessLiability':
					if (QA[q]) return apiClient.submitExcessLiability(vendorId, data);
					else if (data.policyId) {
						return apiClient.deleteExcessLiability(vendorId, data.policyId);
					}
					break;
				default:
					throw new Error('No valid policy type found');
			}
		});

		setSubmitting(true);
		Promise.all(requestsCall)
			.then(r => {
				setSubmitting(false);
				if (state?.next) {
					navigate(state.next);
				} else {
					if(nextTab && nextTab > 0){
						tabChanger(nextTab);
					} else {
						navigate('/vendor/enrollment/msa');
					}
				}
			})
			.catch((err: any) => {
				if (err.response) {
					if (typeof err.response.data === 'string') setError(err.response.data || 'Something went wrong.');
					else setError('Something went wrong.');
				} else {
					setError('Something went wrong.');
				}
			});
	};

	const hasOne = (): boolean => {
		const keys = Object.keys(QA).filter(q => q !== 'isSameAgency' && QA[q]);
		return !!keys.length;
	};

	const changeToUploadTab = () => {
		setNextTab(1);
	}

	return (
		<div className="customCard">
			<Stack direction="row" spacing={2} justifyContent="space-between"
						 alignItems="flex-start">
			<Typography variant="body2" className="steps-header">
				4 of 7
			</Typography>
				{ Object.keys(dataInsurance).length > 0 && (
				<Link
					component={'button'}
					href="#" underline="none"
					variant="caption"
					onClick={() => {
						changeToUploadTab();
					}}
				>
					Upload a COI File
				</Link>
				)}
			</Stack>
			<Typography variant="h2">Certification of Insurance Information</Typography>
			<div className="afterArrowImg">
				<Typography variant="body2">
					It is important to enter the correct email address and phone number for your insurance agency. Your agency will
					receive an email requesting the Certificate of Insurance and the required Endorsements. It will cause delays in
					your compliance verification if your agent does not receive the COI request.
				</Typography>
				<DownArrow className="d-arrow" />
			</div>
			<div>
				<div className="inputWrap mb12">
					<label className={`inputLabel ${qaHasError ? 'errorText' : ''}`}>
						Select the insurance polices you have <span className="textDanger">*</span>
					</label>
					<FormGroup>
						<FormControlLabel
							value={QA.generalLiability}
							control={
								<Checkbox
									name="generalLiability"
									checked={QA.generalLiability}
									onChange={handleChangeQA}
									icon={<span className="badge">Commercial General Liability</span>}
									checkedIcon={<span className="badge checked invert">Commercial General Liability</span>}
								/>
							}
							label=""
							labelPlacement="end"
						/>
						<FormControlLabel
							value={QA.autoLiability}
							control={
								<Checkbox
									name="autoLiability"
									checked={QA.autoLiability}
									onChange={handleChangeQA}
									icon={<span className="badge">Commercial Automobile Liability</span>}
									checkedIcon={<span className="badge checked invert">Commercial Automobile Liability</span>}
								/>
							}
							label=""
							labelPlacement="end"
						/>
						<FormControlLabel
							value={QA.workerPolicy}
							control={
								<Checkbox
									name="workerPolicy"
									checked={QA.workerPolicy}
									onChange={handleChangeQA}
									icon={<span className="badge">Workers Compensation And Employers Liability</span>}
									checkedIcon={<span className="badge checked invert">Workers Compensation And Employers Liability</span>}
								/>
							}
							label=""
							labelPlacement="end"
						/>
						<FormControlLabel
							value={QA.excessLiability}
							control={
								<Checkbox
									name="excessLiability"
									checked={QA.excessLiability}
									onChange={handleChangeQA}
									icon={<span className="badge">Excess Liability</span>}
									checkedIcon={<span className="badge checked invert">Excess Liability</span>}
								/>
							}
							label=""
							labelPlacement="end"
						/>
					</FormGroup>
					<div className="inputWrap buttonCheck mb12">
						<label className="inputLabel">
							Do you use the same broker/agency for all of your policies? <span className="textDanger">*</span>
						</label>
						<RadioGroup className="radioBox" defaultValue={true} name="qa-same-agency">
							<div className="badgeWrap">
								<FormControlLabel
									value={true}
									control={
										<Radio
											name="isSameAgency"
											checked={QA.isSameAgency}
											onChange={(e, v) => handleChangeQA(e, true)}
											icon={<span className="badge">Yes</span>}
											checkedIcon={<span className="badge inverted checked">Yes</span>}
										/>
									}
									label=""
								/>
								<FormControlLabel
									value={false}
									control={
										<Radio
											name="isSameAgency"
											checked={!QA.isSameAgency}
											onChange={(e, v) => handleChangeQA(e, false)}
											icon={<span className="badge">No</span>}
											checkedIcon={<span className="badge inverted checked">No</span>}
										/>
									}
									label=""
								/>
							</div>
						</RadioGroup>
					</div>
				</div>

				{isQA && (
					<>
						{!QA.isSameAgency && QA.generalLiability && (
							<Typography variant="h2" className="afterVerticalLine">
								Commercial general liability
							</Typography>
						)}
						{((hasOne() && QA.isSameAgency) || QA.generalLiability) && (
							<>
								<div className="inputWrap mb12">
									<label htmlFor="agencyName" className="inputLabel">
										Insurance broker/agency name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.generalLiability?.brokerName}
										{...register('generalLiability.brokerName')}
										onChange={handleChange}
										value={dataInsurance.generalLiability?.brokerName}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.generalLiability?.brokerContact}
										{...register('generalLiability.brokerContact')}
										onChange={handleChange}
										value={dataInsurance.generalLiability?.brokerContact}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your contact at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency email address <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.generalLiability?.brokerEmail}
										{...register('generalLiability.brokerEmail')}
										onChange={handleChange}
										value={dataInsurance.generalLiability?.brokerEmail}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the contact email address at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact phone <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.generalLiability?.brokerPhone}
										{...register('generalLiability.brokerPhone')}
										onChange={handleChange}
										value={dataInsurance.generalLiability?.brokerPhone}
										hiddenLabel
										variant="outlined"
									/>

									<Typography className="textMuted inputNote" variant="body2">
										Enter a contact phone number at your insurance broker/agency
									</Typography>
								</div>
							</>
						)}

						{QA.generalLiability && (
							<div className="inputWrap mb12">
								<label htmlFor="cglpNo" className="inputLabel">
									Commercial general liability policy no.
									<span className="textDanger">*</span>
								</label>
								<TextField
									error={!!errors?.generalLiability?.policyNo}
									{...register('generalLiability.policyNo')}
									onChange={handleChange}
									value={dataInsurance.generalLiability?.policyNo}
									hiddenLabel
									variant="outlined"
								/>
								<Typography className="textMuted inputNote" variant="body2">
									Enter “none” if you do not have a commercial general liability policy
								</Typography>
							</div>
						)}

						{!QA.isSameAgency && QA.autoLiability && (
							<Typography variant="h2" className="afterVerticalLine">
								Commercial automobile liability
							</Typography>
						)}
						{!QA.isSameAgency && QA.autoLiability && (
							<div className="inputWrap mb12">
								<FormControlLabel
									control={<Radio onClick={autoFillAutolUseGeneralContact} />}
									label="Use Commercial General Liability info"
									labelPlacement="end"
								/>
							</div>
						)}

						{!QA.isSameAgency && QA.autoLiability && (
							<>
								<div className="inputWrap mb12">
									<label htmlFor="autoLiability.brokerName" className="inputLabel">
										Insurance broker/agency name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.autoLiability?.brokerName}
										{...register('autoLiability.brokerName')}
										onChange={handleChange}
										value={dataInsurance.autoLiability?.brokerName}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.autoLiability?.brokerContact}
										{...register('autoLiability.brokerContact')}
										onChange={handleChange}
										value={dataInsurance.autoLiability?.brokerContact}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your contact at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency email address <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.autoLiability?.brokerEmail}
										{...register('autoLiability.brokerEmail')}
										onChange={handleChange}
										value={dataInsurance.autoLiability?.brokerEmail}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the contact email address at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact phone <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.autoLiability?.brokerPhone}
										{...register('autoLiability.brokerPhone')}
										onChange={handleChange}
										value={dataInsurance.autoLiability?.brokerPhone}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter a contact phone number at your insurance broker/agency
									</Typography>
								</div>
							</>
						)}

						{QA.autoLiability && (
							<div className="inputWrap mb12">
								<label htmlFor="policyNo" className="inputLabel">
									Commercial automobile liability policy no.
									<span className="textDanger">*</span>
								</label>
								<TextField
									error={!!errors?.autoLiability?.policyNo}
									{...register('autoLiability.policyNo')}
									onChange={handleChange}
									value={dataInsurance.autoLiability?.policyNo}
									hiddenLabel
									variant="outlined"
								/>
								<Typography className="textMuted inputNote" variant="body2">
									Enter “none” if you do not have a commercial automobile liability policy
								</Typography>
							</div>
						)}

						{!QA.isSameAgency && QA.workerPolicy && (
							<Typography variant="h2" className="afterVerticalLine">
								Workers compensation and employers' liability
							</Typography>
						)}
						{QA.workerPolicy && (
							<div className="inputWrap mb12">
								{!QA.isSameAgency && (
									<FormGroup>
										<RadioGroup>
											{QA.generalLiability && (
												<FormControlLabel
													value="0"
													control={<Radio onClick={autoFillWorkerUseGeneralContact} />}
													label="Use Commercial General Liability info"
													labelPlacement="end"
												/>
											)}
											{QA.autoLiability && (
												<FormControlLabel
													value="1"
													control={<Radio onClick={autoFillWorkerUseAutoContact} />}
													label="Use Commercial Automobile Liability info"
													labelPlacement="end"
												/>
											)}
										</RadioGroup>
									</FormGroup>
								)}
							</div>
						)}

						{!QA.isSameAgency && QA.workerPolicy && (
							<>
								<div className="inputWrap mb12">
									<label htmlFor="agencyNameCommercialWorkerCompensationLiability" className="inputLabel">
										Insurance broker/agency name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.workerPolicy?.brokerName}
										{...register('workerPolicy.brokerName')}
										onChange={handleChange}
										value={dataInsurance.workerPolicy?.brokerName}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.workerPolicy?.brokerContact}
										{...register('workerPolicy.brokerContact')}
										onChange={handleChange}
										value={dataInsurance.workerPolicy?.brokerContact}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your contact at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency email address <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.workerPolicy?.brokerEmail}
										{...register('workerPolicy.brokerEmail')}
										onChange={handleChange}
										value={dataInsurance.workerPolicy?.brokerEmail}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the contact email address at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact phone <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors.workerPolicy?.brokerPhone}
										{...register('workerPolicy.brokerPhone')}
										onChange={handleChange}
										value={dataInsurance.workerPolicy?.brokerPhone}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter a contact phone number at your insurance broker/agency
									</Typography>
								</div>
							</>
						)}
						{QA.workerPolicy && (
							<div className="inputWrap mb12">
								<label htmlFor="wcelpNo" className="inputLabel">
									Workers compensation and employers' liability policy no.
									<span className="textDanger">*</span>
								</label>
								<TextField
									error={!!errors?.workerPolicy?.policyNo}
									{...register('workerPolicy.policyNo')}
									onChange={handleChange}
									value={dataInsurance.workerPolicy?.policyNo}
									hiddenLabel
									variant="outlined"
								/>
								<Typography className="textMuted inputNote" variant="body2">
									Enter “none” if you do not have a workers compensation and employers’ liability policy
								</Typography>
							</div>
						)}
						{!QA.isSameAgency && QA.excessLiability && (
							<Typography variant="h2" className="afterVerticalLine">
								Excess liability
							</Typography>
						)}
						{QA.excessLiability && !QA.isSameAgency && (
							<div className="inputWrap mb12">
								<FormGroup>
									<RadioGroup>
										{QA.generalLiability && (
											<FormControlLabel
												value="0"
												control={<Radio onClick={autoFillExesssUseGeneralContact} />}
												label="Use Commercial General Liability info"
												labelPlacement="end"
											/>
										)}
										{QA.autoLiability && (
											<FormControlLabel
												value="1"
												control={<Radio onClick={autoFillExesssUseAutoContact} />}
												label="Use Commercial Automobile Liability info"
												labelPlacement="end"
											/>
										)}
										{QA.workerPolicy && (
											<FormControlLabel
												value="2"
												control={<Radio onClick={autoFillExesssUseWorkerContact} />}
												label="Use Workers Compensation and Employers' Liability info"
												labelPlacement="end"
											/>
										)}
									</RadioGroup>
								</FormGroup>
							</div>
						)}

						{!QA.isSameAgency && QA.excessLiability && (
							<>
								<div className="inputWrap mb12">
									<label htmlFor="agencyNameCommercialExcessLiability" className="inputLabel">
										Insurance broker/agency name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.excessLiability?.brokerName}
										{...register('excessLiability.brokerName')}
										onChange={handleChange}
										value={dataInsurance.excessLiability?.brokerName}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact name <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.excessLiability?.brokerContact}
										{...register('excessLiability.brokerContact')}
										onChange={handleChange}
										value={dataInsurance.excessLiability?.brokerContact}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the name of your contact at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency email address <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.excessLiability?.brokerEmail}
										{...register('excessLiability.brokerEmail')}
										onChange={handleChange}
										value={dataInsurance.excessLiability?.brokerEmail}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter the contact email address at your insurance broker/agency
									</Typography>
								</div>
								<div className="inputWrap mb12">
									<label className="inputLabel">
										Insurance broker/agency contact phone <span className="textDanger">*</span>
									</label>
									<TextField
										error={!!errors?.excessLiability?.brokerPhone}
										{...register('excessLiability.brokerPhone')}
										onChange={handleChange}
										value={dataInsurance.excessLiability?.brokerPhone}
										hiddenLabel
										variant="outlined"
									/>
									<Typography className="textMuted inputNote" variant="body2">
										Enter a contact phone number at your insurance broker/agency
									</Typography>
								</div>
							</>
						)}

						{QA.excessLiability && (
							<div className="inputWrap mb12">
								<label htmlFor="celpNo" className="inputLabel">
									Excess liability policy no.
									<span className="textDanger">*</span>
								</label>
								<TextField
									error={!!errors?.excessLiability?.policyNo}
									{...register('excessLiability.policyNo')}
									onChange={handleChange}
									value={dataInsurance.excessLiability?.policyNo}
									hiddenLabel
									variant="outlined"
								/>
								<Typography className="textMuted inputNote" variant="body2">
									Enter “none” if you do not have an excess liability policy
								</Typography>
							</div>
						)}
					</>
				)}

				{submitting === false && Object.keys(dataInsurance).length > 0 ? (
					<SaveAndContinue onClick={isQA ? handleSubmit(goNext) : submitQuestionaire} buttonTitle="Save And Continue" />
				) : (
					<Spinner />
				)}
				{error && (
					<Box pt={2} pb={2}>
						<Alert severity="error">{error}</Alert>
					</Box>
				)}
			</div>
		</div>
	);
};

export default InsuranceForm;
