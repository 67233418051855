import React, { FC, useState, useEffect, useContext } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import ButtonCustom from '../../button';
import InputLabel from '@mui/material/InputLabel';
import apiClient from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import { convertTaxClassificationIdToStr, openInNewTab, until } from '../../../utils/helpers';
import { ReactComponent as EditIcon } from '../../../svg/edit-icon.svg';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import DownloadButton from '../../DownloadButton';
import { Spinner } from '../../loaders';
import NewReleasesIcon from '@mui/icons-material/DashboardOutlined';
import CCIcon from '@mui/icons-material/CreditCardOutlined';
import ACBIcon from '@mui/icons-material/AccountBalanceOutlined';

const styles = {
	align: {
		textAlign: 'center',
		width: 232,
	},
	line: {
		marginBottom: 0.5,
	},
	box: {
		marginBottom: 1,
	},
};

interface ISubjectInfo {
	firstName: string;
	middleName: string;
	lastName: string;
	dob: Date | string | null;
	ssn: string;
	streetAddress: string;
	state: string;
	zipCode: string;
	fullName: string;
	address: string;
	maskedSsn: string;
}

interface Props {}
interface NotFilledProps {
	text?: string;
}

interface IInsurance {
	[key: string]: string;
}

interface IDataInsurance {
	generalLiability: IInsurance | null;
	autoLiability: IInsurance | null;
	excessLiability: IInsurance | null;
	workerPolicy: IInsurance | null;
}

const NotFilledOut: FC<NotFilledProps> = ({ text }: NotFilledProps) => {
	return (
		<Box sx={{ fontStyle: 'italic' }}>
			<Typography sx={styles.line} className="textMuted" variant="body2">
				{text || 'Not Filled Out'}
			</Typography>
		</Box>
	);
};

const Summary = () => {
	const navigate = useNavigate();
	const [w9FileUrl, setW9FileUrl] = useState<any | null>(null);
	const [coiFileUrls, setcoiFileUrls] = useState<any[]>([]);
	const [dataW9, setDataW9] = useState({
		businessName: '',
		dba: '',
		federalTaxClassification: '',
		address: '',
		city: '',
		state: '',
		zipcode: '',
		tin: '',
		digitalSignature: '',
		certification: false,
		checkedSignature: false,
		tinType: '',
	});

	const [dataBusiness, setDataBusiness] = useState({
		email: '',
		companyEmail: '',
		accountsPayableEmail: '',
		phone: '',
		numEmployees: '',
		years: 0,
		employees: 0,
		website: '',
		services: [],
		riskLevels: [],
	});

	const [dataInsurance, setDataInsurance] = useState<IDataInsurance>({
		generalLiability: null,
		autoLiability: null,
		excessLiability: null,
		workerPolicy: null,
	});

	const [dataBackgroundCheck, setDataBackgroundCheck] = useState<ISubjectInfo | null>(null);

	const { session, isNoRisk, setIsNoRisk, isBgSkip, setIsBgSkip } = useContext(SessionContext);
	const [wait, setWait] = useState<boolean>(false);
	const {
		userId,
		details: { vendorId },
	} = session;
	const goNext = async () => {
		if (isNoRisk) {
			// await apiClient.updateNoRiskVendor(vendorId);
			navigate('/vendor/dashboard');
		} else {
			setWait(true);
			var extractionStatus = sessionStorage.getItem('doneCoiExtraction');
			if (extractionStatus === 'pending' || extractionStatus === 'done')
				await until((_: any) => sessionStorage.getItem('doneCoiExtraction') === 'done');
			setWait(false);
			navigate('/vendor/dashboard');
		}
	};
	const goEditW9 = () => {
		navigate('/vendor/enrollment/w9', { state: { next: '/vendor/enrollment/summary' } });
	};
	const goEditBusinessInfo = () => {
		navigate('/vendor/enrollment/business-info', { state: { next: '/vendor/enrollment/summary' } });
	};
	const goEditInsurance = () => {
		navigate('/vendor/enrollment/coi', { state: { next: '/vendor/enrollment/summary' } });
	};
	const goEditBgCheck = () => {
		navigate('/vendor/enrollment/background-check', { state: { next: '/vendor/enrollment/summary' } });
	};

	const downloadFile = (filename: string) => {
		apiClient
			.getFileUrl(vendorId, filename)
			.then(res => {
				console.log('res ', res);
				const { url } = res;
				openInNewTab(url);
			})
			.catch(err => {
				console.error(err);
			});
	};

	useEffect(() => {
		apiClient.getW9File(vendorId).then((r: any) => {
			if (r) {
				setW9FileUrl(r.key);
			} else {
				apiClient.getW9(vendorId).then((r: any) => {
					setDataW9({
						...r,
						federalTaxClassification: convertTaxClassificationIdToStr(r.federalTaxClassification),
					});
				});
			}
		});

		apiClient.getBizInfo(vendorId).then(async (data: any) => {
			setDataBusiness(data);
			if (data.riskLevels) {
				const risksSelected = data?.riskLevels.map((s: any) => s.riskLevel);
				const riskLevel = risksSelected.indexOf('HIGH') >= 0 ? 'HIGH' : risksSelected.indexOf('LOW') >= 0 ? 'LOW' : 'NONE';
				setIsNoRisk(riskLevel === 'NONE');
			}
		});

		apiClient.getCOIFile(vendorId).then((r: any) => {
			if (r) {
				setcoiFileUrls(r);
			} else {
				apiClient.getInsurancePolicies(vendorId).then(resp => {
					const { generalLiability, autoLiability, excessLiability, workerPolicy } = resp.data;
					setDataInsurance({ generalLiability, autoLiability, excessLiability, workerPolicy });
				});
			}
		});

		if (!isBgSkip) {
			apiClient.getW9(vendorId).then((res: any) => {
				// Reset the form with the saved values
				setIsBgSkip(res?.tinType === 'EIN');
			});
			apiClient
				.getBackgroundCheckInfo(userId)
				.then(res => {
					if (res) {
						let { subjectInfo } = res;
						let fullName = subjectInfo.firstName;
						if (subjectInfo.middleName) {
							fullName += ` ${subjectInfo.middleName} ${subjectInfo.lastName}`;
						} else {
							fullName += ` ${subjectInfo.lastName}`;
						}
						const { streetAddress, state, zipCode } = subjectInfo;
						subjectInfo.fullName = fullName;
						subjectInfo.address = `${streetAddress}, ${state}, ${zipCode}`;
						subjectInfo.maskedSsn = subjectInfo.ssn.substr(-4);
						try {
							const dob = DateTime.fromISO(subjectInfo.dob).toLocaleString(DateTime.DATE_FULL);
							subjectInfo.dob = dob;
							setDataBackgroundCheck(subjectInfo);
						} catch (err) {
							subjectInfo.dob = '';
							setDataBackgroundCheck(subjectInfo);
						}
					}
				})
				.catch((err: any) => {
					console.error(err);
				});
		}
	}, [userId, vendorId]);
	return (
		<>
			<Box sx={{ mt: 4, mb: 4 }}>
				<Typography variant="h2"> Summary </Typography>
				<div className="afterArrowImg">
					<Typography variant="body2">Review all of your information and make sure everything is correct.</Typography>
					{/* <DownArrow className="d-arrow" /> */}
				</div>
			</Box>
			<Box sx={{ mt: 4, mb: 4  }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '1em',
					}}
				>
					<Typography variant="h3"> Form W-9 Preview </Typography>
					<IconButton onClick={goEditW9} aria-label="Edit" className="IconButton editButton">
						<EditIcon />
					</IconButton>
				</Box>
				{w9FileUrl && (
					<>
						<Typography variant="caption"> Uploaded file </Typography>
						<Box pl={2} display="flex" alignItems="center">
							<DownloadButton
								onClick={() => {
									downloadFile(w9FileUrl);
								}}
								name={w9FileUrl.split('/').slice(-1)[0]}
							/>
						</Box>
					</>
				)}
				{!w9FileUrl && (
					<>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> Name </InputLabel>

							<Typography sx={styles.line} className="textMuted" variant="body2">
								{' '}
								{dataW9.businessName}{' '}
							</Typography>
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> DBA/Doing business as </InputLabel>
							{dataW9.dba ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{' '}
									{dataW9.dba}{' '}
								</Typography>
							) : (
								<NotFilledOut />
							)}
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> Federal tax classification </InputLabel>
							{dataW9.federalTaxClassification ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{' '}
									{dataW9.federalTaxClassification}{' '}
								</Typography>
							) : (
								<NotFilledOut />
							)}
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> Address </InputLabel>
							{dataW9.address ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{' '}
									{dataW9.address}{' '}
								</Typography>
							) : (
								<NotFilledOut text="Address Not Filled Out" />
							)}
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> City, State, Zip </InputLabel>
							{dataW9.city || dataW9.state || dataW9.zipcode ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{`${dataW9.city ? `${dataW9.city}, ` : ''}`}
									{`${dataW9.state ? `${dataW9.state}, ` : ''}`}
									{`${dataW9.zipcode ? `${dataW9.zipcode}` : ''}`}
								</Typography>
							) : (
								<NotFilledOut text="Address Not Filled Out" />
							)}
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> TIN Type </InputLabel>
							{dataW9.tinType ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{dataW9.tinType}{' '}
								</Typography>
							) : (
								<NotFilledOut />
							)}
						</Box>
						<Box sx={styles.box}>
							<InputLabel sx={styles.line}> TIN </InputLabel>
							{dataW9.tin ? (
								<Typography sx={styles.line} className="textMuted" variant="body2">
									{' '}
									{dataW9.tin}{' '}
								</Typography>
							) : (
								<NotFilledOut />
							)}
						</Box>
					</>
				)}
			</Box>
			<Box sx={{ mb: 2, mt: 4, display: 'flex', flexDirection: 'column', gap: '8px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '1em',
					}}
				>
					<Typography variant="h3"> Business Information </Typography>
					<IconButton onClick={goEditBusinessInfo} aria-label="Edit" className="IconButton editButton">
						<EditIcon />
					</IconButton>
				</Box>
				<Box sx={styles.box}>
					<InputLabel sx={styles.line}> Company email </InputLabel>
					{dataBusiness.email ? (
						<Typography sx={styles.line} className="textMuted" variant="body2">
							{' '}
							{dataBusiness.email}{' '}
						</Typography>
					) : (
						<NotFilledOut />
					)}
				</Box>
				<Box sx={styles.box}>
					<InputLabel sx={styles.line}> Accounts payable email </InputLabel>
					{dataBusiness.accountsPayableEmail ? (
						<Typography sx={styles.line} className="textMuted" variant="body2">
							{' '}
							{dataBusiness.accountsPayableEmail}{' '}
						</Typography>
					) : (
						<NotFilledOut />
					)}
				</Box>
				<Box sx={styles.box}>
					<InputLabel sx={styles.line}> Company phone number </InputLabel>
					{dataBusiness.phone ? (
						<Typography sx={styles.line} className="textMuted" variant="body2">
							{' '}
							{dataBusiness.phone}{' '}
						</Typography>
					) : (
						<NotFilledOut />
					)}
				</Box>

				<Box sx={styles.box}>
					<InputLabel sx={styles.line}> Number of employees</InputLabel>
					{dataBusiness.numEmployees ? (
						<Typography sx={styles.line} className="textMuted" variant="body2">
							{' '}
							{dataBusiness.numEmployees}{' '}
						</Typography>
					) : (
						<NotFilledOut />
					)}
				</Box>
				<Box sx={styles.box}>
					<InputLabel sx={styles.line}>Service Categories</InputLabel>
					{dataBusiness.services?.length > 0 ? (
						<Box sx={{display: 'flex', flexDirection: 'row', gap :'16px', alignItems: 'center'}}>
							{dataBusiness.services.map((s: any) => (
								<Box sx={{padding: '4px 12px',borderRadius: '16px', background: '#F0F4F8'}}>
									<Typography key={s.id} sx={styles.line} className="textMuted" variant="body2">
										{s.name}
									</Typography>
								</Box>
							))}
						</Box>
					) : (
						<NotFilledOut />
					)}
				</Box>
				<Box sx={styles.box}>
					<InputLabel sx={styles.line}>Services Provided</InputLabel>
					{dataBusiness.riskLevels?.length > 0 ? (
						<Box sx={{display: 'flex', flexDirection: 'row', gap :'16px', alignItems: 'center'}}>
							{dataBusiness.riskLevels.map((s: any) => (
								<Box sx={{padding: '4px 12px',borderRadius: '16px', background: '#F0F4F8'}}>
									<Typography key={s.id} sx={styles.line} className="textMuted" variant="body2">
										{s.name}
									</Typography>
								</Box>
							))}
						</Box>
					) : (
						<NotFilledOut />
					)}
				</Box>
			</Box>

			{!isBgSkip && (
				<Box sx={{ mt: 4, mb: 4 }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '1em',
						}}
					>
						<Typography variant="h3">{' Background Check Information '}</Typography>
						<IconButton onClick={goEditBgCheck} aria-label="Edit" className="IconButton editButton">
							<EditIcon />
						</IconButton>
					</Box>
					<Box sx={styles.box}>
						<InputLabel sx={styles.line}> Full Name </InputLabel>
						{dataBackgroundCheck?.fullName ? (
							<Typography sx={styles.line} className="textMuted" variant="body2">
								{' '}
								{dataBackgroundCheck?.fullName}{' '}
							</Typography>
						) : (
							<NotFilledOut />
						)}
					</Box>
					<Box sx={styles.box}>
						<InputLabel sx={styles.line}> Date of Birth </InputLabel>
						{dataBackgroundCheck?.dob ? (
							<Typography sx={styles.line} className="textMuted" variant="body2">
								<>{dataBackgroundCheck?.dob} </>
							</Typography>
						) : (
							<NotFilledOut />
						)}
					</Box>
					<Box sx={styles.box}>
						<InputLabel sx={styles.line}> Social Security Number </InputLabel>
						{dataBackgroundCheck?.ssn ? (
							<Typography sx={styles.line} className="textMuted" variant="body2">
								{' '}
								&lowast;&lowast;&lowast;-&lowast;&lowast;-{dataBackgroundCheck?.maskedSsn}{' '}
							</Typography>
						) : (
							<NotFilledOut />
						)}
					</Box>
					<Box sx={styles.box}>
						<InputLabel sx={styles.line}> Address </InputLabel>
						{dataBackgroundCheck?.ssn ? (
							<Typography sx={styles.line} className="textMuted" variant="body2">
								{' '}
								{dataBackgroundCheck?.address}{' '}
							</Typography>
						) : (
							<NotFilledOut />
						)}
					</Box>
				</Box>
			)}
			{!isNoRisk && (
				<Box sx={{ mb: 4, mt: 4 }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: '1em',
						}}
					>
						<Typography variant="h3"> Certification of Insurance Information </Typography>
						<IconButton onClick={goEditInsurance} aria-label="Edit" className="IconButton editButton">
							<EditIcon />
						</IconButton>
					</Box>
					{coiFileUrls && coiFileUrls.length > 0 && (
						<>
							<Typography variant="caption"> Uploaded file(s) </Typography>
							{coiFileUrls.map((value, i) => {
								return (
									<Box key={i}>
										<Box pl={2} display="flex" alignItems="center">
											<DownloadButton
												onClick={() => {
													downloadFile(value);
												}}
												name={value.key.split('/').slice(-1)[0]}
											/>
										</Box>
									</Box>
								);
							})}
						</>
					)}
					{!(coiFileUrls && coiFileUrls.length > 0) && (
						<>
							{dataInsurance.generalLiability && (
								<Box pt={0} pb={1}>
									<Box pb={2}>
										<Typography variant="body1" component="p">
											Commercial general liability
										</Typography>
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency name
										</Typography>
										{dataInsurance.generalLiability?.brokerName ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.generalLiability?.brokerName}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact name
										</Typography>
										{dataInsurance.generalLiability?.brokerContact ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.generalLiability.brokerContact}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency email address
										</Typography>
										{dataInsurance.generalLiability?.brokerEmail ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.generalLiability.brokerEmail}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact phone
										</Typography>
										{dataInsurance.generalLiability?.brokerPhone ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.generalLiability?.brokerPhone}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Commercial general liability policy no.
										</Typography>
										{dataInsurance.generalLiability?.policyNo ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.generalLiability?.policyNo}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
								</Box>
							)}
							{dataInsurance.autoLiability && (
								<Box pt={0} pb={1}>
									<Box pb={2}>
										<Typography variant="body1" component="p">
											Commercial automobile liability
										</Typography>
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency name
										</Typography>
										{dataInsurance.autoLiability?.brokerName ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.autoLiability?.brokerName}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact name
										</Typography>
										{dataInsurance.autoLiability?.brokerContact ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.autoLiability?.brokerContact}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency email address
										</Typography>
										{dataInsurance.autoLiability?.brokerEmail ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.autoLiability?.brokerEmail}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact phone
										</Typography>
										{dataInsurance.autoLiability?.brokerPhone ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.autoLiability?.brokerPhone}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Commercial automobile liability policy no.
										</Typography>
										{dataInsurance.autoLiability?.policyNo ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.autoLiability?.policyNo}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
								</Box>
							)}
							{dataInsurance.excessLiability && (
								<Box pt={0} pb={1}>
									<Box pb={2}>
										<Typography variant="body1" component="p">
											Excess liability
										</Typography>
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency name
										</Typography>
										{dataInsurance.excessLiability?.brokerName ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.excessLiability?.brokerName}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact name
										</Typography>
										{dataInsurance.excessLiability?.brokerContact ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.excessLiability?.brokerContact}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency email address
										</Typography>
										{dataInsurance.excessLiability?.brokerEmail ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.excessLiability?.brokerEmail}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact phone
										</Typography>
										{dataInsurance.excessLiability?.brokerPhone ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.excessLiability?.brokerPhone}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Excess liability policy no.
										</Typography>
										{dataInsurance.excessLiability?.policyNo ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.excessLiability?.policyNo}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
								</Box>
							)}
							{dataInsurance.workerPolicy && (
								<Box pt={0} pb={1}>
									<Box pb={2}>
										<Typography variant="body1" component="p">
											Workers compensation and employers' liability
										</Typography>
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency name
										</Typography>
										{dataInsurance.workerPolicy?.brokerName ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.workerPolicy?.brokerName}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact name
										</Typography>
										{dataInsurance.workerPolicy?.brokerContact ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.workerPolicy?.brokerContact}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency email address
										</Typography>
										{dataInsurance.workerPolicy?.brokerEmail ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.workerPolicy?.brokerEmail}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Insurance broker/agency contact phone
										</Typography>
										{dataInsurance.workerPolicy?.brokerPhone ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.workerPolicy?.brokerPhone}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
									<Box sx={styles.box}>
										<Typography variant="body2" component="p">
											Workers compensation and employers' liability policy no.
										</Typography>
										{dataInsurance.workerPolicy?.policyNo ? (
											<Typography sx={styles.line} className="textMuted" variant="body2">
												{dataInsurance.workerPolicy.policyNo}
											</Typography>
										) : (
											<NotFilledOut />
										)}
									</Box>
								</Box>
							)}
							<Box sx={styles.box}>
								<InputLabel sx={styles.line}> Your business name</InputLabel>
								{dataW9.businessName ? (
									<Typography sx={styles.line} className="textMuted" variant="body2">
										{' '}
										{dataW9.businessName}{' '}
									</Typography>
								) : (
									<NotFilledOut />
								)}
							</Box>
						</>
					)}
				</Box>
			)}

			<Box sx={{background: '#F1F5F8', height: '40px', marginX: '-40px'}}></Box>
			
			<Box sx={{mt: 4}}>
				<Box>
					<Typography sx={{fontSize: '24px', color: '#272937', fontWeight: 600, lineHeight: '32px'}}>Next Steps</Typography>
				</Box>
				<Box sx={{mt: '16px'}}>
					<Typography sx={{fontSize: '16px', color: '#272937', fontWeight: 400, lineHeight: '24px'}}>Congratulations on signing up for ID Core! Follow the steps below to join your first Property Management Company.</Typography>
				</Box>
				<Box sx={{mt: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: '40px', gap: '80px'}}>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
						<NewReleasesIcon sx={{width: '48px', height: '48px', color: '#334E68'}}/>
						<Typography>Going to Dashboard will show you what steps are needed from you in order to become compliant.</Typography>
					</Box>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
						<CCIcon sx={{width: '48px', height: '48px', color: '#334E68'}}/>
						<Typography>Pay the annual compliance fee to activate your account.</Typography>
					</Box>
					<Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
						<ACBIcon sx={{width: '48px', height: '48px', color: '#334E68'}}/>
						<Typography>After you meet all the requirements, set up a payment processing account so you can get paid.</Typography>
					</Box>
				</Box>
			</Box>
			<Box>{wait && <span>Please wait....</span>}</Box>
			<ButtonCustom
				onClick={goNext}
				disabled={false}
				className="bigButton submit-cta"
				round={'non-full'}
				variant="contained"
				color="primary"
				size="large"
			>
				{wait && <Spinner />}
				{/* {!wait && !isNoRisk && <span>Continue to Payment</span>} */}
				{!wait && <span>Submit and Go to Dashboard</span>}
			</ButtonCustom>
		</>
	);
};

export default Summary;
