import { Autocomplete, Box, Checkbox, Chip, Dialog, TextField, Typography } from "@mui/material"
import ArrowLeft from '@mui/icons-material/ArrowBackIosRounded';
import PlusIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useEffect, useState } from "react";
import apiClient from '../../api/index';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GoogleMapsAutoComplete from "src/components/google/autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const VendorProfile = ({editProfile, setEditProfile, vendorDetails}: any) => {

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [headQuaters, setHeadQuaters] = useState('');
    const [sAreas, setSAreas] = useState<any[]>([]);
    const [locations, setLocations] = useState<any[]>([]);

    const [serviceAreasInputText, setServiceAreasInputText] = useState('');
    const [selectedServiceAreasError, setSelectedServiceAreasError] = useState<String>('');
    const [showLocations, setShowLocations] = useState(false);

    // Service Categories Drop Down
	const [servicesInputText, setServicesInputText] = useState('');
	const [services, setServices] = useState<any[]>([]);
	const [selectedServices, setSelectedServices] = useState<any[]>([]);
	const [selectedServicesError, setSelectedServicesError] = useState<String>('');

    // Services Provided Drop Down
	const [servicesProvidedInputText, setServicesProvidedInputText] = useState('');
	const [servicesProvided, setServicesProvided] = useState<any[]>([]);
	const [selectedServicesProvided, setSelectedServicesProvided] = useState<any[]>([]);
	const [selectedServicesProvidedError, setSelectedServicesProvidedError] = useState<String>('');
	const [servicesProvidedSearchTerm, setServicesProvidedSearchTerm] = useState<string>('');

    useEffect(() => {
        if(vendorDetails) {
            setEmail(vendorDetails.bizInfo?.email);
            setPhone(vendorDetails.bizInfo?.phone);
            setHeadQuaters(vendorDetails.bizInfo?.locationOfHeadQuarters);
            setLocations([]);
            getBizInfo(vendorDetails._id);
            getServiceAreas();
        }
    }, [vendorDetails]);

    const getBizInfo = async(vendorId: any) => {
        apiClient
			.getBizInfo(vendorId).then(async (r: any) => {
				setSelectedServices(r?.services);
				setSelectedServicesProvided(r?.riskLevels);
			})
			.catch(err => {
				console.error(err);
			})
    }

    function getServiceAreas() {
		apiClient
			.getServiceAreas()
			.then((ar: any) => {
				setSAreas(ar.data);
				if (vendorDetails && vendorDetails.bizInfo?.serviceareas) {
					const selected = ar.data.filter((item: any) => vendorDetails.bizInfo?.serviceareas.find((sub: any) => sub === item.id));
					setLocations(selected);
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

    useEffect(() => {
		apiClient
			.getServices()
			.then((r: any) => {
				setServices(r.data);
			})
			.catch(err => {
				console.error(err);
			});

        if(selectedServices?.length) {
            // TOOD The ResProp pmcId shouldn't be hardcoded here.
            const riskLevelFilters = {
                pmcId: '61bfe94e5278deb7dedca562',
                categories: selectedServices.map(s => s.categoryId),
                q: servicesProvidedSearchTerm,
            };
            apiClient
                .getServiceRiskLevels(riskLevelFilters)
                .then((res: any) => {
                    setServicesProvided(res);
                })
                .catch((err: any) => {
                    console.error(err);
                });
        }
		
	}, [selectedServices, servicesProvidedSearchTerm]);

    const onSubmit = async() => {
        const payload = {
            email: email,
			accountsPayableEmail: vendorDetails?.bizInfo?.accountsPayableEmail,
            phone: phone,
            numEmployees: vendorDetails?.bisInfo?.numEmployees,
			locationOfHeadQuarters: headQuaters,
            isOutsideLocations: locations.length ? true : vendorDetails?.bizInfo?.isOutsideLocations,
            services: selectedServices.map(v => v.id),
            riskLevels: selectedServicesProvided.map(v => v.id),
            serviceareas: locations.map(v => v.id),
        }
        apiClient
			.submitBizInfo(vendorDetails?._id, payload).then(r => {setEditProfile(false, true)});
    }

    return  <Dialog open={editProfile} fullWidth>
        <Box p={4}>
            <Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={() => setEditProfile(false)}>
                <ArrowLeft sx={{width: '20px', height: '20px'}}/>
                <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#102A43', fontWeight: 600}}>Back to Dashboard</Typography>
            </Box>
            <Box sx={{marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '32px'}}>
                <Box>
                    <Typography sx={{fontSize: '24px', lineHeight: '28px', color: '#0D0D0D'}}>{vendorDetails?.w9?.businessName}</Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Email</Typography>
                    <TextField value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Phone Number</Typography>
                    <TextField value={phone} onChange={(e) => setPhone(e.target.value)}></TextField>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Headquarters Address</Typography>
                    {/* <TextField value={headQuaters} onChange={(e) => setHeadQuaters(e.target.value)}></TextField> */}
                    <GoogleMapsAutoComplete currVal={headQuaters} placeholder='Type in City and State' onMapValChanged={(val: any) => {
                        setHeadQuaters(val.structured_formatting.main_text);
                    }}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>{locations.length > 0 ? '' : 'No ' } Additional Locations</Typography>
                    {(!showLocations && locations.length === 0) && <Box onClick={() => {setShowLocations(true)}} sx={{padding: '8px', paddingRight: '14px', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', cursor: 'pointer'}}>
                        <PlusIcon sx={{width: '20px', height: '20px', color: '#243B53'}}/>
                        <Typography sx={{color: '#243B53', fontSize: '14px', lineHeight: '20px', fontWeight: '600'}}>Add Location</Typography>
                    </Box>}
                    {(showLocations || locations.length) && <Autocomplete
                        id="serviceareas-list"
                        value={locations}
                        onChange={(e, v) => {
                            setLocations(v);
                            setSelectedServiceAreasError('');
                        }}
                        inputValue={serviceAreasInputText}
                        onInputChange={(event, value, reason) => {
                            if (event && event.type === 'blur') {
                                setServiceAreasInputText('');
                            } else if (reason !== 'reset') {
                                setServiceAreasInputText(value);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={sAreas.sort((a, b) => -b.state?.localeCompare(a.state))}
                        filterOptions={(options, { inputValue }) =>
                            options.filter(
                                item =>
                                    item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                    item.state.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            )
                        }
                        getOptionLabel={option => `${option.name}, ${option.stateCode}`}
                        groupBy={option => option.state}
                        fullWidth
                        multiple
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.name}
                            </li>
                        )}
                        disableCloseOnSelect
                        renderInput={params => (
                            <TextField hiddenLabel {...params} placeholder="Service area" error={selectedServiceAreasError !== ''} />
                        )}
                    />}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Service Categories</Typography>
                    <Autocomplete
                        id="services-list"
                        value={selectedServices}
                        onChange={(e, v) => {
                            setSelectedServices(v);
                            const catIds = v.map(service => service.categoryId);
                            const selRiskLevels = selectedServicesProvided.filter(
                                selRiskLevel => catIds.indexOf(selRiskLevel.categoryId) >= 0
                            );
                            setSelectedServicesProvided(selRiskLevels);
                            setSelectedServicesError('');
                        }}
                        inputValue={servicesInputText}
                        onInputChange={(event, value, reason) => {
                            if (event && event.type === 'blur') {
                                setServicesInputText('');
                            } else if (reason !== 'reset') {
                                setServicesInputText(value);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={services.sort((a, b) => -b.categoryName?.localeCompare(a.categoryName))}
                        filterOptions={(options, { inputValue }) =>
                            options.filter(
                                item =>
                                    item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                                    item.categoryName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            )
                        }
                        groupBy={option => option.categoryName}
                        getOptionLabel={option => option.name}
                        fullWidth
                        multiple
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.name}
                            </li>
                        )}
                        disableCloseOnSelect
                        renderInput={params => (
                            <TextField hiddenLabel {...params} placeholder="Service name" error={selectedServicesError !== ''} />
                        )}
                    />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Service Provided</Typography>
                    <Autocomplete
						id="provided-services-list"
						value={selectedServicesProvided}
						onChange={(e, v) => {
							setSelectedServicesProvided(v);
							setSelectedServicesProvidedError('');
						}}
						inputValue={servicesProvidedInputText}
						onInputChange={(event, value, reason) => {
							if (event && event.type === 'blur') {
								setServicesProvidedInputText('');
							} else if (reason !== 'reset') {
								setServicesProvidedInputText(value);
							}
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						options={servicesProvided.sort((a, b) => -b.categoryId?.localeCompare(a.categoryId))}
						groupBy={option => option.categoryName}
						getOptionLabel={option => option.name}
						filterOptions={(options, { inputValue }) =>
							options.filter(
								item =>
									item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
									item.categoryName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
							)
						}
						fullWidth
						multiple
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
								<Chip label={option.riskLevel} sx={{ borderRadius: 0, mr: 1, width: '4rem' }} size="small" />
								{option.name}
							</li>
						)}
						disableCloseOnSelect
						renderInput={params => (
							<TextField hiddenLabel {...params} placeholder="Service name" error={selectedServicesProvidedError !== ''} />
						)}
					/>
                </Box>
            </Box>
            <Box sx={{display: 'flex', marginTop: '40px', justifyContent: 'flex-end'}}>
                <Box sx={{padding: '12px 32px', background: 'rgb(13, 23, 67)', borderRadius: '8px', alignItems: 'center', cursor: 'pointer'}} onClick={onSubmit}>
                    <Typography sx={{fontSize: '16px', fontWeight: 600, lineHeight: '24px', color: '#fff'}}>Save Changes</Typography>
                </Box>
            </Box>
        </Box>
    </Dialog>
}