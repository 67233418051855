import { IMaskInput } from 'react-imask';
import React, { useContext, useEffect, useState, memo, Children, useRef } from 'react';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { themeColors } from '../../theme';
import Container from '../../components/container';
import Header from '../../components/landing/header';
import ButtonCustom from '../../components/button';
import { ReactComponent as DownArrow } from '../../svg/Down-arrow.svg';
import { useUserState } from '../../context/UserContext';
import { sendVerificationCodeService, verify2faCodeService, verifyPassword } from '../../api';
import { SessionContext } from 'src/context/SessionContext';
import { Spinner } from '../../components/loaders';
import { Box, styled } from '@mui/system';

const IdCoreInput = styled(TextField)(({ theme }) => ({
	[`&.MuiTextField-root`]: {
		borderRadius: '8px',
		paddingBottom: 0,
	},
	'& .MuiInputBase-input': {
		padding: '12px',
	},
}));

export interface IPhoneCode {}

const LoginVerification: React.FC<IPhoneCode> = memo(() => {
	const refInputs = useRef<any>([]);
	const navigate = useNavigate();
	const { user } = useUserState();
	const [submit, setSubmit] = useState(false);
	const [message, setMessage] = useState('');

	const { refreshSession } = useContext(SessionContext);

	const {
		userDetails: { loginId },
		userProviders,
	} = user;

	const isNew = userProviders.length === 0;
	const handleSubmit = () => {
		setSubmit(true);
		const code = password;
		const inviteCode = new URLSearchParams(window.location.search).get('inviteCode');
		verifyPassword(loginId, code, isNew, inviteCode)
			.then(res => {
				refreshSession();
				const { role } = res.data;
				if (role === 'VENDOR') {
					navigate('/vendor/dashboard');
				}
			})
			.catch(err => {
				console.log(err);
				const errMsg = err?.response?.data;
				if (errMsg) {
					if (errMsg.error) {
						setMessage(errMsg.error_description);
					} else if (errMsg instanceof String && errMsg.indexOf('not authorized to access this application') >= 0) {
						setMessage('You are not authorized to access this application');
					} else {
						setMessage('Invalid Verification Code');
					}
				} else {
					setMessage('An error occurred. Please try again later.');
				}
				setSubmit(false);
			});
	};

	const [password, setPassword] = useState('');
	const handleChange = (val: any) => {
		setPassword(val);
	};

	const handleResendCode = () => {
		sendVerificationCodeService(loginId)
			.then(res => {
				if (res.status === 200) {
					navigate('/login-reset');
				} else {
					setMessage('Unable to resend code');
				}
			})
			.catch(err => {
				setMessage('Unable to resend code');
			});
	};

	useEffect(() => {
		refInputs.current[0]?.element?.focus();
	}, []);

	return (
		<Container align="center">
			<div className="landingWrap">
				<Grid container>
					<Grid item xs={12} textAlign={'justify'}>
						<Typography variant="h1" fontSize={30} fontWeight={400} pb={3}>
							{isNew ? 'Create your Password' : 'Enter your Password'}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<form
							onSubmit={e => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<IdCoreInput
								fullWidth
								onChange={(event: any) => {
									handleChange(event.target.value);
								}}
								value={password}
								name="password"
								placeholder="Enter Password"
								type="password"
								error={message.length > 0}
							/>
							{message && (
								<Typography variant="body2" color={themeColors.error} pt={1} textAlign={'justify'}>
									{message}
								</Typography>
							)}
							<ButtonCustom round="full" type="submit" disabled={password.length <= 0} sx={{ mt: 2 }}>
								{submit === false ? <span>{isNew ? 'Create Account' : 'Login'}</span> : <Spinner />}
							</ButtonCustom>
						</form>
					</Grid>
				</Grid>
				{!isNew && (
					<Grid item xs={12} textAlign={'center'} pt={2}>
						<Link component="button" onClick={handleResendCode}>
							Forgot your password?
						</Link>
					</Grid>
				)}
			</div>
		</Container>
	);
});

export default LoginVerification;
