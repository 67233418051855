import React from 'react';
import { IMaskInput } from 'react-imask';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	value: string;
}

const MaskedField = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref: any) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="(#00) 000-0000"
			definitions={{
				'#': /[1-9]/,
			}}
			inputRef={ref}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

const PhoneField = (props: TextFieldProps) => {
	return (
		<TextField
			{...props}
			InputProps={{
				inputComponent: MaskedField as any,
			}}
		/>
	);
};

export { PhoneField };
