import React, { useState, memo, useEffect, useContext } from 'react';
import { Typography, Link, Box, Alert, TextField, Grid, styled, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonCustom from '../../components/button';
import Container from '../../components/container';
import { sendVerificationCodeService, getUser, getSocialLogin, verifySocial } from '../../api';
import { useSetUserDetails, useSetUserProviders, useUserDetails } from '../../context/UserContext';
import { Spinner } from '../../components/loaders';
import { SessionContext } from 'src/context/SessionContext';
import { ReactComponent as GoogleLogo } from '../../svg/google-logo.svg';
import CircularProgress from '@mui/material/CircularProgress';

const IdCoreInput = styled(TextField)(({ theme }) => ({
	[`&.MuiTextField-root`]: {
		borderRadius: '8px',
		paddingBottom: 0,
	},
	'& .MuiInputBase-input': {
		padding: '12px',
	},
}));

// eslint-disable-next-line max-len
const phoneRegExp = /[0-9]{10}/;

// eslint-disable-next-line max-len
const emailRegExp = /^[a-z0-9.\-_+]+@[a-z0-9.\-_]+\.[a-z]{1,3}/;

export interface IPhoneCode {}

const Login: React.FC<IPhoneCode> = memo(() => {
	const setUserProviders = useSetUserProviders();
	const setUserdetails = useSetUserDetails();
	const { loginId } = useUserDetails();
	const [submit, setSubmit] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [socialLoginUrl, setSocialLoginUrl] = useState<string | null>(null);
	const navigate = useNavigate();
	const { refreshSession } = useContext(SessionContext);
	const [submitSocial, setSubmitSocial] = useState(false);

	const disable = () => {
		const phoneMatch = loginId.toString().match(phoneRegExp);
		const emailMatch = loginId.toString().match(emailRegExp);

		if (phoneMatch || emailMatch) {
			return false;
		}

		return true;
	};

	const handleSendCode = async () => {
		setSubmit(true);
	};

	const handleSubmit = () => {
		const search = window.location.search;
		getUser(loginId)
			.then(res => {
				if (res.data) {
					setUserProviders(res.data as any[]);
					navigate(`/login-verification${search}`);
				} else {
					navigate(`/login-verification${search}`);
				}
			})
			.catch(err => {
				if (err.response) {
					setError(err.response.data || 'Something went wrong.');
				} else {
					setError('Something went wrong.');
				}
				setSubmit(false);
			});
	};

	const handleChange = (val: any) => {
		if (val) setUserdetails({ loginId: val.toString().trim().toLowerCase() });
		else setUserdetails({ loginId: '' });
	};

	const getSocialLoginUrl = () => {
		const redirectUri = window.location.toString();
		getSocialLogin(redirectUri)
			.then(res => {
				if (res.status === 200) {
					console.log(res.data);
					setSocialLoginUrl(res.data);
				} else {
					setError('Unable to login with Google');
				}
			})
			.catch(err => {
				if (err.response) {
					setError(err.response.data || 'Something went wrong.');
				} else {
					setError('Something went wrong.');
				}
			});
	};

	useEffect(() => {
		if (window.location.hash) {
			let location = window.location.toString();
			location = location.replace('#', '?');
			let accessToken = new URL(location).searchParams.get('access_token');
			if (accessToken) {
				setSubmitSocial(true);
				verifySocial(accessToken)
					.then(res => {
						refreshSession();
						const { role } = res.data;
						if (role === 'VENDOR') {
							navigate('/vendor/dashboard');
						}
					})
					.catch(err => {
						if (err.response) {
							setError(err.response.data || 'Something went wrong.');
						} else {
							setError('Something went wrong.');
						}
						setSubmitSocial(false);
					});
			} else getSocialLoginUrl();
		} else {
			getSocialLoginUrl();
			sessionStorage.removeItem('coiChecks');
			sessionStorage.removeItem('doneCoiExtraction');
			sessionStorage.removeItem('background-check-info');
		}
	}, []);
	return (
		<Container>
			<div className={'landingWrap'}>
				<Box>
					<Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', gap: '20px', alignContent: 'center', alignItems: 'center'}}>
						<Typography variant="h1" fontSize={30} fontWeight={400} pb={3}>
							Lets Get Started
						</Typography>
						<Typography pb={4} color={'#6E6E6E'}>
							Please enter your email to create an account or log back in
						</Typography>
					</Grid>
					<Grid item xs={12} py={2}>
						<form
							onSubmit={e => {
								e.preventDefault();
								handleSendCode();
							}}
						>
							<IdCoreInput
								fullWidth
								onChange={(event: any) => {
									handleChange(event.target.value);
								}}
								value={loginId}
								name="email or phone"
								placeholder="Enter Email"
								sx={{ mb: 4 }}
							/>

							<ButtonCustom onClick={handleSubmit} type="submit" round="full" disabled={disable()}>
								{submit === false ? <span>Continue</span> : <Spinner />}
							</ButtonCustom>
						</form>
						{error && (
							<Box pt={2}>
								<Alert severity="error">{error}</Alert>
							</Box>
						)}
					</Grid>
					<Grid item xs={12} py={4}>
						<Divider>
							<Typography fontSize={12} color={'#9E9E9E'}>
								Or
							</Typography>
						</Divider>
					</Grid>

					<Box sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
						<Link
							href={socialLoginUrl ? socialLoginUrl : '/'}
							sx={{ textDecoration: 'auto' }}
							onClick={() => setSubmitSocial(true)}
						>
							<Box
								sx={{
									width: '312px',
									height: '48px',
									borderRadius: '8px',
									border: '1px solid #829AB1',
									bgcolor: '#FFFFFF',
									display: 'flex',
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<GoogleLogo></GoogleLogo>
								<Typography>
									{submitSocial === false ? (
										<span>Continue with Google</span>
									) : (
										<CircularProgress sx={{ color: 'black', height: '25px !important', width: '25px !important' }} />
									)}
								</Typography>
							</Box>
						</Link>
					</Box>
				</Box>
			</div>
		</Container>
	);
});

export default Login;
