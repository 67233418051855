import React from 'react';
import {Button, Link, Typography} from '@mui/material';

type Props = {
  name: string;
  disabled?: boolean;
  onClick: () => void;
};

export default function DownloadButton({ name, disabled = false, onClick }: Props) {
  return (
    <Link
      onClick={onClick}
      variant="body1"
      sx={{
        cursor: 'pointer',
        color: '#0B69A3'
      }}
      aria-label="Download File"
      /*startIcon={<DescriptionOutlinedIcon fontSize="small" style={{ width: 22 }} />}*/
    >
        {name}
    </Link>
  );
}
