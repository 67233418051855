import clsx from 'clsx';
import React, { useState, useEffect, DetailedHTMLProps } from 'react';
import classes from './container.module.scss';

interface ContainerProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	gutters?: boolean;
	response?: boolean;
	align?: 'center';
}

const Container: React.FC<ContainerProps> = ({ children, align, gutters, className = '', response = true, ...props }) => {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const setH = () => setHeight(window.innerHeight);
		setH();
		window.addEventListener('resize', setH);
		if (response) document.documentElement.classList.add('responsive');

		return () => {
			window.removeEventListener('resize', setH);
			if (response) document.documentElement.classList.remove('responsive');
		};
	}, [response]);

	return (
		<section
			style={{ minHeight: `${height}px` }}
			className={clsx(classes.container, className, {
				[classes.center]: align === 'center',
			})}
			{...props}
		>
			{children}
		</section>
	);
};

export default Container;
