import { Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import Container from 'src/components/container';
import Header from 'src/components/header';
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadRenter } from 'src/svg/upload-renter.svg';
import { CoverageButtons } from './coverage-buttons';
import { ReviewDocument } from './review-document';
import { RequirementsMatcher } from './requirements-matcher';
import { checkRenterCOI } from 'src/api';
import { useLocation } from 'react-router-dom';
type Props = {};

const RentalVerification = (props: Props) => {

    const locationObj = useLocation();
	const query = new URLSearchParams(locationObj.search);

    const onDrop = useCallback(async(files: File[]) => {
        setFileUploaded(true);
        const resp = await checkRenterCOI(files[0], query.get('carrier') ?? 'Lemonade');
        setExtractedDetails(resp);
        setFileUploaded(false);
        setFileExtracted(true);
    }, []);

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
          'application/pdf': [],
          'image/jpeg': [],
          'image/png': [],
        },
        maxFiles: 1,
        multiple: false,
      });

    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileExtracted, setFileExtracted] = useState(false);
    const [extractedDetails, setExtractedDetails] = useState({});

	return (
        <Container style={{background: '#F1F5F8'}}>
			<Header titleType="squeezed" />
            <Box mb={3}>
                {!fileExtracted &&
                    <Box p={5} flexDirection="column" display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h2">Upload your Renters Insurance</Typography>
                        <Box px={15} py={8} marginY={4} display="flex" border={'2px dashed #7B8794'} flexDirection="column" borderRadius={2} 
                            alignItems={'center'} {...getRootProps({ className: 'dropzone' })}
                        >
                            <input {...getInputProps()} />
                            <UploadRenter/>
                            <Typography variant='body1' style={{paddingTop: 10}}>Drag and drop or browse files</Typography>
                            <Typography variant='body2' style={{paddingTop: 10}}>Max File Size is 10MB</Typography>
                        </Box>
                        {!fileUploaded && !fileExtracted &&
                            <CoverageButtons/>
                        }
                        {fileUploaded && 
                            <ReviewDocument/>
                        }
                    </Box>
                }
                {fileExtracted && 
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            padding: '48px',
                            flexDirection:'column'
                        }}
                    >
                        <Box>
                            <div style={{
                                fontSize: '36px',
                                lineHeight: '44px',
                                color: '#0D0D0D',
                                fontFamily: 'Nunito'
                            }}>Renters Insurance for Arts at Turtle Creek Apartments - Apt #244</div>
                            <div style={{
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#2C2C2C',
                                fontFamily: 'Nunito',
                                paddingTop: '16px',
                            }}>714 TURTLE CREEK BLVD, AUSTIN, TX 78745</div>
                        </Box>
                        <RequirementsMatcher extractedRequirements={extractedDetails}/>
                    </Box>
                }
            </Box>
        </Container>
	);
};

export default RentalVerification;