import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, CircularProgress, Link } from '@mui/material';
import api, { checkInsureproCOI } from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import { useLocation, useNavigate } from 'react-router-dom';
import VendorUploadedFiles from 'src/components/Files/VendorUploadedFile';
import UploadFileDropZone from 'src/components/Files/UploadFileDropZone';
import { useProgress } from 'src/components/layouts/EnrollmentLayout';
import SaveAndContinue from 'src/components/save-and-continue';
import { useIntercom as useReactIntercom } from 'react-use-intercom';
import { saveInsuranceInfo } from 'src/utils/helpers';
import AddIcon from '@mui/icons-material/Add'

interface Props {
	tabChanger: (index: number) => void;
}
const COIFileUpload = ({ tabChanger }: Props) => {
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const { session, isNoRisk, isBgSkip, serviceProvided } = useContext(SessionContext);
	const [insStatus, setInsStaus] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const inputFile = useRef<HTMLInputElement | null>(null);
	const {
		details: { vendorId },
	} = session;

	const [coiFiles, setCoiFiles] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [coiFileObjs, setCoiFileObjs] = useState<File[]>([]);

	const { handleInvalid } = useProgress();

	const { getVisitorId } = useReactIntercom();

	useEffect(() => {
		setLoading(true);
		api
			.getCOIFile(vendorId)
			.then(res => {
				if (res && res !== 'coi/') {
					setCoiFiles(res);
					setInsStaus(res.length > 0 ? 'I_CURRENTLY_HAVE_INSURANCE' : '');
				}
			})
			.catch(err => {
				setError('failed to retrieve vendors COI File.');
			})
			.finally(() => {
				setLoading(false);
			});
	}, [vendorId]);

	inputFile.current?.addEventListener('change', handleFiles, false);
	function handleFiles(this: any) {
		const fileList = this.files;
		doUploadFiles([fileList['0']]);
	}

	const removeFile = async (filename: string) => {
		await api.deleteCOIFile(vendorId, filename);
		const newCoiFiles = [...coiFiles].filter(value => value !== filename);
		if (newCoiFiles.length <= 0) {
			setInsStaus('');
			handleInvalid();
		} else {
			setInsStaus('I_CURRENTLY_HAVE_INSURANCE');
		}
		setCoiFiles(newCoiFiles);
		const temp = coiFileObjs.filter(f => filename !== `coi/${f.name}`);
		setCoiFileObjs(temp);
	};

	const dropZoneOnDrop = useCallback((files: File[]) => {
		doUploadFiles(files);
	}, []);

	const doUploadFiles = (files: File[]) => {
		setCoiFileObjs([...coiFileObjs, ...files]);
		files.forEach(f => {
			api.getCOIUploadUrl(vendorId, f.name).then(url => {
				fetch(url, {
					method: 'PUT',
					body: f,
					headers: {
						'Content-Type': f.type,
					},
				}).then(value => {
					api.coiUploadNotification(vendorId);
				});
				const newArr = [...coiFiles];
				newArr.push({key: `coi/${f.name}`});
				setCoiFiles(newArr);
				setInsStaus('I_CURRENTLY_HAVE_INSURANCE');
			});
		});
	};

	const onContinue = () => {
		if (state?.next) {
			navigate(state?.next);
		} else {
			navigate('/vendor/enrollment/msa');
		}
	};

	const checkCOI = async () => {
		let res: any = await api.getVendor(vendorId);

		let backgroundCheckInfo: any = sessionStorage.getItem('background-check-info');
		if (backgroundCheckInfo) {
			backgroundCheckInfo = JSON.parse(backgroundCheckInfo);
		}
		const user = {
			firstName: backgroundCheckInfo?.subjectInfo?.firstName,
			lastName: backgroundCheckInfo?.subjectInfo?.lastName,
			email: res.data.bizInfo.email,
			phone: res.data.bizInfo.phone,
			profession: serviceProvided,
			business: {
				name: res.data.w9.businessName,
				state: backgroundCheckInfo?.subjectInfo?.state,
				city: backgroundCheckInfo?.subjectInfo?.city,
				zipCode: backgroundCheckInfo?.subjectInfo?.zipCode,
				street: backgroundCheckInfo?.subjectInfo?.streetAddress,
				fullTimeEmployees: res.data.bizInfo.numEmployees,
				insuranceStatus: insStatus,
			},
			intercomId: getVisitorId(),
		};
		// let res1 = await userEmbedded(user);
		// const insureproUser = res1.data.createdUser;
		// sessionStorage.setItem('insurepro-user', JSON.stringify(insureproUser));
		const coiChecks: any = [];
		sessionStorage.setItem('doneCoiExtraction', 'pending');
		(async () => {
			for (const file of coiFileObjs) {
				const r1 = await checkInsureproCOI(file);
				coiChecks.push(r1);
				sessionStorage.setItem('coiChecks', JSON.stringify(coiChecks));
				await saveInsuranceInfo(r1, vendorId);
			}
			sessionStorage.setItem('doneCoiExtraction', 'done');
		})().catch(() => {
			sessionStorage.setItem('doneCoiExtraction', 'fail');
		});

		navigate('/vendor/enrollment/msa');
	};

	if (loading)
		return (
			<Box p={8} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	else {
		return (
			<Box sx={{mt: 4}}>
				<Typography variant="h2" mb={3}>
					Certificate of Insurance Information
				</Typography>
				<Typography sx={{marginBottom: '16px', marginTop: '8px', fontSize: '16px', lineHeight: '24px', color: '#272937BF'}}>Please upload any insurance documents for your company. These papers help Property Management Companies see your current coverage, making it quicker to meet their requirements.</Typography>
				<Box mb={3}>
					
						{coiFiles && coiFiles.length > 0 ? (
							<VendorUploadedFiles loading={loading} files={coiFiles} onDelete={removeFile} />
						) : (
							<UploadFileDropZone onDrop={dropZoneOnDrop} title="Upload my Certificate of Insurance" />
						)}

					{coiFiles && coiFiles.length > 0 && (
						<Box ml={4} mt={1}>
							<Link
								href="#"
								underline="none"
								variant="caption"
								onClick={() => {
									inputFile.current?.click();
								}} sx={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', alignContent: 'center'}}
							>
								<AddIcon sx={{width: '20px', height: '20px'}}/> <Typography sx={{fontSize: '14px', lineHeight: '20px', color: '#243B53', borderBottom: '1px solid #102A4333'}}>Upload Another File</Typography>
							</Link>
							<input
								type="file"
								id="file"
								accept="image/png, image/jpeg, image/jpg, application/pdf"
								ref={inputFile}
								style={{ display: 'none' }}
							/>
						</Box>
					)}
					<SaveAndContinue disabled={!insStatus} onClick={() => checkCOI()} buttonTitle="Save And Continue" />
				</Box>
			</Box>
		);
	}
};

export default COIFileUpload;
