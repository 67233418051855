import { useState, useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as PDFIcon } from '../../../svg/file-pdf-solid.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { ReactComponent as DownArrow } from '../../../svg/Down-arrow.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import apiClient from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import SaveAndContinue from '../../save-and-continue';
import { useNavigate } from 'react-router-dom';
import { useIntercom as useReactIntercom } from 'react-use-intercom';

export const MSA = () => {
	const navigate = useNavigate();

	const { getVisitorId } = useReactIntercom();

	const { session, isNoRisk, isBgSkip, enrollmentStatus } = useContext(SessionContext);
	const [isExternalMSA, setIsExternalMSA] = useState(false);
	const {
		details: { vendorId },
	} = session;
	const [formValues, setFormValues] = useState({
		masterServiceTerm: false,
		termAndCondition: false,
	});

	//  Form valication
	const validationSchema = Yup.object().shape({
		masterServiceTerm: Yup.bool().oneOf([true], 'Checkbox selection is required'),
		termAndCondition: Yup.bool().oneOf([true], 'Checkbox selection is required'),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const handleChange = (event: any, attributeName?: string, MUIvalue?: any) => {
		event.persist();

		setFormValues((values: any) => ({
			...values,
			[event.target.name || attributeName]: MUIvalue !== undefined ? MUIvalue : event.target.value,
		}));
	};
	const goNext = () => {
		apiClient
			.acceptMsa(vendorId)
			.then(() => {
				if(enrollmentStatus) {
					navigate('/vendor/dashboard');	
				} else {
					navigate('/vendor/enrollment/summary');
				}
			})
			.catch(err => {
				console.error(err);
			});
	};
	useEffect(() => {
		console.log(enrollmentStatus);
		apiClient
			.getVendor(vendorId)
			.then((res: any) => {
				const msa = !!res?.data?.msa;
				setIsExternalMSA(res.data.externalMsa);
				setFormValues({
					masterServiceTerm: msa,
					termAndCondition: false,
				});
				// userEmbedded(getVisitorId(), res.data.w9.businessName, res.data.bizInfo.email, res.data).then((res: any) => {});
			})
			.catch(err => {
				console.error(err);
			});
	}, []);

	return (
		<Box sx={{mt: 4}}>
			<div className="afterArrowImg">
				<Typography variant="h2">Master Service Agreement</Typography>
			</div>
			<div>
				<a
					href={'https://www.idcore.com/terms'}
					target="_blank"
					className="hasIconLeft"
					rel="noreferrer"
				>
					{/* <PdfIcon className="leftIcon" /> */}
					<Typography mb={4} className="imBlueDaBaDee" variant="subtitle2">
						<PDFIcon />
						&nbsp;&nbsp;
						<u>Review ID Core Master Service Agreement</u>{' '}
					</Typography>
				</a>
				<div className="inputWrap checkboxLabelMuted">
					<FormGroup aria-label="position" row>
						<FormControlLabel
							value={formValues.masterServiceTerm}
							control={
								<Checkbox
									{...register('masterServiceTerm')}
									checked={formValues.masterServiceTerm}
									onChange={(e, v) => handleChange(e, 'masterServiceTerm', v)}
									sx={{
										color: errors.masterServiceTerm ? 'red' : '#27293759',
									}}
								/>
							}
							label="I acknowledge receipt of and agree to the above terms. I understand that violating this agreement will result in termination of approval to be on IDcore's Platform."
							labelPlacement="end"
						/>
					</FormGroup>
				</div>
			</div>
			<SaveAndContinue onClick={handleSubmit(goNext)} buttonTitle="Save And Continue" />
		</Box>
	);
};
