import { Typography, Box, CircularProgress, IconButton, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/DeleteOutline';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useContext } from 'react';
import { SessionContext } from '../../context/SessionContext';
import { openInNewTab } from '../../utils/helpers';
import apiClient from '../../api';

interface IVendorUploadFileProps {
	files: any[];
	loading: boolean;
	onDelete: (filename: string) => void;
}

const VendorUploadedFile = ({ files, loading, onDelete }: IVendorUploadFileProps) => {
	const { session } = useContext(SessionContext);
	const {
		userId,
		details: { vendorId },
	} = session;
	const downloadFile = (filename: string) => {
		apiClient
			.getFileUrl(vendorId, filename)
			.then(res => {
				console.log('res ', res);
				const { url } = res;
				openInNewTab(url);
			})
			.catch(err => {
				console.error(err);
			});
	};

	return (
		<>
			
			{files.map((value, key) => {
				return (
					<>
						<Box key={key} p={2} component="div">
							<ListItem
								secondaryAction={
									<IconButton
										onClick={() => {
											onDelete(value.key);
										}}
										edge="end"
										aria-label="delete"
										sx={{borderRadius: '32px', background: '#FFBDBD', ":hover": {background: '#E12D3980'}}}
									>
										<CloseIcon sx={{color: '#CF1124', width: '24px', height: '24px'}}/>
									</IconButton>
								}
							>
								<ListItemIcon>{loading ? <CircularProgress size={24} color="primary" /> : <FileIcon sx={{width: '24px', height: '24px', color: '#486581'}}/>} </ListItemIcon>
								<ListItemText
									onClick={() => {
										downloadFile(value.key);
									}}
									primary={value.key.split('/')[1]}
									sx={{
										cursor: 'pointer',
										color: '#868585',
									}}
								/>
							</ListItem>
						</Box>
						{(key < files.length - 1) && <Divider sx={{marginX: '24px'}} />}
					</>
				);
			})}
		</>
	);
};

export default VendorUploadedFile;
