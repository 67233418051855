import React, { DetailedHTMLProps, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Header from '../../components/header';
import Container from '../../components/container';
import { SessionContext } from '../../context/SessionContext';
import apiClient from '../../api/index';
import LoadingScreen from '../loading';
import { useNavigate } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import { NewDashboard } from './new-dashboard';
interface ContainerProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	blot?: 'top' | 'bottom' | true;
	gutters?: boolean;
	response?: boolean;
}

const VendorDashboardPage: React.FC<ContainerProps> = ({
	children,
	blot,
	gutters,
	className = '',
	response = true,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [vendorDetails, setVendorDetails] = useState<any>(null);
	const [coiFiles, setCoiFiles] = useState([] as any[]);
	const { session, enrollmentStatus, setEnrollmentStatus, setRiskLevel } = useContext(SessionContext);
	const [coiChecks, setCoiChecks] = useState<boolean>(sessionStorage.getItem('coiChecks') != null);
	const [insurances, setInsurances] = useState(null as any);
	const navigate = useNavigate();

	async function fetchData() {
		try {
			const vendor = await apiClient.getVendor(session.details?.vendorId);
			setVendorDetails(vendor.data);

			const coiResp = await apiClient.getCOIFile(session.details?.vendorId);
			setCoiFiles(coiResp);
			const refId = vendor.data.referenceId;
			if (refId && refId.trim().length !== 0) {
				FullStory.identify(session.details?.vendorId)
				FullStory.setUserVars({ referenceId: refId });
			}

			let apiCalls = [];
			if (vendor.data) {
				apiCalls.push(apiClient.getVendorServices({ ids: vendor.data?.bizInfo?.riskLevels }));
			}
			apiCalls.push(
				apiClient.getGeneralLiability(session.details?.vendorId),
				apiClient.getAutoLiability(session.details?.vendorId),
				apiClient.getExcessLiability(session.details?.vendorId),
				apiClient.getWorkersComp(session.details?.vendorId)
			);
			if (session.details?.vendorId) {
				Promise.all(apiCalls).then(([r1, r2, r3, r4, r5]: any) => {
					let isRisk = true;
					if (r1.data && r1.data.vendorServices && r1.data.vendorServices.length && setRiskLevel) {
						const levels = r1.data.vendorServices.map((s: any) => s.riskLevel);
						const risklevel = levels.indexOf('HIGH') >= 0 ? 'HIGH' : levels.indexOf('LOW') >= 0 ? 'LOW' : 'NONE';
						isRisk = risklevel !== 'NONE';
						setRiskLevel(risklevel);
					}
					const insurances: any = {};
					if (r2 && Object.keys(r2.data).length > 0) {
						insurances.generalLiability = r2.data;
					}
					if (r3 && Object.keys(r3.data).length > 0) {
						insurances.autoLiability = r3.data;
					}
					if (r4 && Object.keys(r4.data).length > 0) {
						insurances.excessLiability = r4.data;
					}
					if (r5 && Object.keys(r5.data).length > 0) {
						insurances.workerPolicy = r5.data;
					}
					setInsurances(insurances);
					if (Object.keys(vendor.data).length > 0) {
						const overallComplianceStatus = vendor.data.overallComplianceStatus;
						if (vendor.data.payment?.status === 'succeeded' || (!isRisk && overallComplianceStatus !== 'PENDING')) {
							if (overallComplianceStatus && overallComplianceStatus !== 'PENDING') {
								setEnrollmentStatus(overallComplianceStatus);
							} else {
								setEnrollmentStatus('PENDING');
							}
							if(!vendor.data.msa) {
								navigate('/vendor/enrollment/msa');
							}
						} else if (vendor.data.payment?.status === 'processing') {
							setEnrollmentStatus('PENDING');
						} else if (!vendor.data?.bizInfo || !vendor.data?.w9) {
							navigate('/vendor/enrollment/business-info');
						} else {
							setEnrollmentStatus('IN-PROGRESS');
						}
					}
				});
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, [session]);
	if (loading) return <LoadingScreen />;
	return (
		<Container className="vendor-dashboard">
			<Header titleType="squeezed" />
			{enrollmentStatus && (
				<Box pt={1} pb={1}>
					<NewDashboard reloadVendorDetails={fetchData} enrollmentStatus={enrollmentStatus} coiChecks={coiChecks} vendorDetails={vendorDetails} coiFiles={coiFiles} insurances={insurances}/>
				</Box>
			)}
		</Container>
	);
};

export default VendorDashboardPage;
