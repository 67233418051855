import { useState, useContext, useEffect, useRef, ChangeEvent } from 'react';
import {
	Typography,
	Box,
	Paper,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	Alert,
} from '@mui/material';
import api, { checkInsureproCOI } from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import SaveAndContinue from 'src/components/save-and-continue';
import { openInNewTab, saveInsuranceInfo } from 'src/utils/helpers';
import { ReactComponent as FileIcon } from 'src/svg/file.svg';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

interface Props {
	tabChanger: (index: number) => void;
	enrollmentStatus: any;
}
const COIUploadDashBoard = ({ tabChanger, enrollmentStatus }: Props) => {
	const { session, refreshEnrollmentStatus } = useContext(SessionContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingText, setLoadingText] = useState<string>('');
	const {
		details: { vendorId },
	} = session;
	const [coiFiles, setCoiFiles] = useState<string[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [coiFileObjs, setCoiFileObjs] = useState<File[]>([]);
	const inputFile = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		setLoading(true);
		api
			.getCOIFile(vendorId)
			.then(res => {
				if (res && res !== 'coi/') {
					setCoiFiles(res);
					if (!sessionStorage.getItem('coiChecks')) {
						setLoadingText('COI Loading....');
						const coiChecks: any = [];
						sessionStorage.setItem('doneCoiExtraction', 'pending');
						(async () => {
							if (res.length > 0) {
								const file = await getFileFromUrl(res[0]);
								const r1 = await checkInsureproCOI(file);
								coiChecks.push(r1);
								sessionStorage.setItem('coiChecks', JSON.stringify(coiChecks));
							}
							sessionStorage.setItem('doneCoiExtraction', 'done');
							tabChanger(5);
						})().finally(() => {
							setLoading(false);
						});
					} else {
						setLoading(false);
					}
				} else {
					setLoading(false);
				}
			})
			.catch(err => {
				setError('failed to retrieve vendors COI File.');
				sessionStorage.setItem('doneCoiExtraction', 'fail');
				setLoading(false);
			});
	}, [vendorId]);

	const removeFile = async (filename: string) => {
		console.log('removeFile', filename);
		await api.deleteCOIFile(vendorId, filename);
		const newCoiFiles = [...coiFiles].filter(value => value !== filename);
		setCoiFiles(newCoiFiles);
		const temp = coiFileObjs.filter(f => filename !== `coi/${f.name}`);
		setCoiFileObjs(temp);
	};

	const doUploadFiles = async (files: File[]) => {
		setCoiFileObjs([...coiFileObjs, ...files]);
		await (async () => {
			const coiChecks: any = [];
			sessionStorage.setItem('doneCoiExtraction', 'pending');
			for (const f of files) {
				api.getCOIUploadUrl(vendorId, f.name).then(url => {
					fetch(url, {
						method: 'PUT',
						body: f,
						headers: {
							'Content-Type': f.type,
						},
					}).then(value => {
						api.coiUploadNotification(vendorId);
					});
					const newArr = [...coiFiles];
					newArr.push(`coi/${f.name}`);
					setCoiFiles(newArr);
				});
				const r1 = await checkInsureproCOI(f);
				coiChecks.push(r1);
				sessionStorage.setItem('coiChecks', JSON.stringify(coiChecks));
				await saveInsuranceInfo(r1, vendorId);
			}
			sessionStorage.setItem('doneCoiExtraction', 'done');
			await refreshEnrollmentStatus();
		})().catch(() => {
			sessionStorage.setItem('doneCoiExtraction', 'fail');
		});
	};

	const handleClose = async (option: string, fileName: string) => {
		switch (option) {
			case 'View Doc': {
				await downloadFile(fileName);
				break;
			}
			case 'Remove': {
				await removeFile(fileName);
				break;
			}
		}
	};

	async function handleFiles(event: ChangeEvent<HTMLInputElement>) {
		const fileList = event.currentTarget.files;
		if (fileList) {
			setLoadingText('Uploading File....');
			setLoading(true);
			await doUploadFiles([fileList['0']]).finally(() => setLoading(false));
			if (event.currentTarget) event.currentTarget.files = null;
		}
	}

	async function getFileFromUrl(filename: string) {
		const urlResponse = await api.getFileUrl(vendorId, filename);
		const { url } = urlResponse;

		const response = await fetch(url);
		const data = await response.blob();
		return new File([data], 'coi', {
			type: data.type,
		});
	}

	const downloadFile = (filename: string) => {
		api
			.getFileUrl(vendorId, filename)
			.then(res => {
				console.log('res ', res);
				const { url } = res;
				openInNewTab(url);
			})
			.catch(err => {
				console.error(err);
			});
	};

	if (loading)
		return (
			<Box p={8} display="flex" justifyContent="center">
				<Alert severity="info">{loadingText}</Alert>
				<CircularProgress />
			</Box>
		);
	else {
		return (
			<div>
				<Typography variant="h2">Certificate of Insurance Information</Typography>
				<Box mb={3} pt={2}>
					<Paper elevation={0}>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
								background: 'white',
								marginRight: '-16px',
								padding: '48px',
								borderRadius: '16px',
							}}
						>
							<>
								<Typography variant="h4">Upload your document(s)</Typography>
								{enrollmentStatus !== 'COMPLIANT' && <Typography mb={3} variant="body1">
									We found issues in your policy that makes you underinsured in order to work for ResProp Management, Please
									review and resolve these issues.
								</Typography>}
								<Box sx={{ width: '100%' }}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<Typography variant="h6" color="primary" pt={2}>
											My Documents
										</Typography>
										<Button
											onClick={() => inputFile.current?.click()}
											sx={{
												width: '20%',
												height: '3em',
												background: '#F8FDFD',
												border: '1px dashed #288186',
												color: '#288186',
												'&:hover': {
													boxShadow: '0 0 0 0.1rem #288186',
													background: '#F8FDFD',
													border: 'none',
												},
											}}
										>
											Upload Docs
										</Button>
									</Box>
									<Box>
										<input
											type="file"
											id="file"
											accept="image/png, image/jpeg, image/jpg, application/pdf"
											ref={inputFile}
											onChange={e => handleFiles(e)}
											style={{ display: 'none' }}
										/>
									</Box>
									<Box>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												pt: 2,
											}}
										>
											<Typography variant="body2" color="primary">
												Name
											</Typography>
											<Typography variant="body2" color="primary" mr={0.5}>
												Options
											</Typography>
										</Box>
										<List>
											{coiFiles.map((fileName, index) => {
												return (
													<ListItem
														key={index}
														secondaryAction={
															<>
																<Menu>
																	<MenuButton
																		style={{ backgroundColor: '#E4E7EB', border: '1px solid #CBD2D9', borderRadius: '8px' }}
																	>
																		<MoreHorizIcon />
																	</MenuButton>
																	<MenuList style={{ padding: 0 }}>
																		<MenuItem
																			onSelect={() => handleClose('View Doc', fileName)}
																			style={{ paddingBottom: '10px', paddingLeft: '5px' }}
																		>
																			<VisibilityIcon
																				style={{ width: '22px', height: '15px', marginRight: '5px', marginTop: '5px' }}
																			/>
																			View Doc
																		</MenuItem>
																		<MenuItem
																			onSelect={() => handleClose('Remove', fileName)}
																			style={{
																				paddingBottom: '10px',
																				paddingLeft: '10px',
																				color: '#E12D39',
																			}}
																		>
																			<DeleteOutlinedIcon
																				style={{
																					width: '14px',
																					height: '18px',
																					marginRight: '5px',
																					marginTop: '5px',
																					color: '#E12D39',
																				}}
																			/>
																			Remove
																		</MenuItem>
																	</MenuList>
																</Menu>
															</>
														}
														sx={{ pl: 0 }}
													>
														<ListItemIcon sx={{ minWidth: 30 }}>
															{loading ? <CircularProgress size={24} color="primary" /> : <FileIcon />}{' '}
														</ListItemIcon>
														<ListItemText
															onClick={() => {
																downloadFile(fileName);
															}}
															primary={fileName.split('/')[1]}
															sx={{
																cursor: 'pointer',
																color: '#868585',
															}}
														/>
													</ListItem>
												);
											})}
										</List>
									</Box>
								</Box>
							</>
							<SaveAndContinue onClick={() => tabChanger(5)} buttonTitle="Continue" />
						</Box>
					</Paper>
				</Box>
			</div>
		);
	}
};

export default COIUploadDashBoard;
