import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentStatus from './status';
import StripePayment from './stripe-payment';

export const Payment = () => {
	const navigate = useNavigate();
  const goBack = () => { navigate('/vendor/dashboard') }
	const [paymentStatus, setPaymentStatus] = useState<string>();
	useEffect(() => {
		const s = new URLSearchParams(window.location.search).get('success')
		setPaymentStatus(s||'');

		setTimeout(() => {
			if(s) {
				goBack();
			}
		}, 3000);
	}, []);

	return (
		<div>
			{paymentStatus && <PaymentStatus paymentStatus={paymentStatus||''} go_back={goBack} company={'Rex'} />}
			{!paymentStatus && <StripePayment goback={goBack} company={'Rex'} />}
		</div>
	);
};
