import { useCallback, useState } from 'react';
import { Context, createContext, useContextSelector } from 'use-context-selector';

export interface IUserDetails {
	loginId: string;
	email: string;
	userId: string;
	role: string;
}

export interface IUser {
	userDetails: IUserDetails;
	userProviders: any[];
	otp: string[];
	errors: any;
}

interface IAnyObject {
	[key: string]: any;
}

interface IUserStore {
	user: IUser;
	setOtp: (payload: string, idx: number) => void;
	setUserState: (payload: IAnyObject) => void;
	setUserdetails: (payload: IAnyObject) => void;
	setUserProviders: (payload: IAnyObject[]) => void;
}

const initialValue: IUser = {
	userDetails: {
		loginId: '',
		email: '',
		userId: '',
		role: '',
	},
	userProviders: [],
	otp: ['', '', '', '', '', ''],
	errors: '',
};

const initialState: IUserStore = {
	user: initialValue,
	setOtp: (payload: string, idx: number) => {},
	setUserState: (payload: IAnyObject) => {},
	setUserdetails: (payload: IAnyObject) => {},
	setUserProviders: (payload: IAnyObject[]) => {},
};

// user state and all functionalities
const useUser = (): IUserStore => {
	const [user, setUser] = useState(initialValue);

	// make call to GET /auth/session
	const setUserdetails = useCallback((payload: IAnyObject) => {
		setUser(prevState => {
			return {
				...prevState,
				userDetails: {
					...prevState.userDetails,
					...payload,
				},
			};
		});
	}, []);

	const setUserProviders = useCallback((payload: IAnyObject[]) => {
		setUser(prevState => {
			return {
				...prevState,
				userProviders: payload,
			};
		});
	}, []);

	const setOtp = useCallback((payload: string, index: number) => {
		setUser(prevState => {
			const updatedOtp = [...prevState.otp];
			updatedOtp[index] = payload;
			return {
				...prevState,
				otp: updatedOtp,
			};
		});
	}, []);

	const setUserState = useCallback((payload: IAnyObject) => {
		setUser(prevState => {
			return {
				...prevState,
				...payload,
			};
		});
	}, []);

	return {
		user,
		setOtp,
		setUserState,
		setUserdetails,
		setUserProviders,
	};
};

// create user context
const UserContext: Context<IUserStore> = createContext(initialState);

export const UserContextProvider = ({ children }: any) => {
	const value = useUser();
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Selectors
export const useUserDetails = () => useContextSelector(UserContext, state => state.user.userDetails);
export const useUserState = () => useContextSelector(UserContext, state => state);
export const useSetUserDetails = () => useContextSelector(UserContext, state => state.setUserdetails);
export const useSetUserProviders = () => useContextSelector(UserContext, state => state.setUserProviders);
