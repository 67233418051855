import axios from 'axios';

const apiClient = axios.create({
	baseURL: '/api',
	withCredentials: true,
});

const apiClient2 = axios.create({
	baseURL: 'https://api.insurepro.com/api',
	withCredentials: true,
});

export const userEmbedded = async (user: any): Promise<any> => {
	const res = await apiClient2.post('/user/embedded', user);
	return res.data;
};

export const checkInsureproCOI = async (file: any): Promise<any> => {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('filename', Date.now() + file.name);
	const resp = await fetch('https://coi-extractor-ml.idcore.com/coiExtract/v1', {
		method: 'post',
		body: formData,
	});
	const respData = await resp.json();
	return respData;
};

export const checkRenterCOI = async (file: any, carrier: string): Promise<any> => {
	const formData = new FormData();
	formData.append('file', file, file.name);
	formData.append('carrier_type', carrier);
	const resp = await fetch('https://ml.dev.insurepro.com/rentersExtract/v2', {
		method: 'post',
		body: formData,
	});
	const respData = await resp.json();

	return respData;
};

export const createNewConversationFromAdmin = async (payload: any): Promise<any> => {
	const res = await apiClient2.post('/user/embedded-conversation', payload);
	return res.data;
};

export const getApiStatus = async (): Promise<any> => {
	const res = await apiClient.get('/ready');
	return res.data;
};

export const sendVerificationCodeService = async (loginId: string) => {
	const res = await apiClient.post('/auth/sendCode', { loginId });
	return res;
};

export const verify2faCodeService = async (loginId: string, code: string, inviteCode?: string | null) => {
	const res = await apiClient.post('/auth/verify2fa', { loginId, code, inviteCode });
	return res;
};

export const getSession = async () => {
	try {
		const res = await apiClient.get('/auth/session');
		if (res.status === 200) {
			return res.data;
		} else {
			return false;
		}
	} catch (err) {
		return false;
	}
};

export const getUser = async (email: string) => {
	let params: {
		email?: string;
	} = {};
	if (email) {
		params.email = email;
	}
	const res = await apiClient.get('/auth/getUser', { params });
	return res;
};

export const verifyPassword = async (loginId: string, password: string, isNew: boolean, inviteCode?: string | null) => {
	const res = await apiClient.post('/auth/verify-password', { loginId, password, inviteCode, isNew });
	return res;
};

export const resetPassword = async (loginId: string, password: string, userProviders: any[], inviteCode?: string | null) => {
	const res = await apiClient.post('/auth/reset-password', { loginId, password, userProviders, inviteCode });
	return res;
};

export const getSocialLogin = async (redirectUri: string) => {
	let params: {
		redirectUri?: string;
	} = {};
	if (redirectUri) {
		params.redirectUri = redirectUri;
	}
	const res = await apiClient.get('/auth/get-social', { params });
	return res;
};

export const verifySocial = async (accessToken: string, inviteCode?: string | null) => {
	const res = await apiClient.post('/auth/verify-social', { token: accessToken, inviteCode });
	return res;
};

export const getConfiguration = async () => {
	const res = await apiClient.get('/configuration');
	return res.data;
};

const getExemptionCode = () => {
	return apiClient.get('/exemption-codes');
};

const getFactaExemptionCodes = () => {
	return apiClient.get('/facta-exemption-codes');
};

/**
 * Get a list of services
 *
 * Optionally parse and object with query or categoryIDs to filter results
 */
const getServices = (payload?: { q?: string; categoryIds?: string[]; serviceIds?: string[] }) => {
	let params: {
		q?: string;
		category?: string;
		service?: string;
	} = {};
	if (payload?.q) {
		params.q = payload.q;
	}
	if (payload?.categoryIds?.length) {
		params.category = payload?.categoryIds.join(',');
	}
	if (payload?.serviceIds?.length) {
		params.service = payload?.serviceIds.join(',');
	}
	return apiClient.get('/services', { params });
};

interface ISearchRiskLevelsFilters {
	pmcId: string;
	q?: string;
	categories?: string[];
}

const getServiceRiskLevels = async (filters: ISearchRiskLevelsFilters) => {
	const { pmcId, q, categories } = filters;
	let queryParams: any = { pmcId };
	if (q) {
		queryParams = { ...queryParams, q };
	}
	if (categories) {
		queryParams = {
			...queryParams,
			category: categories.join(','),
		};
	}

	const res = await apiClient.get('/services/risk-levels', { params: queryParams });
	return res.data;
};

const getServiceAreas = (payload?: { q?: string }) => {
	let params: {
		q?: string;
	} = {};
	if (payload?.q) {
		params.q = payload.q;
	}
	return apiClient.get('/services/areas', { params });
};

const getServicesProvided = () => {
	return apiClient.get('/services-provided?limit=600');
};

export const getVendorServices = (payload: any) => {
	return apiClient.post('/services-provided/services', payload);
};

const getTaxClassifications = () => {
	return apiClient.get('/tax-classifications');
};

const submitW9 = (userId: string, payload: any) => {
	const body = {
		...payload,
		address: `${payload.address}, ${payload.city}, ${payload.state}, ${payload.zipcode}`,
	};

	return apiClient.post(`/vendors/${userId}/w9`, body);
};

const getW9 = (userId: string) => {
	return apiClient
		.get(`/vendors/${userId}/w9`)
		.then(res => {
			if (Object.keys(res.data).length === 0) {
				// This means the user has never filled out this form before.
				return {};
			}
			const [address, city, state, zipcode] = res.data.address.split(',').map((p: string) => {
				return p.trim();
			});
			let w9Info = {
				...res.data,
				repeatTin: res.data.tin,
				address,
				city,
				state,
				zipcode,
				certification: true, // If they ever submitted this form this will be true
				checkedSignature: true, // if they ever submitted this form this will be true
			};
			return w9Info;
		})
		.catch(err => {
			console.error(err);
			return {};
		});
};

const submitBizInfo = (userId: string, payload: any) => {
	return apiClient.post(`/vendors/${userId}/business-info`, payload);
};

const getBizInfo = async (vendorId: string) => {
	const res = await apiClient.get(`/vendors/${vendorId}/business-info`);
	return res.data;
};

const submitGeneralLiability = (userId: string, payload: any) => {
	const payloadMapping = {
		brokerName: payload.brokerName,
		brokerContact: payload.brokerContact ? payload.brokerContact : payload.brokerName,
		brokerEmail: payload.brokerEmail,
		brokerPhone: payload.brokerPhone,
		policyNo: payload.policyNo,
		policyId: payload.policyId || null,
		expiration: payload.expiration || null,
		startDate: payload.startDate || null,
		aggregate: payload.aggregate || null,
		eachOccurence: payload.eachOccurence || null,
		personalAndAdvInjury: payload.personalAndAdvInjury || null,
		productsOrCompOrOpAgg: payload.productsOrCompOrOpAgg || null,
		medicalExpense: payload.medicalExpense || null,
		damageToPremises: payload.damageToPremises || null,
		additionalInsured: payload.additionalInsured || null,
		waiverOfSubrogation: payload.waiverOfSubrogation || null,
		certificateHolder: payload.certificateHolder,
		description: payload.description,
	};
	return apiClient.post(`/vendors/${userId}/insurance-policies/general-liability`, payloadMapping);
};

const getGeneralLiability = (userId: string) => {
	return apiClient.get(`/vendors/${userId}/insurance-policies/general-liability`);
};

const deleteGeneralLiability = (userId: string, policyId: string) => {
	return apiClient.delete(`/vendors/${userId}/insurance-policies/general-liability/${policyId}`);
};

const submitAutoLiability = (userId: string, payload: any) => {
	const payloadMapping = {
		brokerName: payload.brokerName,
		brokerContact: payload.brokerContact ? payload.brokerContact : payload.brokerName,
		brokerEmail: payload.brokerEmail,
		brokerPhone: payload.brokerPhone,
		policyNo: payload.policyNo,
		policyId: payload.policyId || null,
		expiration: payload.expiration || null,
		startDate: payload.startDate || null,
		combinedSingleLimit: payload.combinedSingleLimit || null,
		bodilyInjuryPerAccident: payload.bodilyInjuryPerAccident || null,
		bodilyInjuryperPerson: payload.bodilyInjuryperPerson || null,
		proprtyDamagePerAccident: payload.proprtyDamagePerAccident || null,
		additionalInsured: payload.additionalInsured || null,
		waiverOfSubrogation: payload.waiverOfSubrogation || null,
		certificateHolder: payload.certificateHolder,
		description: payload.description,
	};
	return apiClient.post(`/vendors/${userId}/insurance-policies/auto-liability`, payloadMapping);
};

const acceptMsa = (userId: string) => {
	return apiClient.post(`/vendors/${userId}/accept-msa`);
};

const acceptRespropMsa = (userId: string) => {
	return apiClient.post(`/vendors/${userId}/accept-msa`, {pmc : 'resprop'});
};

const getAutoLiability = (userId: string) => {
	return apiClient.get(`/vendors/${userId}/insurance-policies/auto-liability`);
};

const deleteAutoLiability = (userId: string, policyId: string) => {
	return apiClient.delete(`/vendors/${userId}/insurance-policies/auto-liability/${policyId}`);
};

const submitExcessLiability = (userId: string, payload: any) => {
	const payloadMapping = {
		brokerName: payload.brokerName,
		brokerContact: payload.brokerContact ? payload.brokerContact : payload.brokerName,
		brokerEmail: payload.brokerEmail,
		brokerPhone: payload.brokerPhone,
		policyNo: payload.policyNo,
		policyId: payload.policyId || null,
		expiration: payload.expiration || null,
		startDate: payload.startDate || null,
		eachOccurence: payload.eachOccurence || null,
		aggregate: payload.aggregate || null,
		excessLiab: payload.excessLiab || null,
		additionalInsured: payload.additionalInsured || null,
		waiverOfSubrogation: payload.waiverOfSubrogation || null,
		certificateHolder: payload.certificateHolder,
		description: payload.description,
	};
	return apiClient.post(`/vendors/${userId}/insurance-policies/excess-liability`, payloadMapping);
};

const getExcessLiability = (userId: string) => {
	return apiClient.get(`/vendors/${userId}/insurance-policies/excess-liability`);
};

const deleteExcessLiability = (userId: string, policyId: string) => {
	return apiClient.delete(`/vendors/${userId}/insurance-policies/excess-liability/${policyId}`);
};

const submitWorkersComp = (userId: string, payload: any) => {
	const payloadMapping = {
		brokerName: payload.brokerName,
		brokerContact: payload.brokerContact ? payload.brokerContact : payload.brokerName,
		brokerEmail: payload.brokerEmail,
		brokerPhone: payload.brokerPhone,
		policyNo: payload.policyNo,
		policyId: payload.policyId || null,
		expiration: payload.expiration || null,
		startDate: payload.startDate || null,
		eachAccident: payload.eachAccident || null,
		diseaseEachEmployee: payload.diseaseEachEmployee || null,
		diseasePolicyLimit: payload.diseasePolicyLimit || null,
		additionalInsured: payload.additionalInsured || null,
		waiverOfSubrogation: payload.waiverOfSubrogation || null,
		certificateHolder: payload.certificateHolder,
		description: payload.description,
	};
	return apiClient.post(`/vendors/${userId}/insurance-policies/workers-comp`, payloadMapping);
};

const getWorkersComp = (userId: string) => {
	return apiClient.get(`/vendors/${userId}/insurance-policies/workers-comp`);
};

const deleteWorkersComp = (userId: string, policyId: string) => {
	return apiClient.delete(`/vendors/${userId}/insurance-policies/workers-comp/${policyId}`);
};

const getBackgroundCheckInfo = async (userId: string) => {
	const res = await apiClient.get('/background-checks', { params: { userId } });
	return res.data[0];
};

const submitBackgroundCheckInfo = async (payload: any) => {
	const { userId, disclosureAccepted, subjectInfo, id } = payload;
	let res;
	if (id) {
		// Update the existing BG Check
		res = await apiClient.put(`/background-checks/${id}`, { userId, disclosureAccepted, subjectInfo });
	} else {
		// Create a new Background Check Record
		res = await apiClient.post('/background-checks', { userId, disclosureAccepted, subjectInfo });
	}

	return res?.data;
};

export const getVendor = (vendorId: string) => {
	return apiClient.get(`/vendors/${vendorId}`);
};

const getInsurancePolicies = (userId: string) => {
	return apiClient.get(`/vendors/${userId}/insurance-policies`);
};

const getW9UploadUrl = async (vendorId: string, filename: string) => {
	const res = await apiClient.post(`/vendors/${vendorId}/w9-upload-url`, { filename });
	return res.data.url;
};

const getW9File = async (vendorId: string) => {
	const res = await apiClient.get(`/vendors/${vendorId}/uploaded-docs`);
	const { w9 } = res.data;
	return w9 || undefined;
};

const deleteW9File = async (vendorId: string, filename: string) => {
	return apiClient.post(`/vendors/${vendorId}/delete-w9-file`, { filename });
};

const getCOIUploadUrl = async (userId: string, filename: any) => {
	const res = await apiClient.post(`/vendors/${userId}/coi-upload-url`, { filename });
	return res.data.url;
};

const getCOIFile = async (vendorId: string) => {
	const res = await apiClient.get(`/vendors/${vendorId}/uploaded-docs`);
	const { coi } = res.data;
	return coi || [];
};

const deleteCOIFile = async (vendorId: string, filename: string) => {
	return apiClient.post(`/vendors/${vendorId}/delete-coi-file`, { filename });
};

const coiUploadNotification = async (vendorId: string) => {
	return apiClient.post(`/vendors/${vendorId}/coi-notification`, {});
};

const getFileUrl = async (vendorId: string, filename: string) => {
	try {
		const res = await apiClient.post(`/vendors/${vendorId}/uploaded-docs/download-url`, { filename });
		return res.data;
	} catch (err) {
		console.error(err);
		return;
	}
};

const createAddress = async (payload: { state: string; stateCode: string; city?: string }) => {
	return apiClient.post(`/addresses`, payload);
};

const updateNoRiskVendor = (vendorId: string) => {
	return apiClient.put(`/vendors/${vendorId}/no-risk`);
};

const getNotes = (vendorId: string) => {
	return apiClient.get(`/notes?vendorId=${vendorId}`);
};
  
const createNote = (note: any) => {
	return apiClient.post(`/notes`, note);
};

const deleteNote = (noteId: any) => {
	return apiClient.delete(`/notes?noteId=${noteId}`);
};

export default {
	getExemptionCode,
	getFactaExemptionCodes,
	getServices,
	getServicesProvided,
	getServiceAreas,
	getTaxClassifications,
	getBackgroundCheckInfo,
	submitBackgroundCheckInfo,
	submitW9,
	getW9,
	submitBizInfo,
	getBizInfo,
	submitGeneralLiability,
	getGeneralLiability,
	submitAutoLiability,
	getAutoLiability,
	submitExcessLiability,
	getExcessLiability,
	submitWorkersComp,
	getWorkersComp,
	getVendor,
	getConfiguration,
	getVendorServices,
	deleteAutoLiability,
	deleteGeneralLiability,
	deleteExcessLiability,
	deleteWorkersComp,
	acceptMsa,
	getInsurancePolicies,
	getW9File,
	getCOIUploadUrl,
	getCOIFile,
	deleteW9File,
	deleteCOIFile,
	getW9UploadUrl,
	getServiceRiskLevels,
	getFileUrl,
	createAddress,
	coiUploadNotification,
	updateNoRiskVendor,
	getNotes, createNote, deleteNote, acceptRespropMsa
};
