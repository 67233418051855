import Container from '../../components/container';
import { Box, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import ButtonCustom from '../../components/button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../svg/idcore-logo.svg';
import LockOpen from '@mui/icons-material/LockOpen';
import FastForward from '@mui/icons-material/FastForwardTwoTone';
import WorkOutline from '@mui/icons-material/WorkOutline';
import AddBusinessIcon from '@mui/icons-material/AddBusinessRounded'
import HowToRegIcon from '@mui/icons-material/HowToRegRounded'
import AddModeratorIcon from '@mui/icons-material/AddModeratorRounded'
import InsertFileIcon from '@mui/icons-material/InsertDriveFileRounded'
import { useIsMobile } from 'src/utils/useIsMobile';

interface ISession {
	userId: string;
	role: string;
	details: any;
}
type RouteProps = {
	session: ISession;
	sessionIsEmpty: () => Boolean;
};

const landingCardData = [
	{
		title: 'Access More Jobs',
		icon: <LockOpen></LockOpen>,
	},
	{
		title: 'Get On-site Quicker',
		icon: <FastForward></FastForward>,
	},
	{
		title: 'Grow Your Business',
		icon: <WorkOutline></WorkOutline>,
	}
];

const Home = ({ session, sessionIsEmpty }: RouteProps) => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const handleContinue = () => {
		const search = window.location.search;
		if (sessionIsEmpty()) {
			navigate(`/login${search}`);
		} else {
			if (session.role === 'VENDOR') {
				navigate(`/vendor/dashboard`);
			}
		}
	};

	return (
		<Container>
			<div className={'landingWrap'}>
				<Box >
					<Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', gap: '20px', alignContent: 'center', alignItems: 'center'}}>
						<Box sx={{display: 'flex', flexDirection: 'row', gap: '20px', alignContent: 'center', alignItems: 'center'}}>
							<Logo width={176} height={44}></Logo>
						</Box>
						<Typography sx={{fontSize: '20px', lineHeight: '28px', fontWeight: 500}}>
							Find your next job with IDcore
						</Typography>
					</Grid>
					<Box sx={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', alignContent: 'center', justifyContent: 'center', marginTop: '24px'}}>
						{landingCardData.map((v, k) => {
							return (
								<Box key={k}
									sx={{
										bgcolor: '#2729370D',
										borderRadius: '8px',
										p: 3,
									}}
								>
									{v.icon}
									<Typography color={'#272937'} sx={{fontSize: '16px', lineHeight: '24px'}}>{v.title}</Typography>
								</Box>
							);
						})}
					</Box>

					<Box sx={{marginTop: '64px'}}>
						<Typography sx={{color: '##272937', fontSize: '20px', lineHeight: '28px', fontWeight: 600}}>
						Sign up in 4 steps
						</Typography>
					</Box>

					<Box sx={{marginTop: '32px', display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', flexWrap: 'wrap', rowGap: '8px'}}>
						<Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', maxWidth: '176px'}}>
							<Box sx={{border: '2px solid #334E68', borderRadius: '32px',  width: '48px', height: '48px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
								<AddBusinessIcon sx={{width: '24px', height: '24px', color: '#334E68'}}/>
							</Box>
							<Box>
								<Typography>Enter in the details about your business.</Typography>
								<Typography>(W-9 and TIN info)</Typography>
							</Box>
						</Box>
						{!isMobile && <Box sx={{height: '2px', minWidth:'64px', maxWidth: '150px', width: '100%', background: '#334E68', marginTop: '24px', marginX: '-80px'}}></Box>}
						<Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', maxWidth: '176px'}}>
							<Box sx={{border: '2px solid #334E68', borderRadius: '32px',  width: '48px', height: '48px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
								<HowToRegIcon sx={{width: '24px', height: '24px', color: '#334E68'}}/>
							</Box>
							<Typography>Complete a background check.</Typography>
						</Box>
						{!isMobile && <Box sx={{height: '2px', minWidth:'64px', maxWidth: '150px', width: '100%', background: '#334E68', marginTop: '24px', marginX: '-80px'}}></Box>}
						<Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', maxWidth: '176px'}}>
							<Box sx={{border: '2px solid #334E68', borderRadius: '32px',  width: '48px', height: '48px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
								<AddModeratorIcon sx={{width: '24px', height: '24px', color: '#334E68'}}/>
							</Box>
							<Typography>Upload your certificate(s) of insurance.</Typography>
						</Box>
						{!isMobile && <Box sx={{height: '2px', minWidth:'64px', maxWidth: '150px', width: '100%', background: '#334E68', marginTop: '24px', marginX: '-80px'}}></Box>}
						<Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', maxWidth: '176px'}}>
							<Box sx={{border: '2px solid #334E68', borderRadius: '32px',  width: '48px', height: '48px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
								<InsertFileIcon sx={{width: '24px', height: '24px', color: '#334E68'}}/>
							</Box>
							<Typography>Sign master agreement & confirm your information.</Typography>
						</Box>
					</Box>

					<Box sx={{marginTop: '64px', background: '#243B53', padding: '12px 20px', cursor: 'pointer', borderRadius: '8px'}} onClick={handleContinue}>
						<Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#FFFFFF'}}>Get Started</Typography>
					</Box>

					{/* <Grid item xs={12}>
						<ButtonCustom onClick={handleContinue} round="full">
							Get Started
						</ButtonCustom>
						<Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
							<Typography pr={1} sx={{ transform: 'translate(-10%, -10%)' }}>
								Powered by
							</Typography>
							<Logo width={64} height={16}></Logo>
						</Box>
					</Grid> */}
				</Box>
			</div>
		</Container>
	);
};

export default Home;
