import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
    Typography,
    Box,
    Paper,
    CircularProgress, Stack, Link,
} from '@mui/material';
import api from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import { useNavigate, useLocation } from 'react-router-dom';
import VendorUploadedFile from 'src/components/Files/VendorUploadedFile';
import UploadFileDropZone from 'src/components/Files/UploadFileDropZone';
import ButtonCustom from 'src/components/button';

interface Props {
    tabChanger : (index: number) => void;
    updateW9Info: (data: any) => void;
}
export const W9FileUpload = ({tabChanger, updateW9Info}: Props) => {
	const navigate = useNavigate();
  const { state }: any = useLocation();
  const { session, isNoRisk, isBgSkip } = useContext(SessionContext);
	const [loading, setLoading] = useState<boolean>(false);
	const {
		details: { vendorId },
	} = session;

  const [ w9File, setW9File ] = useState<any>();
	const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    api.getW9File(vendorId)
      .then(res => {
        if (res && res !== 'w9/') {
          setW9File(res);
        }
      })
      .catch(err => {
        setError('Failed to retrieve vendors w9 file.')
      })
      .finally(() => { setLoading(false) });
  }, [vendorId]);

	const removeFile = async (filename: string) => {
    await api.deleteW9File(vendorId, filename);
    setW9File('');
	};

  const uploadFile = useCallback((files: File[]) => {
    setLoading(true);
    files.map(f => {
      api.getW9UploadUrl(vendorId, f.name)
      .then(url => {
        fetch(url, {
          method: 'PUT',
          body: f,
          headers: {
            'Content-Type': f.type
          }
        });
        setW9File({key: `w9/${f.name}`});
      });
      const formdata = new FormData();
      formdata.append("file", f, f.name);
      fetch('https://coi-extractor-ml.idcore.com/w9Extract/v1', {
        method: 'POST',
        body: formdata,
      }).then(async(r) => {
        const resp = await r.json();
        updateW9Info(resp);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    })
  }, []);

  const onContinue = () => {
    if (state?.next) {
      navigate(state?.next);
    } else {
      tabChanger(2);
    }
  };

	if (loading) {
		return (
			<Box p={8} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
  } else {
    return (
      <Box sx={{mt: 4}}>
        <Paper elevation={0}>
          <Typography variant="h2" mb={3}>Upload Form W-9</Typography>
          { (w9File) ? (
            <VendorUploadedFile
              loading={loading}
              files={[w9File]}
              onDelete={removeFile}
            />
          ) : (
            <UploadFileDropZone
              onDrop={uploadFile}
              title='Upload form W-9'
            />
          )}
        </Paper>
        { (w9File) ? (
          <ButtonCustom
            onClick={onContinue}
            className="bigButton submit-cta"
            round={'non-full'}
            variant="contained"
            color="primary"
            size="large"
          >
            Type in my W-9 Information
          </ButtonCustom>
        ) : (
          ''
        )}
      </Box>
    )
  }
};
