import { Box, Typography } from "@mui/material"
import ButtonCustom from "src/components/button"
import { useIntercom } from "src/utils/intercom";

export const CoverageButtons = ({showInsurepro}: any) => {
    const { run } = useIntercom();

    return (
        <>
            <Typography variant="h2">Don't have Insurance? Get Covered Now</Typography>
            <Box flexDirection={'row'} display={'flex'} gap={3} paddingTop={3} flexWrap={'wrap'}>
                <Box flexDirection="column" display={'flex'} alignItems={'center'} style={{
                    background: 'white',
                    width: showInsurepro ? 'calc(33% - 16px)' : 'calc(50% - 12px)',
                    minHeight: '200px',
                    minWidth: '240px',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.05)'
                }}>
                    <img src='lemonade.png' height={28}/>
                    <p style={{
                        fontFamily: 'Nunito',
                        lineHeight: '24px', 
                        fontSize: '16px',
                        color: '#2C2C2C',
                        minHeight: '72px'
                    }}>Based on what you need, you can get covered for as low as $8.21/mo</p>
                    <ButtonCustom style={{ 
                        background: 'rgba(255, 0, 132, 1)', 
                        borderRadius: '24px', 
                        fontWeight: 600, 
                        lineHeight: '20px', 
                        fontSize: '14px', 
                        letterSpacing:'0.04em',
                        fontFamily: 'Open Sans',
                    }}><a style={{width: '100%'}} target='_blank' href='https://www.lemonade.com/renters'>GET COVERAGE</a></ButtonCustom>
                </Box>
                <Box flexDirection="column" display={'flex'} alignItems={'center'} style={{
                    background: 'white',
                    width: showInsurepro ? 'calc(33% - 16px)' : 'calc(50% - 12px)',
                    minWidth: '240px',
                    minHeight: '200px',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.05)'
                }}>
                    <img src='progressive.png' height={28}/>
                    <p style={{
                        fontFamily: 'Nunito',
                        lineHeight: '24px', 
                        fontSize: '16px',
                        color: '#2C2C2C',
                        minHeight: '72px'
                    }}>Based on what you need, you can get covered for as low as $13.00/mo</p>
                    <ButtonCustom style={{ 
                        background: 'rgba(45, 149, 229, 1)',
                        borderRadius: '24px', 
                        fontWeight: 600, 
                        lineHeight: '20px', 
                        fontSize: '14px', 
                        letterSpacing:'0.04em',
                        fontFamily: 'Open Sans',
                    }}><a style={{width: '100%'}} target='_blank' href='https://www.progressive.com/renters'>GET COVERAGE</a></ButtonCustom>
                </Box>
                {showInsurepro && <Box flexDirection="column" display={'flex'} alignItems={'center'} style={{
                    background: 'white',
                    width: showInsurepro ? 'calc(33% - 16px)' : 'calc(50% - 12px)',
                    minWidth: '240px',
                    minHeight: '200px',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.05)'
                }}>
                    <img src='insurepro.png' height={28}/>
                    <p style={{
                        fontFamily: 'Nunito',
                        lineHeight: '24px', 
                        fontSize: '16px',
                        color: '#2C2C2C',
                        minHeight: '72px'
                    }}>Compare multiple renters insurance rates and save</p>
                    <ButtonCustom style={{ 
                        background: '#288186',
                        borderRadius: '24px', 
                        fontWeight: 600, 
                        lineHeight: '20px', 
                        fontSize: '14px', 
                        letterSpacing:'0.04em',
                        fontFamily: 'Open Sans',
                    }}
                    onClick={() => {run('insurepro')}}
                    >TALK TO AN AGENT</ButtonCustom>
                </Box>}
            </Box>
        </>
    )
}