import React, { ReactNode, useContext } from 'react';
import clsx from 'clsx';
import style from './header.module.scss';
import { ReactComponent as Logo } from '../../svg/idcore-logo-1.svg';
import { Box, Container, Typography } from '@mui/material';
import Person from '@mui/icons-material/PersonOutlineRounded';
import { SessionContext } from 'src/context/SessionContext';

interface ContainerProps {
	secondaryLogo?: ReactNode;
	className?: string;
	titleType: 'spaced' | 'squeezed' | boolean;
}
const Header: React.FC<ContainerProps> = ({ titleType, className }) => {
	const { logout } = useContext(SessionContext);
	return (
		<Box className={clsx([style.headerTop, className])}>
			<Container sx={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', paddingY: '24px'}}>
				<div className={['header-logo'].concat(titleType && titleType === 'squeezed' ? 'squeezed' : []).join(' ')}>
					<Logo />
				</div>
				{/* {titleType && titleType === 'spaced' && (
					<Typography variant="body2Spaced" component="h1" className={clsx([style.titleSpaced, style.title])}>
						DASHBOARD
					</Typography>
				)} */}
				{/* {titleType && titleType === 'squeezed' && (
					<Typography variant="subtitle2" component="h1" className={clsx([style.titleSqueezed, style.title])}>
						ResProp Management
					</Typography>
				)} */}
				<Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={logout}>
					<Person sx={{width: '24px', height: '24px', color: '#FFF'}}/>
					<Typography sx={{color: '#FFF', fontSize: '16px', fontWeight: 700, lineHeight: '24px'}}>Log Out</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default Header;
