import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
type Props = {};

const useStyles = makeStyles({
	box: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const LoadingScreen = (props: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.box}>
			<CircularProgress />
		</Box>
	);
};

export default LoadingScreen;
