import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom';
import Container from '../../components/container';
import Header from '../../components/header';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Alert, Box, Typography } from '@mui/material';
import { SessionContext } from '../../context/SessionContext';
import apiClient from '../../api';
import * as FullStory from '@fullstory/browser';

type ProgressType = { handleInvalid: () => void };

const EnrollmentLayout = () => {
	const [progress, setProgress] = useState({
		bizInfo: false,
		w9: false,
		bgCheck: false,
		coi: false,
		msa: false,
	});
	const navigate = useNavigate();
	const { session, isNoRisk, isBgSkip, setIsBgSkip } = useContext(SessionContext);
	const { vendorId } = session.details;
	const [referenceId, setReferenceId] = useState<string>();

	const navigateTo = (tab: string) => {
		navigate(`/vendor/enrollment/${tab}`);
	};

	useEffect(() => {
		getCurrentTab();
		getReferenceId();
	}, []);

	const getCurrentTab = () => {
		const { pathname } = window.location;
		switch (pathname?.toString().split('/')[3]?.toLowerCase()) {
			case 'business-info':
				if (!progress.bizInfo) setProgress({ ...progress, bizInfo: true });
				return 0;
			case 'w9':
				if (!progress.w9) setProgress({ ...progress, w9: true });
				return 1;
			case 'background-check':
				if (!progress.bgCheck) setProgress({ ...progress, bgCheck: true });
				return 2;
			case 'coi':
				if (!progress.coi) setProgress({ ...progress, coi: true });
				return isBgSkip ? 2 : 3;
			case 'msa':
				if (!progress.msa) setProgress({ ...progress, msa: true });
				return isNoRisk && isBgSkip ? 2 : isBgSkip ? 3 : isNoRisk ? 3 : 4;
			case 'summary':
				return isNoRisk && isBgSkip ? 3 : isBgSkip ? 4 : isNoRisk ? 4 : 5;
			case 'payment':
				return isBgSkip ? 5 : isNoRisk ? 4 : 6;
		}
	};

	/**
	 * Retrieves the vendor reference Id for a new vendor when it is available.
	 */
	const getReferenceId = async () => {
		try {
			if (vendorId && !referenceId) {
				const resp = await apiClient.getVendor(session.details?.vendorId);
				setIsBgSkip(resp.data?.w9?.tinType === 'EIN');
				const refId = resp.data.referenceId;
				if (refId) {
					setReferenceId(refId);
					FullStory.identify(vendorId)
					FullStory.setUserVars({ referenceId: refId });
				};
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleInvalid = () => {
		const { pathname } = window.location;
		const path = pathname.split('/').at(-1)?.toLowerCase();
		switch (path) {
			case 'coi':
				if (progress.msa) setProgress({ ...progress, msa: false });
		}
	};

	return (
		<>
			<Header titleType="squeezed" />
			<Container className="vendor-dashboard enrollment">
				<Box pt={1} pb={1}>
					<Alert severity="info">
						Please make a note <strong>{referenceId}</strong> is your vendor reference number
					</Alert>
				</Box>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={getCurrentTab()}
						variant="scrollable"
						scrollButtons={false}
						aria-label="enrollment-process"
						className="enrollment-tabs"
					>
						<Tab
							disabled={!progress.bizInfo}
							label="Business Information"
							onClick={() => {
								navigateTo('business-info');
							}}
						/>
						<Tab
							disabled={!progress.w9}
							label="Form W-9"
							onClick={() => {
								navigateTo('w9');
							}}
						/>
						{!isBgSkip && (
							<Tab
								disabled={!progress.bgCheck}
								label="Background Check Information"
								onClick={() => {
									navigateTo('background-check');
								}}
							/>
						)}
						{!isNoRisk && (
							<Tab
								disabled={!progress.coi}
								label="Certification of Insurance Information"
								onClick={() => {
									navigateTo('coi');
								}}
							/>
						)}

						<Tab
							disabled={!progress.msa}
							label="Master Service Agreement"
							onClick={() => {
								navigateTo('msa');
							}}
						/>
						<Tab
							disabled={!progress.msa}
							label="Summary"
							onClick={() => {
								navigateTo('summary');
							}}
						/>
						{/* {!isNoRisk && (
							<Tab
								disabled={!progress.msa}
								label="Payment"
								onClick={() => {
									navigateTo('payment');
								}}
							/>
						)} */}
					</Tabs>
				</Box>
				<Box className="customCard" sx={{marginTop: '24px'}}>
					<Typography variant="body2" className="steps-header">
						{isNoRisk && <>
							{isBgSkip && <>{(getCurrentTab() ?? 0) + 1} of 4</>}
							{!isBgSkip && <>{(getCurrentTab() ?? 0) + 1} of 5</>}
						</>}
						{!isNoRisk && <>
							{isBgSkip && <>{(getCurrentTab() ?? 0) + 1} of 5</>}
							{!isBgSkip && <>{(getCurrentTab() ?? 0) + 1} of 6</>}
						</>}
						{/* {isNoRisk && isBgSkip ? `${getCurrentTab()} of 4` : isBgSkip ? `${getCurrentTab()} of 5` : isNoRisk ? `${getCurrentTab()} of 5` : `${getCurrentTab()} of 7`} */}
					</Typography>

					<Outlet context={{ handleInvalid }} />
				</Box>
			</Container>
		</>
	);
};

export function useProgress() {
	return useOutletContext<ProgressType>();
}

export default EnrollmentLayout;
