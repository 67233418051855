import { useState, useEffect, useContext } from 'react';
import Select from '@mui/material/Select';
import {
	Grid,
	Checkbox,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormGroup,
	MenuItem,
	TextField,
	Typography,
	Autocomplete,
	Box,
	Alert,
	InputAdornment,
	IconButton,
	Stack,
	Link,
	List,
	ListItem,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import style from './w9.module.scss';
import apiClient from '../../../api/index';
import { SessionContext } from '../../../context/SessionContext';
import { Spinner } from '../../loaders';
import { states } from '../../../data/states';
import SaveAndContinue from '../../save-and-continue';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATES } from 'src/data/stateOptions';

interface IDynamicObject {
	[key: string]: string;
}

interface Props {
	tabChanger: (index: number) => void;
	data: any;
}

const tinRegex = /^\d{9}$/;
const nameRegex = /^[a-zA-Z ]+$/;

export const W9Form = ({ tabChanger, data }: Props) => {
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const { session, isNoRisk, isBgSkip, setIsBgSkip } = useContext(SessionContext);
	const [taxClassification, setTaxClassificationValue] = useState([]);
	const [showTin, setShowTin] = useState<boolean>(false);
	const [showTinConfirm, setShowTinConfirm] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState(false);
	const { vendorId } = session.details;
	const [error, setError] = useState<string | null>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm({
		resolver: yupResolver(
			Yup.object().shape({
				businessName: Yup.string().required('Legal Business name is Required'),
				dba: Yup.string().required('DBA name is Required'),
				federalTaxClassification: Yup.string().required('Tax Classification is Required'),
				address: Yup.string().required('Address is Required'),
				city: Yup.string().required('City is Required'),
				state: Yup.string().required('State name is Required'),
				zipcode: Yup.string().required('Zip Code is Required'),
				tinType: Yup.string().oneOf(['SSN', 'EIN'], 'Tin Type is Required'),
				tin: Yup.string().required('TIN is Required').matches(tinRegex, ''),
				repeatTin: Yup.string().oneOf([Yup.ref('tin'), null], 'TIN must match').matches(tinRegex, ''),
				certification: Yup.bool().oneOf([true], 'Certification check is required'),
				eSignature: Yup.string().required('A Signature is Required').matches(nameRegex, 'Only characters are allowed'),
				checkedSignature: Yup.bool().oneOf([true], 'Checkbox selection is required'),
			})
		),
		// Note: Default values are required for inputs wrapped in <Controller.../>
		defaultValues: {
			businessName: '',
			dba: '',
			federalTaxClassification: '',
			address: '',
			city: '',
			state: null,
			zipcode: '',
			tinType: '',
			tin: '',
			repeatTin: '',
			certification: false,
			eSignature: '',
			checkedSignature: false,
		},
	});

	useEffect(() => {
		apiClient.getW9(vendorId).then((res: any) => {
			// Reset the form with the saved values
			apiClient.getTaxClassifications().then(r => {
				setTaxClassificationValue(r.data);
				if(data) {
					reset({...data, federalTaxClassification: r.data.find((d: any) => d.code === data.federalTaxClassification)?.id});
					setIsBgSkip(data?.tinType === 'EIN');
				} else {
					reset(res);
					setIsBgSkip(res?.tinType === 'EIN');
				}
			});
		});
	}, [reset, vendorId, data]);

	const saveAndContinue = async (data: any) => {
		setSubmitting(true);
		const stateObj = STATES.find(item => item.id === data.state);
		if (typeof stateObj === 'object' && Object.keys(stateObj).length) {
			let cityData: { state: string; stateCode: string; city?: string } | undefined = undefined;
			cityData = {
				state: stateObj.label,
				stateCode: stateObj.id,
				city: data.city,
			};
			await apiClient
				.createAddress(cityData)
				.then(res => {
					console.log('created Address', res.data);
				})
				.catch(err => {
					console.error(err);
				});
		}
		apiClient
			.submitW9(vendorId, data)
			.then(r => {
				if (state?.next) {
					navigate(state?.next);
				} else if (isBgSkip && isNoRisk) {
					navigate('/vendor/enrollment/msa');
				} else if (isBgSkip) {
					navigate('/vendor/enrollment/coi');
				} else {
					navigate('/vendor/enrollment/background-check');
				}
				setSubmitting(false);
			})
			.catch((err: any) => {
				let errMessage: string = 'Something went wrong.';
				if (err.response && typeof err.response.data === 'string' && err.response.data) {
					errMessage = err.response.data;
				}
				setError(errMessage);
				setSubmitting(false);
			});
	};
	const disableMouseDownTin = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	const handleClickShowTin = () => {
		setShowTin(prev => !prev);
	};

	const handleClickShowConfirmTin = () => {
		setShowTinConfirm(prev => !prev);
	};

	return (
		<Box sx={{mt: 4}}>
			<Typography variant="h2">Form W-9</Typography>
			<Typography mb={3} sx={{color: '#272937BF', fontSize: '16px', lineHeight: '24px'}}>
				Information entered on this page will be used to generate your Electronic W-9.
			</Typography>
			<div>
				{/* add "invalidState" class with "inputwrap" to show error state */}
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.businessName ? 'errorText' : []).join(' ')}>
						Name <span className="textDanger">*</span>
					</label>
					<TextField placeholder='Enter Your Name' {...register('businessName')} error={!!errors.businessName} />
					<Typography
						className={['textMuted', 'inputNote'].concat(errors.businessName ? 'errorText' : []).join(' ')}
						variant="body2"
					>
						As shown on your income tax return
					</Typography>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.dba ? 'errorText' : []).join(' ')}>
						DBA/Doing business as <span className="textDanger">*</span>
					</label>
					<TextField placeholder='Enter Your DBA' {...register('dba')} hiddenLabel error={!!errors.dba} />
					<Typography className={['textMuted', 'inputNote'].concat(errors.dba ? 'errorText' : []).join(' ')} variant="body2">
						Business name/disregarded entity name, if different than above
					</Typography>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.federalTaxClassification ? 'errorText' : []).join(' ')}>
						Federal tax classification <span className="textDanger">*</span>
					</label>
					<Controller
						control={control}
						name="federalTaxClassification"
						render={({ field: { onChange, value } }) => (
							<Select placeholder='Select an Option' error={!!errors.federalTaxClassification} onChange={onChange} value={value} 
								displayEmpty={true} sx={{color: value ? '' : '#27293759'}}>
								<MenuItem key={`tax_0`} value={''} disabled>
									Select an Option
								</MenuItem>
								{taxClassification.map((data: IDynamicObject, idx) => (
									<MenuItem key={`tax_${idx}`} value={data.id}>
										{data.name}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.address ? 'errorText' : []).join(' ')}>
						Address <span className="textDanger">*</span>
					</label>
					<TextField placeholder='Enter Your Address' {...register('address')} hiddenLabel variant="outlined" error={!!errors.address} />
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.city ? 'errorText' : []).join(' ')}>
						City <span className="textDanger">*</span>
					</label>
					<TextField placeholder='Enter your City' {...register('city')} id="city" hiddenLabel variant="outlined" error={!!errors.city} />
				</div>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<div className="inputWrap mb12">
							<label className={['inputLabel'].concat(errors.state ? 'errorText' : []).join(' ')}>
								State <span className="textDanger">*</span>
							</label>
							<Controller
								control={control}
								name="state"
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										onChange={(e, v) => {
											onChange(v);
										}}
										fullWidth
										options={states}
										getOptionLabel={(option: any) => option}
										renderInput={params => (
											<TextField {...params} hiddenLabel placeholder="Select State" error={!!errors.state} variant="outlined" />
										)}
										value={value}
									/>
								)}
							/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="inputWrap mb12">
							<label className={['inputLabel'].concat(errors.zipcode ? 'errorText' : []).join(' ')}>
								ZIP code <span className="textDanger">*</span>
							</label>
							<TextField placeholder='Enter Zip Code' {...register('zipcode')} hiddenLabel variant="outlined" error={!!errors.zipcode} />
						</div>
					</Grid>
				</Grid>
				<div className="inputWrap buttonCheck mb12">
					<label className={['inputLabel'].concat(errors.tinType ? 'errorText' : []).join(' ')}>
						Select TIN Type <span className="textDanger">*</span>
					</label>
					<Controller
						control={control}
						name="tinType"
						render={({ field: { onChange, value } }) => (
							<Select placeholder='Select an Option' error={!!errors.federalTaxClassification} onChange={(e) => {
								console.log(e);
								setIsBgSkip(e.target.value === 'EIN');
								onChange(e);
							}} value={value} displayEmpty={true} sx={{color: value ? '' : '#27293759'}}>
								<MenuItem key={`tax_0`} value={''} disabled>
									Select an Option
								</MenuItem>
								{[{id:'SSN', name: 'Social Security Number'}, {id:'EIN', name: 'EIN'}].map((data: IDynamicObject, idx) => (
									<MenuItem key={`tax_${idx}`} value={data.id}>
										{data.name}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.tin ? 'errorText' : []).join(' ')}>
						{isBgSkip ? 'EIN' : 'Social Security Number'} <span className="textDanger">*</span>
					</label>
					<TextField
						placeholder={isBgSkip ? '00-0000000' : '000-00-0000'}
						type={showTin ? 'text' : 'password'}
						hiddenLabel
						variant="outlined"
						className={style.textInput}
						error={!!errors.tin}
						{...register('tin')}
						inputProps={{
							maxLength: 9,
							minLength: 9
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowTin}
										onMouseDown={disableMouseDownTin}
										edge="end"
									>
										{showTin ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Typography className={['textMuted', 'inputNote'].concat(errors.tin ? 'errorText' : []).join(' ')} variant="body2">
						Only type numbers, no dashes allowed
					</Typography>
				</div>
				<div className="inputWrap mb12">
					<label className={['inputLabel'].concat(errors.repeatTin ? 'errorText' : []).join(' ')}>
						Confirm {isBgSkip ? 'EIN' : 'Social Security Number'} <span className="textDanger">*</span>
					</label>
					<TextField
						placeholder={isBgSkip ? '00-0000000' : '000-00-0000'}
						{...register('repeatTin')}
						hiddenLabel
						variant="outlined"
						error={!!errors.repeatTin}
						type={showTinConfirm ? 'text' : 'password'}
						className={style.textInput}
						{...register('repeatTin')}
						inputProps={{
							maxLength: 9,
							minLength: 9
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowConfirmTin}
										onMouseDown={disableMouseDownTin}
										edge="end"
									>
										{showTinConfirm ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
			</div>
			<Box>
				<Typography mb={2} sx={{color: '#272937BF', fontSize: '14px', lineHeight: '20px'}}>
				The TIN provided must match the name given on Line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, <Link sx={{color: '#0B69A3'}} href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>see the instructions for Part 1</Link>, later. For other entities, your TIN is your employer identification number (EIN). If you do not have a EIN number, see <Link sx={{color: '#0B69A3'}} href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>how to get a TIN</Link>, later.
				</Typography>
				<Typography mb={2} sx={{color: '#272937BF', fontSize: '14px', lineHeight: '20px'}}>
				Note: If your account is in more than one name, <Link sx={{color: '#0B69A3'}} href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>see the instructions for line 1</Link>. also see <Link sx={{color: '#0B69A3'}} href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>What Name and Number To Give the Requester</Link> for guidelines on whose number to enter.
				</Typography>

				<Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
					<Typography
						className={[''].concat(errors.certification ? 'errorText' : []).join(' ')}
						sx={{fontSize: '16px', lineHeight: '24px', color: '#000'}}
					>
						Acknowledgement <span className="textDanger">*</span>
					</Typography>
					<List sx={{ listStyle: "decimal", pl: 3 }}>
						<ListItem sx={{ display: "list-item" }}>
							<Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px'}}>
								The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to
								be issued to me); and
							</Typography>
						</ListItem>
						<ListItem sx={{ display: "list-item" }}>
							<Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px'}}>
								I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not
								been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of
								a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject
								to backup withholding; and I am a U.S. citizen or other U.S. person (defined below); and
							</Typography>
						</ListItem>
						<ListItem sx={{ display: "list-item" }}>
							<Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px'}}>
								The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is
								correct.
							</Typography>
						</ListItem>
					</List>

					<FormGroup aria-label="position" row>
						<FormControlLabel
							control={
								<Controller
									control={control}
									name="certification"
									render={({ field: { onChange, value } }) => (
										<Checkbox
											checked={value}
											onChange={onChange}
											sx={{
												color: errors.certification ? 'red' : '#27293759',
											}}
										/>
									)}
								/>
							}
							label="Under penalties of perjury, I acknowledge all the information above is true"
							labelPlacement="end"
						/>
					</FormGroup>
				</Box>
				<Box sx={{marginTop: '32px', display: 'flex', gap: '32px', flexDirection: 'column'}}>
					<Box className="inputWrap" sx={{display: 'flex', gap: '8px'}}>
						<Typography sx={{fontSize: '16px', lineHeight: '24px'}} className={[''].concat(errors.eSignature ? 'errorText' : []).join(' ')}>
							Enter Your Full Name for Digitally Signing The W-9 <span className="textDanger">*</span>
						</Typography>
						<TextField
							placeholder='Enter Your Name Here'
							{...register('eSignature')}
							id="eSignature"
							hiddenLabel
							variant="outlined"
							error={!!errors.eSignature}
						/>
					</Box>
					<Box>
						<FormGroup className="inputNote" aria-label="position" row>
							<FormControlLabel
								control={
									<Controller
										control={control}
										name="checkedSignature"
										render={({ field: { onChange, value } }) => (
											<Checkbox
												checked={value}
												onChange={onChange}
												sx={{
													color: errors.checkedSignature ? 'red' : '#27293759',
												}}
											/>
										)}
									/>
								}
								label="By Selecting this box, I herby assert that I am authorized to Electronically sign any and all documents including, but not limited to, Tax Information, the Master Service Agreement and our Terms & Conditions (if applicable). I understand that by completing this enrollment. I will be electronically signing these documents."
								labelPlacement="end"
							/>
						</FormGroup>
						{submitting === false ? (
							<SaveAndContinue onClick={handleSubmit(saveAndContinue)} buttonTitle="Save And Continue" />
						) : (
							<Spinner />
						)}
						{error && (
							<Box pt={2} pb={2}>
								<Alert severity="error">{error}</Alert>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
