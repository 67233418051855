import { Box, Divider } from "@mui/material"
import { useEffect, useState } from "react";
import { ReactComponent as CheckIcon } from 'src/svg/check-icon.svg';
import { ReactComponent as CrossIcon } from 'src/svg/cross-icon.svg';
import { ReactComponent as PPIcon } from 'src/svg/pp.svg';
import { ReactComponent as MPIcon } from 'src/svg/mp.svg';
import { ReactComponent as LOUIcon } from 'src/svg/lou.svg';
import { ReactComponent as MinusIcon } from 'src/svg/minus-ins.svg';
import { ReactComponent as PlusIcon } from 'src/svg/plus-ins.svg';
import { CoverageButtons } from "./coverage-buttons";
import ButtonCustom from "src/components/button";
import { useIntercom } from "src/utils/intercom";

export const RequirementsMatcher = ({extractedRequirements}: any) => {
    const { run } = useIntercom();

    const requirements = [
        {
            title: 'Have personal liability coverage of $100,000',
            val: 100000,
            errorMsg: 'You need to have a personal liability coverage of $100,000 for this apartment.'
        },
        {
            title: 'Start date of insurance should be Oct 2nd 2022',
            val1: '10/1/2023',
            val2: '10/2/2022',
        },
        {
            title: 'The insured address is 714 TURTLE CREEK BLVD APT 244, AUSTIN, TX 78745',
            val: '714 TURTLE CREEK BLVD APT 244, AUSTIN, TX 78745',
        }
    ];

    const getValueFromMoney = (str: string) => {
        return Number(str.replaceAll('$', '').replaceAll(',', ''));
    }
    const [reqMatched, setReqMatched] = useState([true, true, true]);
    const [overallReqMatched, setOverallReqMatched] = useState(true);
    
    useEffect(() => {
        console.log(extractedRequirements);
        const tempMatched = [];
        if(getValueFromMoney(extractedRequirements.Limits['Personal Liability']) >= (requirements[0].val as number)) {
            tempMatched.push(true);
        } else {
            tempMatched.push(false);
        }

        if(new Date(extractedRequirements['Policy Expire Date']) >= new Date(requirements[1].val1 as string) && 
        new Date(extractedRequirements['Policy Start Date']) <= new Date(requirements[1].val2 as string)
        ) {
            tempMatched.push(true);
        } else {
            tempMatched.push(false);
        }

        if(extractedRequirements['Insured Address'] === (requirements[2].val as string)) {
            tempMatched.push(true);
        } else {
            tempMatched.push(false);
        }
        setReqMatched(tempMatched);
        setOverallReqMatched(tempMatched[0] && tempMatched[1] && tempMatched[2]);
    }, [extractedRequirements]);

    return (
        <>
            <Box style={{
                fontSize: '30px',
                lineHeight: '36px',
                color: '#0D0D0D',
                fontFamily: 'Nunito',
                paddingTop: '56px'
            }}>
                {overallReqMatched && <>You Have Met The Requirements</>}
                {!overallReqMatched && <>You do not meet all the requirements</>}
            </Box>
            <Box paddingTop={3} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={4}>
                {requirements.map((req, index) => {
                    return <Box key={index} width={'calc(50% - 16px)'} display={'flex'} gap={'20px'} style={{
                        background: 'white',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 8px 1px rgba(0, 0, 0, 0.11)'
                    }}>
                        <Box style={{
                                width: 'calc(100% - 92px)',
                                paddingLeft: '32px',
                                alignSelf: 'center'
                            }}>
                            {req.title}
                        </Box>
                        {reqMatched[index] ? <CheckIcon/> : <CrossIcon />}
                    </Box>
                })}
            </Box>
            {overallReqMatched && 
                <>
                    <Box pt={8}>
                        <span style={{
                            fontFamily: 'Nunito',
                            fontSize: '30px',
                            lineHeight: '36px',
                            color: '#0D0D0D'
                        }}>Other Coverages</span>
                    </Box>
                    <Box pt={3} display={'flex'} gap={4} flexWrap={'wrap'}>
                        <Box style={{
                            background: 'white',
                            width: '225px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Box paddingTop={3}>
                                <PPIcon />
                            </Box>
                            <Box paddingTop={3}>
                                <span style={{
                                    fontSize: '20px', 
                                    lineHeight: '24px', 
                                    fontWeight: 600,
                                    fontFamily: 'Nunito'
                                }}>Personal Property</span>
                            </Box>
                            <Box paddingTop={1.5} paddingX={2} textAlign={'center'}>
                                <span style={{
                                    fontSize: '16px', 
                                    lineHeight: '24px', 
                                    fontFamily: 'Nunito'
                                }}>The total value of the stuff you own, such as electronics, clothing, furniture, etc.</span>
                            </Box>
                            <Divider style={{marginTop: '24px', border: '1px solid #D9E2EC', width: '201px'}}/>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} paddingX={2} paddingY={1.5}>
                                <MinusIcon/>
                                <span style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    fontSize: '24px',
                                    lineHeight: '28px'
                                }}>$15,000</span>
                                <a href='javascript:void(0)' onClick={() => {run('insurepro')}}><PlusIcon/></a>
                            </Box>
                        </Box>
                        <Box style={{
                            background: 'white',
                            width: '225px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Box paddingTop={3}>
                                <MPIcon />
                            </Box>
                            <Box paddingTop={3}>
                                <span style={{
                                    fontSize: '20px', 
                                    lineHeight: '24px', 
                                    fontWeight: 600,
                                    fontFamily: 'Nunito',
                                }}>Medical Payments</span>
                            </Box>
                            <Box paddingTop={1.5} paddingX={2} textAlign={'center'}>
                                <span style={{
                                    fontSize: '16px', 
                                    lineHeight: '24px', 
                                    fontFamily: 'Nunito'
                                }}>Covers medical expenses up to this amount if a guest or neighbor is injured on your property.</span>
                            </Box>
                            <Divider style={{marginTop: '24px', border: '1px solid #D9E2EC', width: '201px'}}/>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} paddingX={2} paddingY={1.5}>
                                <MinusIcon/>
                                <span style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    fontSize: '24px',
                                    lineHeight: '28px'
                                }}>$15,000</span>
                                <a href='javascript:void(0)' onClick={() => {run('insurepro')}}><PlusIcon/></a>
                            </Box>
                        </Box>
                        <Box style={{
                            background: 'white',
                            width: '225px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Box paddingTop={3}>
                                <LOUIcon />
                            </Box>
                            <Box paddingTop={3}>
                                <span style={{
                                    fontSize: '20px', 
                                    lineHeight: '24px', 
                                    fontWeight: 600,
                                    fontFamily: 'Nunito'
                                }}>Loss of Use</span>
                            </Box>
                            <Box paddingTop={1.5} paddingX={2} textAlign={'center'}>
                                <span style={{
                                    fontSize: '16px', 
                                    lineHeight: '24px', 
                                    fontFamily: 'Nunito'
                                }}>In case your place is unlivable, this covers accommodation and living expenses.</span>
                            </Box>
                            <Divider style={{marginTop: '24px', border: '1px solid #D9E2EC', width: '201px'}}/>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} paddingX={2} paddingY={1.5}>
                                <ButtonCustom style={{borderRadius: '4px', width: '100%', height: '36px'}} onClick={()=>{run('insurepro')}}>
                                    Get Coverage
                                </ButtonCustom>
                            </Box>
                        </Box>
                    </Box>
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} pt={6}>
                        <ButtonCustom style={{borderRadius: '24px', width: '100%'}} onClick={() => {window.close()}}>Finish</ButtonCustom>
                    </Box>
                </>
            }
            {!overallReqMatched && 
                <>
                    <Box pt={8} gap={2} display={'flex'} flexDirection={'column'}>
                        <span style={{
                            fontFamily: 'Nunito',
                            fontSize: '30px',
                            lineHeight: '36px',
                            color: '#0D0D0D'
                        }}>({reqMatched.filter(m => !m).length}) Issue(s) Found</span>
                        <span style={{
                            fontFamily: 'Nunito',
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#0D0D0D'
                        }}>{!reqMatched[0] && requirements[0].errorMsg} Here are some insurance carriers who can help get you the right insurance you need.</span>
                        <CoverageButtons showInsurepro={true}/>
                    </Box>
                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} pt={5}>
                        <ButtonCustom style={{
                            borderRadius: '24px', 
                            background: 'transparent',
                            width: '48%', color:'black',
                            border: '2px solid #0D1743'}}
                            onClick={() => {run('idcore')}}
                        >Chat With Support</ButtonCustom>
                        <ButtonCustom style={{borderRadius: '24px', width: '48%'}} onClick={() => {window.location.reload()}}>Upload New Document</ButtonCustom>
                    </Box>
                </>
            }
        </>
    )
}