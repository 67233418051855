import { useDropzone } from "react-dropzone";
import {
  Box,
  Typography
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';

interface IUploadFileDropZoneProps {
  onDrop: (files: File[]) => void;
  title: string;
};

const UploadFileDropZone = ({ onDrop, title }: IUploadFileDropZoneProps) => {
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 2,
    multiple: false,
  });

  return (
    <Box
      sx={{ 
        display: 'flex', alignItems: 'center', flexDirection: 'column', 
        width: '100%', border: '2px dashed #0B69A3', borderRadius: '8px',
        padding: '48px'
      }}
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        <FileUploadIcon  sx={{width: '24px', height: '24px', color: '#0B69A3'}}/>
      </Typography>
      <Typography sx={{ fontSize: 14, color: '#272937' }} gutterBottom>
       Drop files here or
      </Typography>
      <Typography sx={{ fontSize: 14, color: '#243B53', borderBottom: '1px solid #102A4333', cursor: 'pointer' }}>
       Browse
      </Typography>
        {fileRejections.length > 0 && (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            { fileRejections.map(f => {
              const errMsg = f.errors.map(e => e.message.toLowerCase() );
              return (
                <Typography
                  variant="body2"
                  mt={4}
                  fontSize="small" color="darkred"
                  sx={{ padding: 0.5, margin: 0, maxWidth: '60%' }}
                >{
                  `${f.file.name} rejected ${errMsg.join(':')}`}
                </Typography>
              );
            })}
          </Box>
        )}
    </Box>
  );
};

export default UploadFileDropZone;
