import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Autocomplete, Checkbox, Box, Alert, Chip, LinearProgress, Select, MenuItem } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import apiClient from '../../../api/index';
import { ReactComponent as DownArrow } from '../../../svg/Down-arrow.svg';
import { SessionContext } from '../../../context/SessionContext';
import { Spinner } from '../../loaders';
import { PhoneField } from '../../forms';
import SaveAndContinue from 'src/components/save-and-continue';
import GoogleMapsAutoComplete from 'src/components/google/autocomplete';

interface ServiceOption {
	categoryId: string;
	categoryName: string;
	id: string;
	name: string;
}

interface IServiceRiskLevel {
	id: string;
	name: string;
	riskLevel: string;
	categoryId: string;
	categoryName: string;
	pmcId: string;
}

interface ServiceAreaOption {
	id: string;
	name: string;
	state: string;
	stateCode: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// eslint-disable-next-line max-len
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const BizInfoForm = () => {
	const { state }: any = useLocation();
	const navigate = useNavigate();
	const { session, setRiskLevel, setServiceProvided, isNoRisk, isBgSkip } = useContext(SessionContext);
	// Service Categories Drop Down
	const [servicesInputText, setServicesInputText] = useState('');
	const [services, setServices] = useState<ServiceOption[]>([]);
	const [selectedServices, setSelectedServices] = useState<ServiceOption[]>([]);
	const [selectedServicesError, setSelectedServicesError] = useState<String>('');

	const [isOutsideLocations, setIsOutsideLocations] = useState<String>('');
	const [isOutsideLocationsError, setIsOutsideLocationsError] = useState<String>('');
	const [serviceAreasInputText, setServiceAreasInputText] = useState('');
	const [serviceAreas, setServiceAreas] = useState<ServiceAreaOption[]>([]);
	const [selectedServiceAreas, setSelectedServiceAreas] = useState<ServiceAreaOption[]>([]);
	const [selectedServiceAreasError, setSelectedServiceAreasError] = useState<String>('');

	// Services Provided Drop Down
	const [servicesProvidedInputText, setServicesProvidedInputText] = useState('');
	const [servicesProvided, setServicesProvided] = useState<IServiceRiskLevel[]>([]);
	const [selectedServicesProvided, setSelectedServicesProvided] = useState<IServiceRiskLevel[]>([]);
	const [selectedServicesProvidedError, setSelectedServicesProvidedError] = useState<String>('');
	const [servicesProvidedSearchTerm, setServicesProvidedSearchTerm] = useState<string>('');
	const [vendorDetails, setVendorDetails] = useState(null as any);

	const [submitting, setSubmitting] = useState(false);
	const [loading, setloading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
		reset,
		setValue
	} = useForm({
		resolver: yupResolver(
			Yup.object().shape({
				email: Yup.string().required('Email is required').email('Email is required'),
				accountsPayableEmail: Yup.string().required('Payable Email is Required').email('Payable Email is Required'),
				phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
				numEmployees: Yup.number().required('Number of employees is required').positive().integer(),
				locationOfHeadQuarters: Yup.string().required('Location of Head Quarters is required'),
			})
		),
	});

	const { vendorId } = session.details;

	useEffect(() => {
		// Note his useEffect needs to be separate so the current bizInfo is
		// only loaded once. Otherwise you cannot remove a service category
		// or a risk level
		setloading(true);
		apiClient
			.getBizInfo(vendorId)
			.then(async (r: any) => {
				reset(r);
				setVendorDetails(r);
				setSelectedServices(r?.services);
				setSelectedServicesProvided(r?.riskLevels);
				r?.isOutsideLocations ? setIsOutsideLocations(r?.isOutsideLocations.toString()) : setIsOutsideLocations('false');
				getServiceAreas(r);
				const risksSelected = r?.riskLevels.map((s: any) => s.riskLevel);
				const riskLevel = risksSelected.indexOf('HIGH') >= 0 ? 'HIGH' : risksSelected.indexOf('LOW') >= 0 ? 'LOW' : 'NONE';
				if (setRiskLevel) {
					setRiskLevel(riskLevel);
				}
			})
			.catch(err => {
				console.error(err);
				getServiceAreas(null);
			})
			.finally(() => setloading(false));
	}, [reset, vendorId]);

	useEffect(() => {
		apiClient
			.getServices()
			.then((r: any) => {
				setServices(r.data);
			})
			.catch(err => {
				console.error(err);
			});

		// TOOD The ResProp pmcId shouldn't be hardcoded here.
		const riskLevelFilters = {
			pmcId: '61bfe94e5278deb7dedca562',
			categories: selectedServices.map(s => s.categoryId),
			q: servicesProvidedSearchTerm,
		};
		apiClient
			.getServiceRiskLevels(riskLevelFilters)
			.then((res: any) => {
				setServicesProvided(res);
			})
			.catch((err: any) => {
				console.error(err);
			});
	}, [selectedServices, servicesProvidedSearchTerm]);

	function getServiceAreas(current: any) {
		apiClient
			.getServiceAreas()
			.then((ar: any) => {
				setServiceAreas(ar.data);
				if (current && current?.serviceareas) {
					const selected = ar.data.filter((item: any) => current?.serviceareas.find((sub: any) => sub === item.id));
					setSelectedServiceAreas(selected);
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

	const onSubmit = (formData: any) => {
		if (selectedServices?.length === 0) {
			setSelectedServicesError('Please select at least 1 service');
			return;
		}
		if (selectedServicesProvided?.length === 0) {
			setSelectedServicesProvidedError('Please select at least 1 service risk level');
			return;
		}

		if (!isOutsideLocations) {
			setIsOutsideLocationsError('Islocations outside of your Head Quarters required');
			return;
		} else {
			setIsOutsideLocationsError('');
		}

		if (isOutsideLocations == 'true' && selectedServiceAreas?.length === 0) {
			setSelectedServiceAreasError('Please select at least 1 service area');
			return;
		}

		const payload = {
			...formData,
			services: selectedServices.map(v => v.id),
			riskLevels: selectedServicesProvided.map(v => v.id),
			isOutsideLocations: isOutsideLocations,
			serviceareas: selectedServiceAreas.map(v => v.id),
		};
		const risksSelected = selectedServicesProvided.map(s => s.riskLevel);
		const riskLevel = risksSelected.indexOf('HIGH') >= 0 ? 'HIGH' : risksSelected.indexOf('LOW') >= 0 ? 'LOW' : 'NONE';
		if (setRiskLevel) {
			setRiskLevel(riskLevel);
		}
		if (setServiceProvided) {
			setServiceProvided(selectedServicesProvided[0].name);
		}

		setSubmitting(true);
		apiClient
			.submitBizInfo(vendorId, payload)
			.then(r => {
				setSubmitting(false);
				if (state?.next) {
					navigate(state?.next);
				} else {
					navigate('/vendor/enrollment/w9');
				}
			})
			.catch((err: any) => {
				if (err.response) {
					if (typeof err.response.data === 'string') setError(err.response.data || 'Something went wrong.');
					else setError('Something went wrong.');
				} else {
					setError('Something went wrong.');
				}
			});
	};

	return (
		<div>
			<Box className="afterArrowImg" sx={{mb: '32px'}}>
				<Box sx={{display: 'flex', gap: '12px', flexDirection: 'column'}}>
					<Typography variant="h2">Business Information</Typography>
					<Box sx={{display: 'flex', gap: '4px'}}>
						<Typography sx={{color: '#CF1124', fontSize: '14px', lineHeight: '20px'}}>*</Typography>
						<Typography sx={{color: '#272937BF', fontSize: '14px', lineHeight: '20px'}}>Required Information</Typography>
					</Box>
				</Box>
				{/* <DownArrow className="d-arrow" /> */}
			</Box>
			{loading && (
				<Box mb={2}>
					<LinearProgress />
				</Box>
			)}
			<div className="inputWrap mb12">
				<label className="inputLabel">
					Company email <span className="textDanger">*</span>
				</label>
				<TextField placeholder="Enter your Company's Email" {...register('email')} hiddenLabel variant="outlined" error={!!errors.email} />
			</div>

			<div className="inputWrap mb12">
				<label className="inputLabel">
					Accounts payable email <span className="textDanger">*</span>
				</label>
				<TextField
					placeholder="Enter Accounts Payable Email"
					{...register('accountsPayableEmail')}
					hiddenLabel
					variant="outlined"
					error={!!errors.accountsPayableEmail}
				/>
			</div>

			<div className="inputWrap mb12">
				<label className="inputLabel">
					Company phone number <span className="textDanger">*</span>
				</label>
				<Controller
					control={control}
					name="phone"
					render={({ field: { onChange, value } }) => (
						<PhoneField placeholder='(000) 000-0000' onChange={onChange} value={value} name="phone" error={!!errors.phone} />
					)}
				/>
			</div>

			<div className="inputWrap mb12">
				<label className="inputLabel">
					Number of employees <span className="textDanger">*</span>
				</label>
				<TextField type='number' placeholder='Enter Number of Employees' {...register('numEmployees')} hiddenLabel variant="outlined" error={!!errors.numEmployees} />
			</div>

			<div className="inputWrap mb12">
				<label className="inputLabel">
					Location of Head Quarters <span className="textDanger">*</span>
				</label>
				<GoogleMapsAutoComplete currVal={vendorDetails?.locationOfHeadQuarters} placeholder='Type in City and State' onMapValChanged={(val: any) => {
					setValue('locationOfHeadQuarters', val.structured_formatting.main_text);
					console.log(val)
				}}/>
			</div>
			

			<div className="inputWrap mb12">
				<label className="inputLabel">
					Do you provide service in any other locations outside of your Head Quarters? <span className="textDanger">*</span>
				</label>
				<Select
					value={isOutsideLocations}
					onChange={e => {
						if (e.target.value == 'false') setSelectedServiceAreas([]);
						setIsOutsideLocations(e.target.value);
					}}
					error={isOutsideLocationsError !== ''}
					displayEmpty={true} 
					sx={{color: isOutsideLocations ? '' : '#27293759'}}
				>
					<MenuItem key={`tax_0`} value={''} disabled>
						Select an Option
					</MenuItem>
					<MenuItem value={'true'}>Yes</MenuItem>
					<MenuItem value={'false'}>No</MenuItem>
				</Select>
			</div>

			{isOutsideLocations && isOutsideLocations == 'true' && (
				<div className="inputWrap mb12">
					<label className="inputLabel">
						Enter In the Locations you serve in<span className="textDanger">*</span>
					</label>
					<Autocomplete
						id="serviceareas-list"
						value={selectedServiceAreas}
						onChange={(e, v) => {
							console.log('v', v);
							setSelectedServiceAreas(v);
							setSelectedServiceAreasError('');
						}}
						inputValue={serviceAreasInputText}
						onInputChange={(event, value, reason) => {
							if (event && event.type === 'blur') {
								setServiceAreasInputText('');
							} else if (reason !== 'reset') {
								setServiceAreasInputText(value);
							}
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						options={serviceAreas.sort((a, b) => -b.state?.localeCompare(a.state))}
						filterOptions={(options, { inputValue }) =>
							options.filter(
								item =>
									item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
									item.state.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
							)
						}
						getOptionLabel={option => `${option.name}, ${option.stateCode}`}
						groupBy={option => option.state}
						fullWidth
						multiple
						renderOption={(props, option, { selected }) => (
							<li {...props} key={option.id}>
								<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
								{option.name}
							</li>
						)}
						renderTags={(value: readonly ServiceAreaOption[], getTagProps) =>
							value.map((option: ServiceAreaOption, index: number) => (
							  <Chip sx={{borderRadius: '6px'}} variant="filled" label={option.name} {...getTagProps({ index })} />
							))
						}
						disableCloseOnSelect
						renderInput={params => (
							<TextField hiddenLabel {...params} placeholder="Service area" error={selectedServiceAreasError !== ''} />
						)}
					/>
				</div>
			)}

			<div className="inputWrap mb12">
				<label className="inputLabel">
					What is the category of service you provide?<span className="textDanger">*</span>
				</label>
				<Autocomplete
					id="services-list"
					value={selectedServices}
					onChange={(e, v) => {
						setSelectedServices(v);
						const catIds = v.map(service => service.categoryId);
						const selRiskLevels = selectedServicesProvided.filter(
							selRiskLevel => catIds.indexOf(selRiskLevel.categoryId) >= 0
						);
						setSelectedServicesProvided(selRiskLevels);
						setSelectedServicesError('');
					}}
					inputValue={servicesInputText}
					onInputChange={(event, value, reason) => {
						if (event && event.type === 'blur') {
							setServicesInputText('');
						} else if (reason !== 'reset') {
							setServicesInputText(value);
						}
					}}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					options={services.sort((a, b) => -b.categoryName?.localeCompare(a.categoryName))}
					filterOptions={(options, { inputValue }) =>
						options.filter(
							item =>
								item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
								item.categoryName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
						)
					}
					groupBy={option => option.categoryName}
					getOptionLabel={option => option.name}
					fullWidth
					multiple
					renderOption={(props, option, { selected }) => (
						<li {...props} key={option.id}>
							<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
							{option.name}
						</li>
					)}
					renderTags={(value: readonly ServiceOption[], getTagProps) =>
						value.map((option: ServiceOption, index: number) => (
						  <Chip sx={{borderRadius: '6px'}} variant="filled" label={option.name} {...getTagProps({ index })} />
						))
					}
					disableCloseOnSelect
					renderInput={params => (
						<TextField hiddenLabel {...params} placeholder="Service name" error={selectedServicesError !== ''} />
					)}
				/>
			</div>

			{selectedServices.length > 0 ? (
				<div className="inputWrap mb12">
					<label className="inputLabel">
					Please Select The Service(s) You Will Provide. <span className="textDanger">*</span>
					</label>
					<Typography className="inputNote" sx={{ mb: 1 }}>
					Each service is associated with a risk level that determines the required insurance coverage limits you would need.
					</Typography>
					<Autocomplete
						id="provided-services-list"
						value={selectedServicesProvided}
						onChange={(e, v) => {
							setSelectedServicesProvided(v);
							setSelectedServicesProvidedError('');
						}}
						inputValue={servicesProvidedInputText}
						onInputChange={(event, value, reason) => {
							if (event && event.type === 'blur') {
								setServicesProvidedInputText('');
							} else if (reason !== 'reset') {
								setServicesProvidedInputText(value);
							}
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						options={servicesProvided.sort((a, b) => -b.categoryId?.localeCompare(a.categoryId))}
						groupBy={option => option.categoryName}
						getOptionLabel={option => option.name}
						filterOptions={(options, { inputValue }) =>
							options.filter(
								item =>
									item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
									item.categoryName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
							)
						}
						fullWidth
						multiple
						renderOption={(props, option, { selected }) => (
							<li {...props}>
								<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
								{/* <Chip label={option.riskLevel} sx={{ borderRadius: 0, mr: 1, width: '4rem' }} size="small" /> */}
								{option.name}
							</li>
						)}
						renderTags={(value: readonly IServiceRiskLevel[], getTagProps) =>
						  value.map((option: IServiceRiskLevel, index: number) => (
							<Chip sx={{borderRadius: '6px'}} variant="filled" label={option.name} {...getTagProps({ index })} />
						  ))
						}
						disableCloseOnSelect
						renderInput={params => (
							<TextField hiddenLabel {...params} placeholder="Service name" error={selectedServicesProvidedError !== ''} />
						)}
					/>
				</div>
			) : (
				''
			)}

			{submitting === false ? (
				<SaveAndContinue onClick={handleSubmit(onSubmit)} buttonTitle="Save And Continue" />
			) : (
				<Spinner />
			)}
			{error && (
				<Box pt={2} pb={2}>
					<Alert severity="error">{error}</Alert>
				</Box>
			)}
			{/** End of main div */}
		</div>
	);
};
