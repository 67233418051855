import { IMaskInput } from 'react-imask';
import React, { useContext, useEffect, useState, memo, Children, useRef } from 'react';
import { Button, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { themeColors } from '../../theme';
import Container from '../../components/container';
import Header from '../../components/landing/header';
import ButtonCustom from '../../components/button';
import { ReactComponent as DownArrow } from '../../svg/Down-arrow.svg';
import { useUserState } from '../../context/UserContext';
import { sendVerificationCodeService, verify2faCodeService, resetPassword } from '../../api';
import { SessionContext } from 'src/context/SessionContext';
import { Spinner } from '../../components/loaders';
import { Box, styled } from '@mui/system';

const IdCoreInput = styled(TextField)(({ theme }) => ({
	[`&.MuiTextField-root`]: {
		borderRadius: '8px',
		paddingBottom: 0,
	},
	'& .MuiInputBase-input': {
		padding: '12px',
	},
}));

export interface IPhoneCode {}

const Otp = ['d0', 'd1', 'd2', 'd3', 'd4', 'd5'];

const LoginReset: React.FC<IPhoneCode> = memo(() => {
	const refInputs = useRef<any>([]);
	const navigate = useNavigate();
	const { user, setOtp } = useUserState();
	const [submit, setSubmit] = useState(false);
	const [message, setMessage] = useState('');
	const [codeVerified, setCodeVerified] = useState(false);

	const { refreshSession } = useContext(SessionContext);

	const {
		userDetails: { loginId },
		userProviders,
		otp,
	} = user;

	const isNew = userProviders.length === 0;

	const handleDelete = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Backspace') {
			const id: string = event.target.id;
			const index: number = Otp.indexOf(id);
			if (index > 0) {
				event.preventDefault();
				refInputs.current[index].element.value = '';
				refInputs.current[index - 1]?.element?.focus();
			}
		}
	};

	const resetInputs = () => {
		for (let index = 0; index < refInputs?.current?.length; index++) {
			refInputs.current[index].maskRef.value = '';
		}
		refInputs.current[0]?.element?.focus();
	};

	const handleNumber = (val: string, id: string, index: number) => {
		setOtp(val, index);
		if (val) refInputs.current[index + 1]?.element?.focus();
	};

	const handleResendCode = () => {
		sendVerificationCodeService(loginId)
			.then(res => {
				if (res.status === 200) {
					resetInputs();
					alert('Code Sent Successfully');
				} else {
					setMessage('Unable to resend code');
				}
			})
			.catch(err => {
				setMessage('Unable to resend code');
			});
	};

	const handleSubmitVerifyCode = () => {
		setSubmit(true);
		const code = otp.join('').trim();
		verify2faCodeService(loginId, code)
			.then(res => {
				if (res.data === true) setCodeVerified(true);
				setSubmit(false);
			})
			.catch(err => {
				const errMsg = err?.response?.data;
				if (errMsg) {
					if (errMsg.indexOf('not authorized to access this application') >= 0) {
						setMessage('You are not authorized to access this application');
					} else {
						setMessage('Invalid Verification Code');
					}
				} else {
					setMessage('An error occurred. Please try again later.');
				}
				setSubmit(false);
			});
	};

	const handleSubmitPassword = () => {
		let pwdCheck = 0;
		let validateRegex = ['[A-Z]', '[a-z]', '[0-9]'];
		validateRegex.forEach((regex, i) => {
			if (new RegExp(regex).test(password)) {
				pwdCheck += 1;
			}
		});

		if (pwdCheck < 3 || password.length < 8) {
			setMessage('Please enter a strong password');
			return;
		}

		setSubmit(true);
		const inviteCode = new URLSearchParams(window.location.search).get('inviteCode');
		resetPassword(loginId, password, userProviders, inviteCode)
			.then(res => {
				refreshSession();
				const { role } = res.data;
				if (role === 'VENDOR') {
					navigate('/vendor/dashboard');
				}
			})
			.catch(err => {
				console.log(err);
				const errMsg = err?.response?.data;
				if (errMsg) {
					if (errMsg.error && errMsg.error_description) {
						setMessage(errMsg.error_description);
					} else if (errMsg instanceof String && errMsg.indexOf('not authorized to access this application') >= 0) {
						setMessage('You are not authorized to access this application');
					} else {
						setMessage('Invalid Password');
					}
				} else {
					setMessage('An error occurred. Please try again later.');
				}
				setSubmit(false);
			});
	};

	const [password, setPassword] = useState('');
	const handleChange = (val: any) => {
		setPassword(val);
	};

	useEffect(() => {
		refInputs.current[0]?.element?.focus();
	}, []);

	return (
		<Container align="center">
			<div className="landingWrap">
				{!codeVerified && (
					<Grid container>
						<Grid item xs={12} textAlign={'center'}>
							<Typography variant="h1" fontSize={30} fontWeight={400} pb={3}>
								Verification code
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<form
								onSubmit={e => {
									e.preventDefault();
									handleSubmitVerifyCode();
								}}
							>
								<div className="multi-input">
									{Children.toArray(
										Otp.map((id: string, idx: number) => (
											<IMaskInput
												ref={ref => {
													refInputs.current[idx] = ref;
												}}
												key={id}
												id={id}
												className={['otp-input'].concat(message ? 'has-error' : []).join(' ')}
												mask="#"
												type="number"
												definitions={{
													'#': /\b\d\b/,
												}}
												onAccept={(value: any) => handleNumber(value, id, idx)}
												overwrite
												required
												onKeyDown={handleDelete}
											/>
										))
									)}
									{message && (
										<Typography variant="body2" color={themeColors.error}>
											{message}
										</Typography>
									)}
								</div>
								<ButtonCustom round="full" type="submit" disabled={otp.join('').length < 6}>
									{submit === false ? <span>Confirm Code</span> : <Spinner />}
								</ButtonCustom>
							</form>

							<Button variant="link" className="resend-code" onClick={handleResendCode}>
								Resend code
							</Button>

							<Typography variant="body2" className="button link" color={themeColors.linkBlue}>
								<Link to="/login">login with a different account</Link>
							</Typography>
						</Grid>
					</Grid>
				)}

				{codeVerified && (
					<Grid container>
						{isNew && (
							<Grid item xs={12} textAlign={'justify'}>
								<Typography variant="h1" fontSize={30} fontWeight={400} pb={1}>
									Create a Password
								</Typography>
								<Typography pb={3}>Please set a password so you can log back in</Typography>
							</Grid>
						)}
						{!isNew && (
							<Grid item xs={12} textAlign={'start'}>
								<Typography variant="h1" fontSize={30} fontWeight={400} pb={3}>
									Enter New Password
								</Typography>
							</Grid>
						)}
						<Grid item xs={12}>
							<form
								onSubmit={e => {
									e.preventDefault();
									handleSubmitPassword();
								}}
							>
								<IdCoreInput
									fullWidth
									onChange={(event: any) => {
										handleChange(event.target.value);
									}}
									value={password}
									name="password"
									placeholder="Enter Password"
									type="password"
									error={message.length > 0}
								/>
								{message && (
									<Typography variant="body2" color={themeColors.error} pt={1} textAlign={'justify'}>
										{message}
									</Typography>
								)}

								<Grid item xs={12}>
									<Typography variant="h6" pt={4} textAlign={'justify'}>
										Password must include
									</Typography>
									<List sx={{ listStyleType: 'disc', p: 2 }}>
										<ListItem disablePadding sx={{ display: 'list-item' }}>
											<ListItemText primary="It must include one uppercase letter." />
										</ListItem>
										<ListItem disablePadding sx={{ display: 'list-item' }}>
											<ListItemText primary="It must contain one lowercase letter." />
										</ListItem>
										<ListItem disablePadding sx={{ display: 'list-item' }}>
											<ListItemText primary="It must have one number." />
										</ListItem>
										<ListItem disablePadding sx={{ display: 'list-item' }}>
											<ListItemText primary="It must contain at least 8 characters." />
										</ListItem>
									</List>
								</Grid>
								<ButtonCustom round="full" type="submit" disabled={password.length <= 0} sx={{ mt: 2 }}>
									{submit === false ? <span>{isNew ? 'Create Account' : 'Reset And Login'}</span> : <Spinner />}
								</ButtonCustom>
							</form>
						</Grid>
					</Grid>
				)}
			</div>
		</Container>
	);
});

export default LoginReset;
