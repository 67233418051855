import React, { ReactNode } from 'react';
import { styled } from '@mui/system';
import { ButtonProps, Button } from '@mui/material';
import { borderRadius, themeColors } from '../../theme';

type SubmitButtonProps = {
	className?: string;
	bg?: string;
	endIcon?: ReactNode;
	startIcon?: ReactNode;
	loading?: boolean;
};

const ColorButton = styled(Button)<any & SubmitButtonProps>(({ round, bg, color }) => ({
	borderRadius: round === 'full' ? '200px' : '8px',
	'&.MuiButton-iconButton': {
		background: themeColors.primary,
		paddingLeft: '15px',
		paddingRight: '15px',
		color: '#fff',
		justifyContent: 'space-between',
		'&:hover': {
			background: themeColors.primaryHover,
		},
	},
	'&.MuiButton-primaryRoundFull': {
		background: themeColors.primary,
		color: themeColors.white,
		borderRadius: borderRadius.full,
		width: '100%',
	},
}));

const ButtonCustom = <C extends React.ElementType>(props: SubmitButtonProps & ButtonProps<C, { component?: C }>) => {
	return <ColorButton {...props} />;
};

export default ButtonCustom;
