export const typography = {
	fontFamily: ['Nunito'].join(','),
	allVariants: {
		color: '#0D0D0D',
		lineHeight: 1.5,
	},
	h1: {
		fontSize: 36,
		fontWeight: 700,
	},
	h2: {
		fontSize: 24,
		lineHeight: 1.1666666667,
		fontWeight: 600,
		marginBottom: 4,
	},
	h3: {
		fontSize: 16,
		fontWeight: 600,
		lineHeight: 1.25,
	},
	subtitle1: {
		fontSize: 18,
		lineHeight: 1.5,
		fontWeight: 600,
	},
	body1: {
		fontSize: 14,
		lineHeight: 1.5,
		fontWeight: 400,
	},
	subtitle2: {
		fontSize: 16,
		lineHeight: 1.25,
		fontWeight: 600,
	},
	body2: {
		fontSize: 12,
		lineHeight: 1.333,
		fontWeight: 400,
		letterSpacing: 0.15,
	},
	body2Spaced: {
		fontSize: 12,
		lineHeight: 1.333,
		fontWeight: 400,
		letterSpacing: 5,
	},
	caption: {
		letterSpacing: 0.5,
		lineHeight: 1.4,
		fontSize: 14,
		fontWeight: 400,
	},
};
