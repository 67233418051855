import { useIntercom as useReactIntercom } from 'react-use-intercom';

export function useIntercom() {

  const { show, update, showNewMessages } = useReactIntercom();
  
  return {
    run: async (appName: string, name: string = '', email: string = '', user_id: string = '', message: string = '') => {
      update({
        email: email,
        name: name
      })
      // @ts-ignore
      // window.Intercom('boot', {
      //   app_id: appName === 'idcore' ? 'gpm1w2cg' : 'ggekus3b',
      //   hide_default_launcher	: true,
      //   custom_launcher_selector: '#intercom-chat',
      //   name,
      //   email,
      //   user_id
      // });
      // await delay(2000);
      // // @ts-ignore
      // window.Intercom('show');
      if(message) {
        showNewMessages(message);
      } else {
        show();
      }
    },
  };
}